import { Avatar, Grid, Typography } from "@mui/material";
import React from "react";
import moment from "moment";

export default function AthleteProfilCard({
  firstName,
  lastName,
  email,
  createdAt,
}) {
  return (
    <Grid
      container
      alignItems={"flex-end"}
      justifyContent="space-between"
      sx={{
        p: { xs: 2, sm: 3 },
        borderRadius: 5,
        bgcolor: "#fff",
        boxShadow:
          "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
      }}
    >
      <Grid item>
        <Grid container alignItems={"center"}>
          <Grid item>
            <Avatar
              sx={{
                textTransform: "uppercase",
                color: "primary.main",
                bgcolor: "primary.100",
                width: { xs: 56, sm: 80 },
                height: { xs: 56, sm: 80 },
              }}
            >
              {firstName
                ? `${firstName?.charAt(0)} ${lastName?.charAt(0)}`
                : " "}
            </Avatar>
          </Grid>
          <Grid item sx={{ ml: 2 }} xs>
            <Typography
              sx={{
                "-webkit-box-orient": "vertical",
                "-webkit-line-clamp": "1",
                display: "-webkit-box",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              variant="subtitle1"
            >
              {firstName} {lastName}
            </Typography>

            <Typography
              sx={{
                "-webkit-box-orient": "vertical",
                "-webkit-line-clamp": "1",
                display: "-webkit-box",
                overflow: "hidden",
                textOverflow: "ellipsis",
                wordBreak: "break-word",
              }}
              variant="subtitle2"
            >
              {email}
            </Typography>
            <Typography
              fontWeight={400}
              sx={{
                color: "#6C737F",
                // display: { xs: "flex", sm: "none" },
                "-webkit-box-orient": "vertical",
                "-webkit-line-clamp": "1",
                display: "-webkit-box",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              variant="caption"
            >
              {moment(createdAt).format("DD/MM/YYYY")} tarihinde eklendi.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {createdAt && (
        <Grid item sx={{ display: { xs: "none", sm: "block" } }}>
          <Typography
            sx={{
              "-webkit-box-orient": "vertical",
              "-webkit-line-clamp": "1",
              display: "-webkit-box",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            variant="subtitle2"
          >
            {moment(createdAt).format("DD/MM/YYYY")} tarihinde eklendi.
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
