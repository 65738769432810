import * as React from "react";
import { Autocomplete, Grid, TextField } from "@mui/material";

export default function MultipleSelectChip({
  options,
  error,
  helperText,
  selectedOptions,
  setSelectedOptions,
  handleFormSubmit,
}) {
  const handleChange = (value) => {
    setSelectedOptions(value);
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleFormSubmit();
        }}
      >
        {" "}
        <Grid container sx={{ minHeight: 76 }}>
          <Autocomplete
            sx={{
              "&:hover .MuiAutocomplete-input": {
                minWidth: 30,
              },
            }}
            disableCloseOnSelect
            multiple
            value={selectedOptions}
            onChange={(event, newValue) => {
              handleChange(newValue);
            }}
            fullWidth
            id="select"
            name="select"
            options={options}
            getOptionLabel={(option) => option.groupLeagueName}
            renderInput={(params) => (
              <TextField
                {...params}
                id="select"
                name="select"
                label="Takım"
                placeholder=""
                error={error}
                helperText={helperText}
              />
            )}
            // sx={{ width: '500px' }}
          />
        </Grid>
      </form>
    </>
  );
}
