import { Grid, Typography } from "@mui/material";
import React from "react";

export default function DuesPaymentDate({ labelText, date, dateColor }) {
  return (
    <Grid
      alignItems={"center"}
      container
      sx={{
        borderRadius: 5,
        padding: "24px",
        alignSelf: "stretch",
        bgcolor: "#fff",
        boxShadow:
          "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
      }}
    >
      <Grid container>
        <Typography variant="subtitle1">{labelText}</Typography>
      </Grid>
      <Typography style={{ color: dateColor }} variant="subtitle2">
        {date}
      </Typography>
    </Grid>
  );
}
