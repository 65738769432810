import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import React from "react";

export default function TableContainer({ title, table }) {
  return (
    <Grid container>
      <Grid container>
        <Grid
          container
          sx={{
            mt: 2,
           
          }}
        >
          {title && (
            <Grid container sx={{ mb: 2 }}>
              <Typography sx={{ color: "#111927", fontSize: 18, fontWeight: 700 }}>
                {title}
              </Typography>
            </Grid>
          )}
          <Grid container>{table}</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
