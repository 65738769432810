import { Grid, IconButton } from "@mui/material";
import React from "react";
import NotificationMenu from "../menu/NotificationMenu";
import AvatarMenu from "../menu/AvatarMenu";
import { Paper } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

export default function Header({ setOpen }) {

  return (
    <Paper
      sx={{
        ml: "1px",
        width: "100%",
        zIndex: 1,
        backdropFilter: "blur(12px)",
        bgcolor: "#ffffff40",
        position: "sticky",
        top: 0,
      }}
      elevation={0}
    >
      <Grid
        container
        justifyContent={"space-between"}
        alignItems="center"
        sx={{ p: "4px 20px" }}
      >
        <Grid item>
          <Grid container>
            <IconButton sx={{ display: { lg: 'none' } }} onClick={() => setOpen(true)}>
              <MenuIcon />
            </IconButton>
            <NotificationMenu />
          </Grid>
        </Grid>
        <AvatarMenu />
      </Grid>
    </Paper>
  );
}
