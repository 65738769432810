import { Typography } from '@mui/material'
import { Grid } from '@mui/material'
import React from 'react'

export default function SectionTitle({ title, description, button }) {
    return (
        <Grid container alignItems={"center"} justifyContent="space-between" sx={{ bgcolor: 'white', px: 3, pb: 3 }}>
            <Grid item>
                <Grid container >
                    <Typography variant='h1'>{title} </Typography>
                </Grid>
                <Grid container sx={{ mt: 1 }}>
                    <Typography fontWeight={500} variant='body2'>{description}</Typography>
                </Grid>
            </Grid>
            {button}
        </Grid>
    )
}
