import { Grid, Typography, Button } from "@mui/material";
import React from "react";
import moment from "moment";

export default function ProfilDetail({ formik, handleEditScreen }) {
  const formattedDateOfBirth = formik.values.dateOfBirth
    ? moment(formik.values.dateOfBirth).format("DD/MM/YYYY")
    : "-";

  const formattedPhone = formik.values.phone || "-";

  return (
    <Grid container>
      <Grid
        container
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          pb: "24px",
          "& .MuiButton-root": {
            px: "10px",
          },
        }}
      >
        <Typography
          sx={{
            fontStyle: "normal",
            color: "#111927",
            fontFeatureSettings: `'clig' off, 'liga' off`,
          }}
          variant="h6"
        >
          Hesap Bilgileri
        </Typography>
        <Button onClick={handleEditScreen}>Düzenle</Button>
      </Grid>

      <Grid
        container
        sx={{
          boxShadow: "0px 0px 0px 1px #F2F4F7",
          borderRadius: "8px",
        }}
      >
        <Grid container sx={{ p: "16px 24px" }}>
          <Grid item xs={6} md={4}>
            <Typography
              sx={{ fontWeight: 500, fontFamily: "Inter" }}
              variant="body1"
            >
              Ad
            </Typography>
          </Grid>
          <Grid item xs={6} md={8}>
            <Typography sx={{ fontFamily: "Inter" }} variant="body2">
              {formik.values.firstName}
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ p: "16px 24px" }}>
          <Grid item xs={6} md={4}>
            <Typography
              sx={{ fontWeight: 500, fontFamily: "Inter" }}
              variant="body1"
            >
              Soyad
            </Typography>
          </Grid>
          <Grid item xs={6} md={8}>
            <Typography sx={{ fontFamily: "Inter" }} variant="body2">
              {formik.values.lastName}
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ p: "16px 24px" }}>
          <Grid item xs={6} md={4}>
            <Typography
              sx={{ fontWeight: 500, fontFamily: "Inter" }}
              variant="body1"
            >
              Eposta Adresi
            </Typography>
          </Grid>
          <Grid item xs={6} md={8}>
            <Typography sx={{ fontFamily: "Inter" }} variant="body2">
              {formik.values.email}
            </Typography>
          </Grid>
        </Grid>{" "}
        <Grid container sx={{ p: "16px 24px" }}>
          <Grid item xs={6} md={4}>
            <Typography
              sx={{ fontWeight: 500, fontFamily: "Inter" }}
              variant="body1"
            >
              Telefon Numarası
            </Typography>
          </Grid>
          <Grid item xs={6} md={8}>
            <Typography sx={{ fontFamily: "Inter" }} variant="body2">
              {formattedPhone}
            </Typography>
          </Grid>
        </Grid>{" "}
        <Grid container sx={{ p: "16px 24px" }}>
          <Grid item xs={6} md={4}>
            <Typography
              sx={{ fontWeight: 500, fontFamily: "Inter" }}
              variant="body1"
            >
              Doğum Tarihi
            </Typography>
          </Grid>

          <Grid item xs={6} md={8}>
            <Typography sx={{ fontFamily: "Inter" }} variant="body2">
              {formattedDateOfBirth}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
