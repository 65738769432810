import { Grid, ListItemIcon, ListItemText } from "@mui/material";
import { Collapse } from "@mui/material";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { HiChevronDown } from "react-icons/hi2";
import { NavLink } from "react-router-dom";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <div {...other} />;
})(({ theme, expand }) => ({
  display: "flex",
  alignItems: "center",
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function DrawerBottomMenu({ open, setOpen, ar, index }) {
  const theme = useTheme();


  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    if (open) {
      setExpanded(!expanded);
    } else {
      setExpanded(true);
    }
    setOpen(true);
  };

  useEffect(() => {
    if (!open) {
      setExpanded(false);
    }
  }, [open]);

  const hasGroups = ar?.linkedGroupLeagueJunctions && ar?.linkedGroupLeagueJunctions.length > 0;

  return (
    <>
      {hasGroups && (
        <Grid
          key={index}
          onClick={handleExpandClick}
          justifyContent={"space-between"}
          sx={{
            p: "0px 8px 4px 8px",
            m: "16px 0px 0px 0px",
            cursor: "pointer",
            minHeight: 46,
            borderRadius: "12px",
            "&.active p ": { color: theme.palette.primary.main },
            "&.active": {
              color: theme.palette.primary.main,
              bgcolor: theme.palette.primary[100],
            },
            "&.active svg": {
              stroke: theme.palette.primary.main,
            },
          }}
          alignItems="center"
          container
          flexWrap={"nowrap"}
        >
          <Grid item>
            <Grid
              sx={{
                "& .MuiListItemText-primary": {
                  color: "#6C737F ",
                  fontSize: 12,
                  fontWeight: 600,
                  lineHeight: "100%",
                  fontStyle: "normal",
                  letterSpacing: "0.5px",
                  textTransform: "uppercase",
                  fontFeatureSettings: `'clig' off, 'liga' off`,
                },
              }}
              container
              flexWrap={"nowrap"}
              alignItems={"center"}
            >
              <ListItemText primary={ar.leagueName} />
            </Grid>
          </Grid>
          <ExpandMore
            id="expand"
            expand={expanded}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <HiChevronDown fill={"#6C737F"} />
          </ExpandMore>
        </Grid>
      )}

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Grid container sx={{ px: 1 }}>
          {ar?.linkedGroupLeagueJunctions?.map((dat, index2) => (
            <Grid
              component={NavLink}
              to={`/sporcular/${dat?.id}`}
              alignItems="center"
              container
              key={index2}
              sx={{
                p: "2px 16px 2px 16px",
                cursor: "pointer",
                borderRadius: "12px",
                "&.active p ": { color: theme.palette.primary.main },
                "&.active": {
                  color: theme.palette.primary.main,
                  bgcolor: theme.palette.primary[100],
                },
                "&.active svg": {
                  stroke: theme.palette.primary.main,
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 2,
                  justifyContent: "center",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                >
                  <circle id="Ellipse" cx="4" cy="4" r="4" fill="#EBEEFE" />
                </svg>
              </ListItemIcon>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "24px",
                  color: "#6C737F",
                }}
              >
                {dat.groupName}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Collapse>
    </>
  );
}
