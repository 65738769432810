import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PersonalMeasureRow from "./PersonalMeasureRow";
import { GET_ATHLETE_MEASUREMENTS_QUERY } from "../../../queries";
import { useQuery } from "@apollo/client";
import { useSearchParams } from "react-router-dom";

export default function PersonalMeasureDetails() {
  let [searchParams] = useSearchParams();
  let athleteId = searchParams.get("sporcu");

  const { data: getAthleteMeasurementsHeight, refetch: refetchHeight } =
    useQuery(GET_ATHLETE_MEASUREMENTS_QUERY, {
      variables: {
        measurementType: "height",
        athleteId: athleteId,
      },
      skip: !athleteId,
      fetchPolicy: "cache-and-network",
    });
  const { data: getAthleteMeasurementsWeight, refetch: refetchWeight } =
    useQuery(GET_ATHLETE_MEASUREMENTS_QUERY, {
      variables: {
        measurementType: "weight",
        athleteId: athleteId,
      },
      skip: !athleteId,
      fetchPolicy: "cache-and-network",
    });
  const { data: getAthleteMeasurementsJumHeight, refetch: refetchJumHeight } =
    useQuery(GET_ATHLETE_MEASUREMENTS_QUERY, {
      variables: {
        measurementType: "jumpHeight",
        athleteId: athleteId,
      },
      skip: !athleteId,
      fetchPolicy: "cache-and-network",
    });
  const {
    data: getAthleteMeasurementsBlockHeight,
    refetch: refetchBlockHeight,
  } = useQuery(GET_ATHLETE_MEASUREMENTS_QUERY, {
    variables: {
      measurementType: "blockHeight",
      athleteId: athleteId,
    },
    skip: !athleteId,
    fetchPolicy: "cache-and-network",
  });
  const {
    data: getAthleteMeasurementsSpikeHeight,
    refetch: refetchSpikeHeight,
  } = useQuery(GET_ATHLETE_MEASUREMENTS_QUERY, {
    variables: {
      measurementType: "spikeHeight",
      athleteId: athleteId,
    },
    skip: !athleteId,
    fetchPolicy: "cache-and-network",
  });
  const { data: getAthleteMeasurementsExitSpeed, refetch: refetchExitSpeed } =
    useQuery(GET_ATHLETE_MEASUREMENTS_QUERY, {
      variables: {
        measurementType: "exitSpeed",
        athleteId: athleteId,
      },
      skip: !athleteId,
      fetchPolicy: "cache-and-network",
    });

  let myArr = [
    {
      type: "height",
      label: "Boy",
      measure: "cm",
      datas: getAthleteMeasurementsHeight?.getAthleteMeasurements,
      lastData: getAthleteMeasurementsHeight?.getAthleteMeasurements?.at(-1),
      refetch: refetchHeight,
    },
    {
      type: "weight",
      label: "Kilo",
      measure: "kg",
      datas: getAthleteMeasurementsWeight?.getAthleteMeasurements,
      lastData: getAthleteMeasurementsWeight?.getAthleteMeasurements?.at(-1),
      refetch: refetchWeight,
    },
    {
      type: "jumpHeight",
      label: "Sıçrama Yüksekliği",
      measure: "cm",
      datas: getAthleteMeasurementsJumHeight?.getAthleteMeasurements,
      lastData: getAthleteMeasurementsJumHeight?.getAthleteMeasurements?.at(-1),
      refetch: refetchJumHeight,
    },
    {
      type: "blockHeight",
      label: "Blok Yüksekliği",
      measure: "cm",
      datas: getAthleteMeasurementsBlockHeight?.getAthleteMeasurements,
      lastData:
        getAthleteMeasurementsBlockHeight?.getAthleteMeasurements?.at(-1),
      refetch: refetchBlockHeight,
    },
    {
      type: "spikeHeight",
      label: "Smaç Yüksekliği",
      measure: "cm",
      datas: getAthleteMeasurementsSpikeHeight?.getAthleteMeasurements,
      lastData:
        getAthleteMeasurementsSpikeHeight?.getAthleteMeasurements?.at(-1),
      refetch: refetchSpikeHeight,
    },
    {
      type: "exitSpeed",
      label: "Çıkış Hızı",
      measure: "m/s",
      datas: getAthleteMeasurementsExitSpeed?.getAthleteMeasurements,
      lastData: getAthleteMeasurementsExitSpeed?.getAthleteMeasurements?.at(-1),
      refetch: refetchExitSpeed,
    },
  ];

  return (
    <>
      <TableContainer
        sx={{ boxShadow: "unset", maxWidth: "100%" }}
        component={Paper}
      >
        <Table
          sx={{
            "& .MuiTableCell-root": {
              border: "unset",
              textAlign: "left",
            },
            "& thead .MuiTableCell-root": {
              fontWeight: 600,
              fontSize: 12,
              color: "#111927",
              letterSpacing: "0.5px",
              textTransform: "uppercase",
            },
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="right">ÖLÇÜM ADI</TableCell>
              <TableCell align="right" sx={{ minWidth: "130px" }}>
                ÖLÇÜM
              </TableCell>
              <TableCell align="right" sx={{ minWidth: "130px" }}>
                SON ÖLÇÜM TARİHİ
              </TableCell>
              <TableCell align="right">REFERANS ARALIĞI</TableCell>
              <TableCell align="right">AKSİYONLAR</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {myArr.map((row, index) => (
              <PersonalMeasureRow key={index} data={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <Grid container justifyContent={"flex-end"}>
        <TablePagination
          labelRowsPerPage="Satır sayısı:"
          sx={{ mt: 1 }}
          component="div"
          count={100}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid> */}
    </>
  );
}
