import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Grid, IconButton, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AthleteProfilCard from "./components/AthleteProfilCard";
import AthleteDetailTabs from "./components/AthleteDetailTabs";

import { useQuery } from "@apollo/client";
import { GET_ATHLETE_DETAIL_QUERY } from "../../queries";

export default function AthleteDetail() {
  let [searchParams] = useSearchParams();
  let playerId = searchParams.get("sporcu");

  const { data: getAthlete } = useQuery(GET_ATHLETE_DETAIL_QUERY, {
    variables: {
      getAthleteId: playerId,
    },
    skip: !playerId,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const isMobil = useMediaQuery("(max-width:600px)");
  // bu playerId yi kullanarak DB ye istek atacagız.
  const handleClose = () => {
    navigate(`${location?.pathname}`);
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={isMobil}
        fullWidth
        maxWidth="lg"
        open={!!playerId}
        onClose={handleClose}
        PaperProps={{
          sx: { borderRadius: { xs: 0, sm: "20px" } },
        }}
      >
        <DialogTitle
          component={Grid}
          container
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          Sporcu Detayı
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon sx={{ color: "#6C737F", width: 20, height: 20 }} />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <AthleteProfilCard
            firstName={getAthlete?.getAthlete?.firstName}
            lastName={getAthlete?.getAthlete?.lastName}
            email={getAthlete?.getAthlete?.email}
            createdAt={getAthlete?.getAthlete?.createdAt}
          />
          <AthleteDetailTabs />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
