import { TablePagination, TextField } from "@mui/material";
import { Tabs } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { Button } from "@mui/material";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import React from "react";
import MatchResultCard from "./MatchResultCard.js";
import { MatchResult } from "../../utils.js";

export default function AthletesCard() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Grid container sx={{ p: 3, py: { xs: 0, sm: 0 } }}>
      <Tabs
        sx={{
          "& .MuiTabs-scroller": { height: "40px" },
          "& .MuiTabs-scrollButtons": {
            height: 40,
            width: {
              xs: "20px",
              sm: "20px",
              md: "40px",
            },
          },
        }}
        variant="scrollable"
        scrollButtons="auto"
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      ></Tabs>
      <Grid container >
        <Grid container alignItems={"center"} spacing={2}>
          <Grid item xs>
            <TextField
              fullWidth
              sx={{ bgcolor: "white", borderRadius: "12px" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="24"
                      viewBox="0 0 23 24"
                      fill="none"
                    >
                      <path
                        d="M20.125 21L15.9562 16.65M18.2083 11C18.2083 15.4183 14.7758 19 10.5417 19C6.30748 19 2.875 15.4183 2.875 11C2.875 6.58172 6.30748 3 10.5417 3C14.7758 3 18.2083 6.58172 18.2083 11Z"
                        stroke="#111927"
                        stroke-opacity="0.38"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </InputAdornment>
                ),
              }}
              placeholder="Maç sonucu ara"
              size="small"
            />
          </Grid>
          <Grid item>
            <Button
              sx={{
                bgcolor: "white",
                p: "7px 16px",
                minWidth: {
                  xs: "120px",
                  sm: "120px",
                  md: "160px",
                },
                justifyContent: "flex-start",
                textTransform: "unset",
                color: "inherit",
              }}
              variant="outlined"
              startIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M6 12H18M3 6H21M9 18H15"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              }
            >
              <Typography variant="body1">Filter</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container sx={{ p: "24px 0px" }}>
        {MatchResult.map((res, index) => {
          return <MatchResultCard res={res} index={index} />;
        })}
        <Grid
          container
          sx={{
            "& .MuiSelect-select-MuiInputBase-input:focus": {
              backgroundColor: "#FFF",
            },
          }}
          justifyContent={"flex-end"}
        >
          <TablePagination
            labelRowsPerPage="Satır sayısı:"
            sx={{ mt: 1 }}
            component="div"
            count={100}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
