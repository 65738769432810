import { useQuery } from "@apollo/client";
import { Grid, TablePagination, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { ALL_PAYMENTS_QUERY_PAGINATED } from "../../queries";
import DuesAction from "../card/duesTable/DuesAction";
import PaymentStatusChip from "../card/duesTable/PaymentStatusChip";
import moment from "moment";
import { getAidatNameFromDate } from "../../utils";

export default function DuesTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  let [searchParams] = useSearchParams();
  const playerId = searchParams.get("sporcu");

  const { data: allPaymentsRaw, loading } = useQuery(
    ALL_PAYMENTS_QUERY_PAGINATED,
    {
      variables: {
        skip: page * 10,
        take: rowsPerPage,
        athleteId: playerId,
      },
      skip: !playerId,
      fetchPolicy: "cache-and-network",
    }
  );
  const allPayments = allPaymentsRaw?.allPayments?.payments || [];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let totalPayment = 0;
  allPaymentsRaw?.allPayments?.payments
    ?.filter((payment) => payment.status !== "Paid")
    ?.map((p) => {
      totalPayment = totalPayment + p.amount;
      return null;
    });
  return (
    <>
      <TableContainer
        sx={{ boxShadow: "unset", maxWidth: "100%" }}
        component={Paper}
      >
        <Table
          sx={{
            "& .MuiTableCell-root": {
              border: "unset",
              textAlign: "left",
            },
            "& thead .MuiTableCell-root": {
              fontWeight: 600,
              fontSize: 12,
              color: "#111927",
              letterSpacing: "0.5px",
              textTransform: "uppercase",
            },
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: "130px" }} align="right">
                {"Ödeme ADI"}
              </TableCell>
              <TableCell align="right" sx={{ minWidth: "130px" }}>
                ÖDEME TARİHİ
              </TableCell>
              <TableCell align="right" sx={{ minWidth: "130px" }}>
                ÖDENECEK TUTAR
              </TableCell>
              <TableCell sx={{ minWidth: "156px" }} align="right">
                ÖDEME DURUMU
              </TableCell>
              <TableCell sx={{ minWidth: "130px" }} align="right">
                Aksiyonlar
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && allPayments.length === 0 && (
              <TableRow>
                <TableCell>
                  <Typography>Borç bulunamadı...</Typography>
                </TableCell>
              </TableRow>
            )}
            {allPayments.map((payment, index) => (
              <TableRow
                key={payment.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {getAidatNameFromDate(payment.installmentDate)}
                </TableCell>

                <TableCell align="right">
                  {payment.installmentDate
                    ? moment(payment.installmentDate).format("DD/MM/YYYY")
                    : "-"}
                </TableCell>
                <TableCell align="right">{payment.debtAmount}</TableCell>
                <TableCell align="right">
                  {
                    <PaymentStatusChip
                      ödemeDurumu="ÖDEME YAPILMADI"
                      value={payment.status}
                    />
                  }
                </TableCell>

                <TableCell align="right">
                  {payment?.debtAmount > 0 && (
                    <DuesAction
                      button={true}
                      playerId={playerId}
                      paymentStatus={
                        payment?.debtAmount > 0 ? "NotPaid" : "Paid"
                      }
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid
        container
        sx={{
          "& .MuiSelect-select-MuiInputBase-input:focus": {
            backgroundColor: "#FFF",
          },
        }}
        justifyContent={"flex-end"}
      >
        <TablePagination
          labelRowsPerPage="Satır sayısı:"
          sx={{ mt: 1 }}
          component="div"
          count={allPaymentsRaw?.allPayments?.total || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </>
  );
}
