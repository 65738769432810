import { Grid } from "@mui/material";
import React from "react";
import SectionTitle from "../components/title/SectionTitle";
import DuesReportsCard from "../components/card/DuesReportsCard";
import HomeInfoCard from "../components/card/HomeInfoCard";
import { useQuery } from "@apollo/client";
import { LOGGED_IN_QUERY, PAYMENT_REPORTS } from "../queries";

export default function DuesReports() {
  const { data: loggedInUser } = useQuery(LOGGED_IN_QUERY);

  const { data: paymentReportRaw } = useQuery(PAYMENT_REPORTS, {
    skip: loggedInUser?.loggedInUser?.role !== "SUPERADMIN",
  });
  const paymentReport = paymentReportRaw?.paymentReports || {};
  const potentialIncome =
    paymentReport?.currentMonth?.potentialIncome?.toLocaleString();
  const actualIncome =
    paymentReport?.currentMonth?.realIncome?.toLocaleString();
  const percentage = paymentReport?.currentMonth?.percentage?.toFixed(2);

  const potentialIncomePrev6 =
    paymentReport?.lastSixMonthsSummary?.potentialIncome?.toLocaleString();
  const actualIncomePrev6 =
    paymentReport?.lastSixMonthsSummary?.realIncome?.toLocaleString();

  const potentialIncomeNext6 =
    paymentReport?.nextSixMonthsForecast?.potentialIncome?.toLocaleString();
  const actualIncomeNext6 =
    paymentReport?.nextSixMonthsForecast?.realIncome?.toLocaleString();

  const monthlyTxt = paymentReport?.currentMonth
    ? `${actualIncome} / ${potentialIncome} ₺`
    : "-";

  const monthlyTxtPrev6 = paymentReport?.lastSixMonthsSummary
    ? `${actualIncomePrev6} / ${potentialIncomePrev6} ₺`
    : "-";

  const monthlyTxtNext6 = paymentReport?.nextSixMonthsForecast
    ? `${actualIncomeNext6} / ${potentialIncomeNext6} ₺`
    : "-";

  const thisMonthNameLocale = new Date().toLocaleString("tr-TR", {
    month: "long",
  });

  return (
    <Grid container>
      <SectionTitle
        title="Aidat Raporları"
        description="Sporcularınızın aidat ödemelerini bu ekrandan takip edebilirsiniz."
      />
      <Grid container>
        {loggedInUser?.loggedInUser?.role === "SUPERADMIN" && (
          <Grid container sx={{ mt: 3, mb: 1, px: 3 }}>
            <Grid container spacing={4}>
              <HomeInfoCard
                title="Gerçekleşen / Potansiyel"
                month={thisMonthNameLocale}
                smallerInfo={true}
                info={monthlyTxt}
              />
              <HomeInfoCard
                title="Gelir Oranı"
                smallerInfo={true}
                month={thisMonthNameLocale}
                info={percentage ? `% ${percentage}` : "-"}
                progress={percentage || 0}
              />
              <HomeInfoCard
                smallerInfo={true}
                month={"Son 3 Ay"}
                title="Gerçekleşen / Potansiyel"
                info={monthlyTxtPrev6}
              />
              <HomeInfoCard
                smallerInfo={true}
                month={"Tahmini Önümüzdeki 6 Ay"}
                title="Gerçekleşebilecek / Potansiyel"
                info={monthlyTxtNext6}
              />
            </Grid>
          </Grid>
        )}
        <DuesReportsCard />
      </Grid>
    </Grid>
  );
}
