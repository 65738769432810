import { gql } from "@apollo/client";

export const ATHLETE_FRAGMENT = gql`
  fragment AthleteFragment on Athlete {
    id
    firstName
    lastName
    email
    status
    groupLeagueId
    dateOfBirth
    phone
    playerPositionId
    parentNumber
    height
    weight
    jumpHeight
    blockHeight
    spikeHeight
    exitSpeed
    lastPaymentDate
    totalDebtAmount
    totalPaymentStatus
    specialPaymentAmount
  }
`;
