import { createTheme } from "@mui/material/styles";
import { HiChevronDown } from "react-icons/hi2";

const fonts = "Inter, sans-serif";

const colors = {
  primary: {
    main: "#6366F1",
    100: "#6366f114",
  },
  secondary: {
    main: "#f4f4f4",
  },
  grey: {
    main: "#D2D6DB",
  },
  success: {
    main: "#107569",
    100: "#15b79e1f",
  },
};
const typography = {
  fontFamily: fonts,
  h1: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: "120%",
    fontStyle: "normal",
    fontFamily: "Plus Jakarta Sans",
  },
  h5: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "120%",
    fontFamily: "Plus Jakarta Sans",
  },
  h6: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: "120%",
    fontFamily: "Plus Jakarta Sans",
  },
  captionTitle: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: "120%",
    fontFamily: "Plus Jakarta Sans",
    fontStyle: "normal",
    color: "#111927",
    fontFeatureSettings: `'clig' off, 'liga' off`,
  },
  TableTitle: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "100%",
    fontStyle: "normal",
    letterSpacing: "0.5px",
    textTransform: "uppercase",
    color: "#111927",
    fontFeatureSettings: `'clig' off, 'liga' off`,
  },
  body2: {
    color: "#6C737F",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "157%",
    fontFeatureSettings: `'clig' off, 'liga' off`,
  },
  body1: {
    fontSize: 14,
    fontWeight: 400,
    color: "#111927",
    lineHeight: "157%",
    fontFeatureSettings: `'clig' off, 'liga' off`,
  },
  subtitle1: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "157%",
    fontFeatureSettings: `'clig' off, 'liga' off`,
    color: "#111927",
  },
  subtitle2: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "157%",
    fontFeatureSettings: `'clig' off, 'liga' off`,
    color: "#6C737F",
  },
  subtitle3: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "150%",
    letterSpacing: "0.15px",
    fontFeatureSettings: `'clig' off, 'liga' off`,
    color: "#111927",
    fontFamily: "Inter",
  },
  caption: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "200%",
    letterSpacing: "0.5px",
    fontFeatureSettings: `'clig' off, 'liga' off`,
  },
  caption2: {
    fontSize: 10,
    fontWeight: 500,
    lineHeight: "140%",
    fontFeatureSettings: `'clig' off, 'liga' off`,
    color: "#6C737F",
  },
  placeholder: {
    fontSize: 14,
    color: "#6C737F",
    fontWeight: 400,
    opacity: 0.5,
  },
};
export const goztepeTheme = createTheme({
  palette: {
    ...colors,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@global": { body: { fontFamily: fonts } },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingLeft: 8,
          fontFamily: "Inter",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          "&::before": { borderBottom: "unset" },
          "&::after": { borderBottom: "unset" },
          "&:hover:not(.Mui-disabled, .Mui-error):before": {
            borderBottom: "unset",
          },
          borderRadius: "8px",
          backgroundColor: "#FFF",
          border: "1px solid #D2D6DB",
          "& .MuiFormHelperText-root": {
            color: "#F04438",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "20px",
            letterSpacing: "0.4px",
          },
        },
      },
    },

    MuiInputAdornment: {
      styleOverrides: {
        root: {
          "& .MuiTypography-root": {
            color: "var(--text-disabled, rgba(17, 25, 39, 0.38))",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Inter",
            fontSize: "13px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "18px",
            letterSpacing: "0.16px",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: "#F04438",
          fontFamily: "Inter",
          fontSize: "10px",
          fontWeight: 400,
          lineHeight: "20px",
          letterSpacing: "0.4px",
          marginTop: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": { color: "#6C737F" },
          color: "#6C737F",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "24px",
          letterSpacing: "0.15px",
          fontFamily: "Inter",
        },
      },
    },

    MuiButtonGroup: {
      styleOverrides: {
        root: {
          boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.08)",
          borderRadius: "12px",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          backgroundColor: "#F3F4F6",
          padding: "24px",
          paddingTop: "24px !important",
        },
      },
    },
    MuiDialogTitle: {
      defaultProps: {},
      styleOverrides: {
        root: {
          padding: "32px 24px 25px",
          "&+.MuiDialogContent-root": { paddingTop: 24 },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "20px",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          ...typography.body1,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "#F8F9FA",
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: `0 1px 2px 0 rgba(0, 0, 0, 0.03),0 1px 6px -1px rgba(0, 0, 0, 0.02),0 2px 4px 0 rgba(0, 0, 0, 0.02)`,
          border: "1px solid #f7f7f7",
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: HiChevronDown,
      },

      styleOverrides: {
        icon: {
          color: "#6C737F",
        },
        select: {
          padding: "14px 14px",
        },
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Inter",
        },
      },
      defaultProps: {
        variantMapping: {
          subtitle3: "span",
        },
      },
    },
    // burda kullandım icine actım böyle biliyon demi bunu?
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: "Inter",
          "&::placeholder": {
            ...typography.placeholder,
          },
        },
      },
    },
    // MuiTextField:{
    //   styleOverrides:{
    //     root:{
    //       "& .MuiFormLabel-root": {
    //         ...typography.placeholder,
    //       },
    //     }
    //   }
    // },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.primary.main,
          },
        },
        notchedOutline: {
          borderColor: colors.grey.main,
        },
        input: {
          "&.Mui-disabled": {
            WebkitTextFillColor: "#111927",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.08)",
          textTransform: "unset",
          fontWeight: 500,
          fontSize: 15,
          padding: "8px 16px",
        },
        outlined: {
          borderColor: colors.grey.main,
        },
        text: {
          boxShadow: "unset",
        },
        textSecondary: {
          color: "#6C737F",
          fontWeight: 600,
          lineHeight: "171.429%",
        },
      },
    },
  },
  typography: {
    ...typography,
  },
});
