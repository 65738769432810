import { Grid, useMediaQuery } from "@mui/material";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import GoztepeBottomNavigation from "../components/bottomNavigation/GoztepeBottomNavigation";
import { DRAWER_WIDTH } from "../utils";
import MobileDrawer from "../components/drawer/MobileDrawer";
import { useEffect, useState } from "react";
import MyDrawer from "../components/drawer/MyDrawer";
import Header from "../components/header/Header";
import { LOGGED_IN_QUERY } from "../queries";
import { useQuery } from "@apollo/client";
import AthleteDetail from "../pages/AthleteDetail/AthleteDetail";
import { superAdminAccess } from "../routes";

const HomeRoutes = () => {
    const location = useLocation()

    const { data: loggedInUser, loading, } = useQuery(LOGGED_IN_QUERY, {
        fetchPolicy: 'network-only',
    });
    const [open, setOpen] = useState(true);
    const matches = useMediaQuery("(max-width:1200px)");
    useEffect(() => {
        if (matches && open === true) {
            setOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matches]);
    if (loading) {
        return ''
    }
    if (loggedInUser?.loggedInUser?.id && superAdminAccess.find(s => s.path === location.pathname) && loggedInUser?.loggedInUser?.role !== "SUPERADMIN") {
        return <Navigate to={"/"} replace />;
    }
    // const hasAccess = dashboardRoutes?.find()
    if (loggedInUser?.loggedInUser?.id) {

        return (
            <>
                <Grid container >
                    <MyDrawer open={open} setOpen={setOpen} />
                    <MobileDrawer open={open} setOpen={setOpen} />
                    <Grid
                        item
                        xs
                        component="main"
                        sx={{ width: `calc( 100% - ${DRAWER_WIDTH}px )` }}
                    >
                        <Grid container>
                            <Header setOpen={setOpen} />
                            <Grid container sx={{ bgcolor: "#F3F4F6" }}>
                                <Outlet />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <AthleteDetail />
                <GoztepeBottomNavigation />
            </>
        )
    }

    return <Navigate to={"/login"} replace />;
};

export default HomeRoutes
