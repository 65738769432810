import { Grid, Typography } from "@mui/material";
import React from "react";
import LoginHeader from "../loginPage/LoginHeader";
import SignUpButtonCard from "./SignUpButtonCard";

export default function IForgatMyPasswordPageDetailPage() {
  return (
    <>
      <LoginHeader />

      <Grid
        sx={{ height: "calc(100vh - 60px)" }}
        alignItems={"center"}
        container
      >
        <Grid container justifyContent={"center"}>
          <Grid item sx={{ maxWidth: "444px" }}>
            <SignUpButtonCard />
            <Grid
              container
              sx={{
                height: "136px",

                borderRadius: "20px",
                bgcolor: "#FFF",

                boxShadow:
                  "0px 0px 0px 0.5px #00000008, 0px 5px 22px 0px #0000000a",
              }}
            >
              <Grid
                alignItems={"center"}
                container
                justifyContent={"center"}
                sx={{ p: "32px 24px 16px 24px" }}
              >
                <Typography variant="captionTitle" sx={{ fontSize: "18px" }}>
                  Epostanı Kontrol et
                </Typography>
              </Grid>
              <Grid
                component={"span"}
                container
                sx={{ p: "0px 16px 32px 16px" }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "Inter",
                    color: "#6366F1",
                    textAlign: "center",
                  }}
                  component={"span"}
                >
                  zeynep.sahin@example.com{" "}
                  <Typography
                    component={"span"}
                    variant="body2"
                    sx={{ fontFamily: "Inter", mr: "2px" }}
                  >
                    eposta adresine sıfırlama linki gönderdik.
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
