import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { athletesFilterOptions, months } from "../../utils";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { ALL_GROUPS_QUERY, ALL_PLAYER_POSITIONS_QUERY } from "../../queries";
import { useQuery } from "@apollo/client";
import { LuTrash2 } from "react-icons/lu";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function AthletesFilter({ filter, setFilter }) {
  const theme = useTheme();
  const isMobil = useMediaQuery("(max-width:600px)");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        sx={{
          ...(filter.data?.length > 0
            ? { borderColor: "primary.main", bgcolor: "primary.100" }
            : {}),
          p: "7px 16px",
          minWidth: {
            xs: "unset",
            sm: "120px",
            md: "160px",
          },
          justifyContent: "flex-start",
          textTransform: "unset",
          color: "inherit",
          "& .MuiButton-startIcon": {
            marginRight: {
              xs: 0,
              sm: "8px",
            },
            marginLeft: {
              xs: 0,
              sm: "-4px",
            },
          },
        }}
        variant="outlined"
        startIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M6 12H18M3 6H21M9 18H15"
              stroke={
                filter.data?.length > 0 ? theme.palette.primary.main : "black"
              }
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        }
      >
        <Typography
          sx={{ display: { sm: "block", xs: "none" } }}
          variant="body1"
        >
          Filtre{" "}
        </Typography>
        {filter.data?.length > 0 && (
          <Grid
            component={"span"}
            item
            sx={{
              ml: 1,
              p: "1px 8px",
              border: "1px solid red",
              borderRadius: "6px",
              borderColor: "primary.100",
              bgcolor: "primary.main",
              fontSize: 10,
              color: "white",
              fontWeight: 600,
            }}
          >
            {filter.data?.length}
          </Grid>
        )}
      </Button>
      {!isMobil && (
        <Popover
          slotProps={{
            paper: {
              sx: {
                p: 3,
                boxShadow:
                  "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                maxWidth: 480,
                width: "100%",
              },
            },
          }}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <FilterDetail
            open={open}
            filter={filter}
            setFilter={setFilter}
            handleClose={handleClose}
          />
        </Popover>
      )}
      {isMobil && (
        <Dialog
          TransitionComponent={Transition}
          sx={{ "& .MuiPaper-root": { borderRadius: 0 } }}
          fullScreen={isMobil}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle
            component={Grid}
            container
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant="h6">Filtrele</Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon sx={{ color: "#6C737F", width: 20, height: 20 }} />
            </IconButton>
          </DialogTitle>
          <Grid container>
            <DialogContent>
              <Grid container>
                <FilterDetail
                  filter={filter}
                  setFilter={setFilter}
                  handleClose={handleClose}
                />
              </Grid>
            </DialogContent>
          </Grid>
        </Dialog>
      )}
    </>
  );
}

const FilterDetail = ({ open, filter, setFilter, handleClose }) => {
  const [selectedFilters, setSelectedFilters] = useState([
    athletesFilterOptions[0],
  ]);
  useEffect(() => {
    if (filter?.data?.length > 0) {
      setSelectedFilters(filter?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { data: allPlayerPositions } = useQuery(ALL_PLAYER_POSITIONS_QUERY);
  const { data: allGroupLeagueJunctions } = useQuery(ALL_GROUPS_QUERY);
  const handleAddNewFilter = () => {
    let myData = athletesFilterOptions.filter(
      (af) => !selectedFilters.find((sf) => sf.id === af.id)
    );
    if (myData?.length > 0) {
      setSelectedFilters([...selectedFilters, myData[0]]);
    }
  };
  const handleChange = (index, data) => {
    let getfilters = selectedFilters;
    getfilters[index] = { ...getfilters[index], ...data };
    setSelectedFilters([...getfilters]);
  };

  const handleChangeValue = (index, data) => {
    let getfilters = selectedFilters;
    getfilters[index] = { ...getfilters[index], value: data };
    setSelectedFilters([...getfilters]);
  };
  const handleConfirm = () => {
    setFilter({ ...filter, data: selectedFilters });
    handleClose();
  };
  const handleDelete = (dat) => {
    setSelectedFilters(selectedFilters?.filter((f) => f.id !== dat.id));
  };
  return (
    <>
      <Grid container>
        <Typography fontFamily={"Plus Jakarta Sans"} variant="h6">
          Filtre
        </Typography>
      </Grid>
      {selectedFilters.map((selected, index) => (
        <Grid key={index} container sx={{ mt: 2 }}>
          <Grid container alignItems={"center"} spacing={2}>
            <Grid item sm xs={12}>
              <Grid container>
                <Select
                  MenuProps={{
                    sx: {
                      display:
                        athletesFilterOptions.filter(
                          (af) => !selectedFilters.find((sf) => sf.id === af.id)
                        )?.length > 0
                          ? "block"
                          : "none",
                    },
                  }}
                  value={selected}
                  onChange={(e) => handleChange(index, e.target.value)}
                  displayEmpty
                  renderValue={(mySelected) => {
                    if (!mySelected?.id) {
                      return (
                        <Typography variant="placeholder">
                          Filtre seçin...
                        </Typography>
                      );
                    }
                    return mySelected.name;
                  }}
                  fullWidth
                >
                  {athletesFilterOptions
                    .filter(
                      (af) => !selectedFilters.find((sf) => sf.id === af.id)
                    )
                    .map((opt, index) => (
                      <MenuItem key={index} value={opt}>
                        {opt.name}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
            </Grid>
            <Grid item sm xs={12}>
              <Grid container>
                <Select
                  value={selected}
                  onChange={(e) => handleChangeValue(index, e.target.value)}
                  MenuProps={{
                    sx: {
                      "& .MuiPaper-root": {
                        maxHeight: 240,
                      },
                      "& .MuiListSubheader-root": {
                        borderBottom: "1px solid #f1f1f1",
                        color: "#000",
                      },
                    },
                  }}
                  displayEmpty
                  renderValue={(mySelected) => {
                    if (!mySelected?.value) {
                      return (
                        <Typography variant="placeholder">
                          {selected?.id === 1 && "Oyuncu posizyonu seçin"}
                          {selected?.id === 2 && `Grup seçin`}
                          {selected?.id === 3 && `Ay şeçin`}
                        </Typography>
                      );
                    }
                    return (
                      mySelected?.value?.name ||
                      mySelected?.value?.groupLeagueName
                    );
                  }}
                  fullWidth
                >
                  {selected?.id === 1 &&
                    allPlayerPositions?.allPlayerPositions?.map((position) => {
                      return (
                        <MenuItem value={position}>{position.name}</MenuItem>
                      );
                    })}
                  {selected?.id === 2 &&
                    allGroupLeagueJunctions?.allGroupLeagueJunctions?.map(
                      (position) => {
                        return (
                          <MenuItem value={position}>
                            {position?.groupLeagueName}
                          </MenuItem>
                        );
                      }
                    )}
                  {selected?.id === 3 &&
                    months?.map((position) => {
                      return (
                        <MenuItem value={position}>{position?.name}</MenuItem>
                      );
                    })}
                </Select>
              </Grid>
            </Grid>
            <Grid item>
              <IconButton onClick={() => handleDelete(selected)} size={"small"}>
                <LuTrash2 />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12} sx={{ display: { xs: "block", sm: "none" } }}>
        <Grid container>
          {athletesFilterOptions.filter(
            (af) => !selectedFilters.find((sf) => sf.id === af.id)
          )?.length > 0 && (
            <Button onClick={handleAddNewFilter} sx={{ px: "4px" }}>
              + Filtre ekle
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          mt: 2,
          display: { xs: "none", sm: "flex", justifyContent: "space-between" },
        }}
      >
        <Grid item>
          {athletesFilterOptions.filter(
            (af) => !selectedFilters.find((sf) => sf.id === af.id)
          )?.length > 0 && (
            <Button onClick={handleAddNewFilter} sx={{ px: "4px" }}>
              + Filtre ekle
            </Button>
          )}
        </Grid>
        <Grid item>
          <Grid container>
            <Button
              sx={{ mr: "10px", p: "8px 22px" }}
              variant="contained"
              color="secondary"
              onClick={() => {
                handleClose();
                setFilter({ ...filter, data: null });
              }}
            >
              Temizle
            </Button>
            <Button
              onClick={handleConfirm}
              sx={{ p: "8px 24px" }}
              variant="contained"
            >
              Uygula
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          p: 1,
          display: { sm: "none", xs: "flex" },
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <Button
          sx={{ mr: "10px", p: "8px 22px", flex: 1 }}
          variant="contained"
          color="secondary"
          onClick={() => {
            handleClose();
            setFilter({ ...filter, data: null });
          }}
        >
          Temizle
        </Button>
        <Button
          onClick={handleConfirm}
          sx={{ p: "8px 24px", flex: 1 }}
          variant="contained"
        >
          Uygula
        </Button>
      </Grid>
    </>
  );
};
