import { Grid, Typography } from "@mui/material";
import React from "react";
import MatchResultTeamDetail from "./MatchResultTeamDetail";
import MatchResultsMobileCard from "./MatchResultsMobileCard";

export default function RightCard({ res }) {
  return (
    <Grid
      xs={12}
      md={10}
      sm={12}
      lg={10}
      xl={11}
      item
      sx={{ mt: { xs: "16px", lg: "unset" } }}
    >
      <Grid
        container
        justifyContent={"space-between"}
        sx={{
          p: { sm: "16px 16px", xs: "block" },
          alignItems: { xs: "baseline", sm: "baseline", md: "center" },
        }}
      >
        {/* burası 1.logo */}
        <MatchResultTeamDetail
          names={res?.name}
          levels={res?.level}
          png={res?.png1}
          flexDirection="row"
          end="unset"
          justifyContent="unset"
          paddingRight="unset"
          paddingLeft="16px"
          column="column"
          row="row"
          textAlign="flex-start"
          flexEnd="flex-start"
        />
        {/* burası ortadaki 3-2 */}
        <Grid
          item
          sx={{
            display: { xs: "none", sm: "none", md: "none", lg: "block" },
          }}
          lg={3}
        >
          <Grid container justifyContent={"center"}>
            <Typography
              sx={{
                fontStyle: "normal",
                color: "#111927",
                fontSize: { xl: "24px", lg: "20px" },
                fontFeatureSettings: `'clig' off, 'liga' off`,
              }}
              variant="h5"
            >
              {res?.results}
            </Typography>
          </Grid>
          <Grid container justifyContent={"center"}>
            <Typography
              sx={{
                fontStyle: "normal",
                fontSize: { xl: "24px", lg: "unset" },
              }}
              variant="body1"
            >
              {res?.resultsname}
            </Typography>
          </Grid>
        </Grid>
        {/* burası soldaki 2.logo */}
        <MatchResultTeamDetail
          names={res?.name2}
          levels={res?.level2}
          png={res?.png2}
          flexDirection="row-reverse"
          justifyContent="end"
          end="end"
          paddingRight="16px"
          paddingLeft="unset"
          column="column"
          row="row"
          textAlign="end"
          flexEnd="flex-end"
        />

        {/* burası ortadaki alanının mobilde görünümü */}
        <MatchResultsMobileCard res={res} />
      </Grid>
    </Grid>
  );
}
