import React, { useEffect, useState } from "react";
import {
  Grid,
  InputAdornment,
  Typography,
  TextField,
  IconButton,
  useMediaQuery,
  Checkbox,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AthleteProfilCard from "../../../pages/AthleteDetail/components/AthleteProfilCard";
import DuesPaymentDate from "./DuesPaymentDate";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation, useQuery } from "@apollo/client";
import { ALL_PAYMENTS_QUERY, GET_ATHLETE_DETAIL_QUERY } from "../../../queries";
import moment from "moment";
import PaymentStatusChip from "./PaymentStatusChip";
import { COMPLETE_PAYMENTS_MUTATION } from "../../../mutations";
import { toast } from "react-hot-toast";
import { getAidatNameFromDate } from "../../../utils";
export default function DuesPaymentAction({
  setDialogOpen,
  dialogOpen,
  playerId,
}) {
  const [selected, setSelected] = useState([]);

  const [completePayments] = useMutation(COMPLETE_PAYMENTS_MUTATION, {
    refetchQueries: ["allAthletes", "allPayments"],
  });

  const { data: allPayments, loading } = useQuery(ALL_PAYMENTS_QUERY, {
    variables: {
      athleteId: playerId,
    },
    skip: !dialogOpen,
    fetchPolicy: "cache-and-network",
  });
  useEffect(() => {
    if (!loading) {
      setSelected(
        allPayments?.allPayments?.payments?.filter(
          (payment) => payment?.status !== "Paid"
        )
      );
    }
  }, [allPayments, loading]);
  const { data: getAthlete } = useQuery(GET_ATHLETE_DETAIL_QUERY, {
    variables: {
      getAthleteId: playerId,
    },
    skip: !dialogOpen,
  });
  const handledialogClose = () => {
    setDialogOpen(false);
  };
  const isMobil = useMediaQuery("(max-width:600px)");
  let totalSum = 0;
  selected?.map((s) => {
    totalSum = totalSum + s?.amount;
    return null;
  });
  const handleSelected = (payment) => {
    if (selected.find((s) => s.id === payment.id)) {
      // var ise selectedin icinden cıkarcaz
      setSelected(selected.filter((s) => s.id !== payment.id));
    } else {
      // yok ise buraya girecek ve selectedin icine ekleyecez.
      setSelected([...selected, payment]);
    }
  };

  const handleConfirm = async () => {
    try {
      completePayments({
        variables: {
          paymentIds: selected?.map((s) => s.id),
        },
      });
      toast.success("Ödeme alma işlemi başarıyla tamamlandı!");
    } catch (error) {
      toast.error(error.message);
    }
    handledialogClose();
  };
  return (
    <Dialog fullScreen={isMobil} open={dialogOpen} onClose={handledialogClose}>
      <DialogTitle
        component={Grid}
        container
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h6">Ödeme Al</Typography>
        <IconButton
          aria-label="close"
          onClick={handledialogClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ color: "#6C737F", width: 20, height: 20 }} />
        </IconButton>
      </DialogTitle>
      <Grid container>
        <DialogContent>
          <Grid spacing={2} container>
            <Grid item xs={12}>
              <AthleteProfilCard
                firstName={getAthlete?.getAthlete?.firstName}
                lastName={getAthlete?.getAthlete?.lastName}
                email={getAthlete?.getAthlete?.email}
              />
            </Grid>
            <Grid item xs={6}>
              <DuesPaymentDate
                dateColor="#6C737F"
                labelText="Son Ödeme Tarihi"
                date={
                  getAthlete?.getAthlete?.lastPaymentDate
                    ? moment(getAthlete?.getAthlete?.lastPaymentDate)?.format(
                        "DD/MM/YYYY"
                      )
                    : "-"
                }
              />
            </Grid>

            <Grid item xs={6}>
              <DuesPaymentDate
                dateColor="#F04438"
                labelText="Ödenecek Tutar"
                date={getAthlete?.getAthlete?.totalDebtAmount}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                sx={{
                  p: { xs: 2, sm: 3 },
                  borderRadius: "20px",
                  padding: "24px",
                  alignSelf: "stretch",
                  gap: "16px",
                  bgcolor: "#fff",
                  boxShadow:
                    "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                }}
              >
                <Grid container>
                  <Typography variant="subtitle1">Eksik Ödemeler</Typography>
                </Grid>
                <Grid container>
                  {allPayments?.allPayments?.payments
                    ?.filter((payment) => payment.status !== "Paid")
                    ?.map((payment) => {
                      let mydata = !!selected?.find((s) => s.id === payment.id);
                      return (
                        <Grid
                          sx={{ cursor: "pointer" }}
                          onClick={() => handleSelected(payment)}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          container
                          key={payment?.id}
                        >
                          <Checkbox checked={mydata} />
                          <Typography>
                            {getAidatNameFromDate(payment?.installmentDate)}
                          </Typography>
                          <Typography>
                            {moment(payment?.installmentDate).format(
                              "DD/MM/YYYY"
                            )}
                          </Typography>
                          <Typography>{payment?.amount} TL</Typography>
                          <Grid item>
                            <PaymentStatusChip
                              ödemeDurumu="ÖDEME YAPILMADI"
                              value={"Unpaid"}
                            />
                          </Grid>
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                sx={{
                  p: { xs: 2, sm: 3 },
                  borderRadius: "20px",
                  padding: "24px",
                  alignSelf: "stretch",
                  gap: "16px",
                  bgcolor: "#fff",
                  boxShadow:
                    "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                }}
              >
                <Grid container>
                  <Typography variant="subtitle1">Toplam Borç</Typography>
                </Grid>
                <Grid container>
                  <TextField
                    fullWidth
                    value={totalSum}
                    placeholder="Ödeme Miktarı"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">TL</InputAdornment>
                      ),
                    }}
                  ></TextField>
                </Grid>
                <Grid container sx={{ mt: 6 }} justifyContent={"flex-end"}>
                  <Button
                    sx={{ mr: 2 }}
                    variant="contained"
                    color="secondary"
                    onClick={handledialogClose}
                  >
                    İptal
                  </Button>
                  <Button
                    onClick={handleConfirm}
                    disabled={selected?.length === 0}
                    variant="contained"
                  >
                    Ödeme Al
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Grid>
    </Dialog>
  );
}
