import {
  ListItemIcon,
  ListItemText,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { Grid } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import { drawerMenuOptions } from "../../utils";
import SettingsCollapse from "../collapse/SettingsCollapse";
import { useTheme } from "@mui/material/styles";
import { useQuery } from "@apollo/client";
import { ALL_LEAGUES_QUERY, LOGGED_IN_QUERY } from "../../queries";
import DrawerBottomMenu from "./DrawerBottomMenu";
// import DrawerBottomMenu from "./DrawerBottomMenu";

export default function DrawerMenu({ open, setOpen }) {
  const { data: loggedInUser } = useQuery(LOGGED_IN_QUERY);
  const { data: allLeagues } = useQuery(ALL_LEAGUES_QUERY);
  console.log("oy", allLeagues);
  const theme = useTheme();
  const matches = useMediaQuery("(max-width:1200px)");

  return (
    <Grid container>
      {drawerMenuOptions.map((opt, index) => (
        <Tooltip
          disableHoverListener={open}
          placement={"right-start"}
          title={opt.title}
        >
          <Grid
            onClick={() => {
              if (matches) {
                setOpen(false);
              }
            }}
            to={opt.to}
            component={NavLink}
            sx={{
              p: "8px 16px",
              mb: 0.5,
              cursor: "pointer",
              minHeight: 46,
              borderRadius: "12px",
              "&:hover": {
                bgcolor: "#1119270A",
              },
              "& svg": { stroke: "#6C737F" },
              "&.active span": { color: theme.palette.primary.main },
              "&.active": {
                color: theme.palette.primary.main,
                bgcolor: theme.palette.primary[100],
              },
              "&.active svg": {
                stroke: theme.palette.primary.main,
              },
            }}
            alignItems="center"
            container
            flexWrap={"nowrap"}
            key={index}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 0,
                justifyContent: "center",
              }}
            >
              {opt.icon}
            </ListItemIcon>

            <ListItemText variant="body1" primary={opt.title} />
          </Grid>
        </Tooltip>
      ))}

      {loggedInUser?.loggedInUser?.role === "SUPERADMIN" ? (
        <Tooltip
          disableHoverListener={open}
          placement={"right-start"}
          title="Koçlarımız"
        >
          <Grid
            onClick={() => {
              if (matches) {
                setOpen(false);
              }
            }}
            to="/koclar"
            component={NavLink}
            sx={{
              p: "8px 16px",
              mb: 0.5,
              cursor: "pointer",
              minHeight: 46,
              borderRadius: "12px",
              "&:hover": {
                bgcolor: "#1119270A",
              },
              "& svg": { stroke: "#6C737F" },
              "&.active span": { color: theme.palette.primary.main },
              "&.active": {
                color: theme.palette.primary.main,
                bgcolor: theme.palette.primary[100],
              },
              "&.active svg": {
                stroke: theme.palette.primary.main,
              },
            }}
            alignItems="center"
            container
            flexWrap={"nowrap"}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 0,
                justifyContent: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M9.1 15.8C7.6 15.8 6.325 15.275 5.275 14.225C4.225 13.175 3.7 11.9 3.7 10.4C3.7 10.235 3.7075 10.07 3.7225 9.905C3.7375 9.74 3.76 9.575 3.79 9.41C3.715 9.44 3.625 9.4625 3.52 9.4775C3.415 9.4925 3.325 9.5 3.25 9.5C2.62 9.5 2.0875 9.2825 1.6525 8.8475C1.2175 8.4125 1 7.88 1 7.25C1 6.62 1.2064 6.0875 1.6192 5.6525C2.032 5.2175 2.5531 5 3.1825 5C3.6775 5 4.1239 5.1386 4.5217 5.4158C4.9195 5.693 5.2006 6.0494 5.365 6.485C5.86 6.035 6.4264 5.675 7.0642 5.405C7.702 5.135 8.3806 5 9.1 5H17.92C18.5165 5 19 5.48353 19 6.08V7.42C19 8.01647 18.5165 8.5 17.92 8.5H14.5V10.4C14.5 11.9 13.975 13.175 12.925 14.225C11.875 15.275 10.6 15.8 9.1 15.8ZM3.25 8.15C3.505 8.15 3.7189 8.0636 3.8917 7.8908C4.0645 7.718 4.1506 7.5044 4.15 7.25C4.1494 6.9956 4.063 6.782 3.8908 6.6092C3.7186 6.4364 3.505 6.35 3.25 6.35C2.995 6.35 2.7814 6.4364 2.6092 6.6092C2.437 6.782 2.3506 6.9956 2.35 7.25C2.3494 7.5044 2.4358 7.7183 2.6092 7.8917C2.7826 8.0651 2.9962 8.1512 3.25 8.15ZM9.1 13.55C9.97 13.55 10.7125 13.2425 11.3275 12.6275C11.9425 12.0125 12.25 11.27 12.25 10.4C12.25 9.53 11.9425 8.7875 11.3275 8.1725C10.7125 7.5575 9.97 7.25 9.1 7.25C8.23 7.25 7.4875 7.5575 6.8725 8.1725C6.2575 8.7875 5.95 9.53 5.95 10.4C5.95 11.27 6.2575 12.0125 6.8725 12.6275C7.4875 13.2425 8.23 13.55 9.1 13.55ZM9.1 12.2C9.595 12.2 10.0189 12.0239 10.3717 11.6717C10.7245 11.3195 10.9006 10.8956 10.9 10.4C10.8994 9.9044 10.7233 9.4808 10.3717 9.1292C10.0201 8.7776 9.5962 8.6012 9.1 8.6C8.6038 8.5988 8.1802 8.7752 7.8292 9.1292C7.4782 9.4832 7.3018 9.9068 7.3 10.4C7.2982 10.8932 7.4746 11.3171 7.8292 11.6717C8.1838 12.0263 8.6074 12.2024 9.1 12.2Z"
                  fill="#6C737F"
                />
              </svg>
            </ListItemIcon>

            <ListItemText variant="body1" primary="Koçlarımız" />
          </Grid>
        </Tooltip>
      ) : null}
      <SettingsCollapse open={open} setOpen={setOpen} />
      {open && (
        <Grid container>
          {allLeagues?.allLeagues?.map((ar, index) => {
            return (
              <DrawerBottomMenu
                setOpen={setOpen}
                open={open}
                ar={ar}
                index={index}
                res={DrawerMenu[0]}
              />
            );
          })}
        </Grid>
      )}
    </Grid>
  );
}
