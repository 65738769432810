import { Grid, Typography } from "@mui/material";
import React from "react";
import UpdateDialog from "./UpdateDialog";
import DeleteDialog from "./DeleteDialog";
import { LOGGED_IN_QUERY } from "../../../queries";
import { useQuery } from "@apollo/client";

export default function LeaguesCard({ opt, index }) {
  const { data: loggedInUser } = useQuery(LOGGED_IN_QUERY);
  return (
    <Grid container sx={{ pb: "32px" }}>
      <Grid item xs>
        <Grid container>
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            {opt.leagueName}
          </Typography>
        </Grid>
        <Grid container>
          <Typography variant="body2">{opt.totalAthletes} Sporcu</Typography>
        </Grid>
      </Grid>
      {loggedInUser?.loggedInUser?.role === "SUPERADMIN" && (
        <Grid item>
          <Grid container>
            <Grid
              item
              sx={{
                pr: "7px",
                cursor: "pointer",
              }}
            >
              <UpdateDialog opt={opt} title={opt?.leagueName} />
            </Grid>
            <Grid tem sx={{ pr: "10px", cursor: "pointer" }}>
              <DeleteDialog opt={opt} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
