import { Grid, Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import React from "react";
import MatchResultCard from "./MatchResultCard";
import { MatchResult } from "../../utils";
import MatchResultsDetailTable from "./MatchResultsDetailTable";
import { NavLink } from "react-router-dom";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function MatchResultsDetails() {
  return (
    <Grid container sx={{ p: "24px 24px", background: "#FFF" }}>
      <Grid container sx={{ pb: "24px" }}>
        <div role="presentation" onClick={handleClick}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" component={NavLink} to={"/mac-sonuclari"}>
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, fontStyle: "normal" }}
              >
                Maç Sonuçları
              </Typography>
            </Link>

            <Typography
              variant="body2"
              sx={{ fontWeight: 500, fontStyle: "normal", color: "#6366F1" }}
            >
              GENÇLER LİGİ - GENÇLER A1
            </Typography>
          </Breadcrumbs>
        </div>
      </Grid>
      <MatchResultCard hideButton={true} res={MatchResult[0]} index={0} />
      <MatchResultsDetailTable />
    </Grid>
  );
}
