import { Grid } from "@mui/material";
import React from "react";
import AthletesCard from "../components/card/AthletesCard";
import AddAthleteDialog from "../components/dialog/AddAthleteDialog";
import SectionTitle from "../components/title/SectionTitle";

export default function Athletes() {
  return (
    <Grid container>
      <SectionTitle
        title="Sporcularımız"
        description="Sisteme kayıtlı sporcularınıza buradan ulaşabilirsiniz..."
        button={<AddAthleteDialog />}
      />
      <Grid container >
        <AthletesCard />
      </Grid>
    </Grid>
  );
}
