import { Grid } from "@mui/material";
import React from "react";
import CardTitle from "./CardTitle";
import LeftCard from "./LeftCard";
import RightCard from "./RightCard";

export default function MatchResultCard({ res, index, hideButton }) {
  return (
    <Grid
      container
      key={index}
      sx={{
        boxShadow: "0px 0px 0px 0.5px #00000008, 0px 5px 22px 0px #0000000a",
        borderRadius: "20px",
        bgcolor: "white",
        mb: "24px",
      }}
    >
      {/* Cardın baslik alanı */}
      <CardTitle res={res} index={index} hideButton={hideButton} />

      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        alignSelf={"stretch"}
        sx={{
          p: { sm: "8px 24px 16px 24px", xs: "8px 16px 16px 16px" },
        }}
      >
        {/*Cardın sol tarafı s*/}
        <LeftCard res={res} />

        {/* Cardın sag tarafı */}

        <RightCard res={res} />
      </Grid>
    </Grid>
  );
}
