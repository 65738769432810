import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";

import LeaguesCard from "./LeaguesCard";
import SettingsTabs from "../SettingsTabs";

import CreateLeaguesDialog from "./CreateLeaguesDialog";
import { useQuery } from "@apollo/client";
import { ALL_LEAGUES_QUERY, LOGGED_IN_QUERY } from "../../../queries";
import SectionTitle from "../../../components/title/SectionTitle";
export default function SettingsLeagues() {
  const { data: allLeagues, loading } = useQuery(ALL_LEAGUES_QUERY);
  const { data: loggedInUser } = useQuery(LOGGED_IN_QUERY);

  //DİALOG

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const isMobil = useMediaQuery("(max-width:600px)");
  return (
    <>
      <SectionTitle
        title="Ayarlar"
        description="Lig bilgilerine buradan ulaşabilirsiniz."
      />
      <Grid container sx={{ px: "24px", py: "16px", pt: 3 }}>
        <Grid container sx={{ pb: "24px" }}>
          <Grid
            container
            sx={{
              p: "8px 24px",
              borderRadius: "20px",
              boxShadow:
                "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
              bgcolor: "white",
            }}
          >
            <Grid container sx={{ pb: "24px" }}>
              <SettingsTabs />
            </Grid>
            {allLeagues?.allLeagues?.map((opt, index) => {
              return <LeaguesCard opt={opt} key={index} index={index} />;
            })}
            {!loading && allLeagues?.allLeagues?.length === 0 && (
              <Grid container justifyContent={"center"}>
                <Typography>Lig Bulunamadı...</Typography>
              </Grid>
            )}
            <CreateLeaguesDialog
              handleClose={handleClose}
              isMobil={isMobil}
              open={open}
            />

            <Grid
              container
              sx={{
                justifyContent: "center !important",
              }}
            ></Grid>
            {loggedInUser?.loggedInUser?.role === "SUPERADMIN" && (
              <Grid
                sx={{
                  position: "sticky",
                  bottom: 0,
                  background: "white",
                }}
                container
                justifyContent={"end"}
              >
                <Button variant="contained" onClick={handleClickOpen}>
                  Lig Ekle
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
