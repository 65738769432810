import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Grid, Skeleton, TablePagination } from "@mui/material";
import AvatarCard from "../card/athleteTable/AvatarCard";
import { Typography } from "@mui/material";
import PlayerPositionSelect from "../card/athleteTable/PlayerPositionSelect";
import PlayerGroupsSelect from "../card/athleteTable/PlayerGroupsSelect";
import StatusChip from "../card/athleteTable/StatusChip";
import ActionCard from "../card/athleteTable/ActionCard";
import { ATHLETE_LIST_QUERY } from "../../queries";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { MdCall } from "react-icons/md";
import { FaSort } from "react-icons/fa";
import { FaSortUp } from "react-icons/fa";
import { FaSortDown } from "react-icons/fa6";
import { isEmpty } from "lodash";

import { useTheme } from "@mui/material/styles";
import { useParams } from "react-router-dom";

export default function AthleteTable({ search = "", filter }) {
  const theme = useTheme();
  let { id } = useParams();
  console.log("id", id);
  const [total, setTotal] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [sort, setSort] = React.useState({});
  const monthFilter = filter?.data?.find((d) => d.type === "month");

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { data: allAthletes, loading } = useQuery(ATHLETE_LIST_QUERY, {
    variables: {
      skip: page * 10,
      take: rowsPerPage,
      search: search,
      ...(filter?.data?.find((d) => d.id === 1)
        ? { playerPositionId: filter?.data?.find((d) => d.id === 1)?.value?.id }
        : {}),
      ...(filter?.data?.find((d) => d.id === 2)
        ? { groupLeagueId: filter?.data?.find((d) => d.id === 2)?.value?.id }
        : {}),
      ...(id ? { groupLeagueId: id } : {}),
      ...(!isEmpty(monthFilter)
        ? {
            paymentMonth: monthFilter?.value?.id,
            paymentYear: moment().year(),
          }
        : {}),

      ...(!isEmpty(sort)
        ? {
            orderBy: sort,
          }
        : {}),
    },
    fetchPolicy: "cache-and-network",
  });
  React.useEffect(() => {
    if (!loading) {
      setTotal(allAthletes?.allAthletes?.total);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSort = (val) => {
    if (!sort[val]) {
      setSort({ [val]: "asc" });
    } else if (sort[val] === "asc") {
      setSort({ [val]: "desc" });
    } else {
      setSort({});
    }
  };
  React.useEffect(() => {
    setPage(0);
  }, [search]);
  return (
    <>
      <TableContainer
        sx={{ boxShadow: "unset", maxWidth: "100%" }}
        component={Paper}
      >
        <Table
          sx={{
            "& .MuiTableCell-root": {
              border: "unset",
              textAlign: "left",
            },
            "& thead .MuiTableCell-root": {
              fontWeight: 600,
              fontSize: 12,
              color: "#111927",
              letterSpacing: "0.5px",
              textTransform: "uppercase",
            },
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                onClick={() => handleSort("firstName")}
                align="right"
                sx={{ cursor: "pointer", userSelect: "none" }}
              >
                <Grid
                  sx={{
                    color: sort["firstName"]
                      ? theme.palette.primary.main
                      : "inherit",
                  }}
                  container
                  alignItems={"center"}
                >
                  AD SOYAD{" "}
                  {sort["firstName"] !== "asc" &&
                    sort["firstName"] !== "desc" && (
                      <FaSort style={{ marginLeft: 4 }} />
                    )}
                  {sort["firstName"] === "asc" && (
                    <FaSortUp style={{ marginLeft: 4 }} />
                  )}
                  {sort["firstName"] === "desc" && (
                    <FaSortDown style={{ marginLeft: 4 }} />
                  )}
                </Grid>
              </TableCell>
              <TableCell
                onClick={() => handleSort("dateOfBirth")}
                align="right"
                sx={{
                  minWidth: "130px",
                  cursor: "pointer",
                  userSelect: "none",
                }}
              >
                <Grid
                  sx={{
                    color: sort["dateOfBirth"]
                      ? theme.palette.primary.main
                      : "inherit",
                  }}
                  container
                  alignItems={"center"}
                >
                  DOĞUM TARİHİ{" "}
                  {sort["dateOfBirth"] !== "asc" &&
                    sort["dateOfBirth"] !== "desc" && (
                      <FaSort style={{ marginLeft: 4 }} />
                    )}
                  {sort["dateOfBirth"] === "asc" && (
                    <FaSortUp style={{ marginLeft: 4 }} />
                  )}
                  {sort["dateOfBirth"] === "desc" && (
                    <FaSortDown style={{ marginLeft: 4 }} />
                  )}
                </Grid>
              </TableCell>
              <TableCell
                onClick={() => handleSort("phone")}
                align="right"
                sx={{ cursor: "pointer", userSelect: "none" }}
              >
                <Grid
                  sx={{
                    color: sort["phone"]
                      ? theme.palette.primary.main
                      : "inherit",
                  }}
                  container
                  alignItems={"center"}
                >
                  TELEFON{" "}
                  {sort["phone"] !== "asc" && sort["phone"] !== "desc" && (
                    <FaSort style={{ marginLeft: 4 }} />
                  )}
                  {sort["phone"] === "asc" && (
                    <FaSortUp style={{ marginLeft: 4 }} />
                  )}
                  {sort["phone"] === "desc" && (
                    <FaSortDown style={{ marginLeft: 4 }} />
                  )}
                </Grid>
              </TableCell>
              <TableCell align="right" sx={{ minWidth: 164 }}>
                Oyuncu Pozİsyonu
              </TableCell>
              <TableCell align="right">Grup</TableCell>
              <TableCell align="right">Durum</TableCell>
              <TableCell
                sx={{
                  position: "sticky",
                  right: -1,
                  backdropFilter: "blur(8px)",
                  bgcolor: "#ffffff40",
                }}
                align="right"
              >
                Aksiyonlar
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading &&
              !allAthletes?.allAthletes?.athletes &&
              [1, 2, 3, 4, 6, 7, 8, 9, 10].map((s) => (
                <Skeleton
                  sx={{ my: 1.5, bgcolor: "#dcdee29e", borderRadius: "12px" }}
                  variant="rectangular"
                  width={"1351px"}
                  height={"64.13px"}
                />
              ))}
            {allAthletes?.allAthletes?.athletes?.map((athlete, index) => (
              <TableRow
                key={athlete.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{ minWidth: "256px" }}
                  component="th"
                  scope="row"
                >
                  <AvatarCard
                    playerId={athlete?.id}
                    firstName={athlete.firstName}
                    lastName={athlete.lastName}
                    email={athlete.email}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography>
                    {moment(athlete?.dateOfBirth).format("DD/MM/YYYY")}
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{ minWidth: "200px", textDecoration: "underline" }}
                  align="right"
                >
                  {athlete?.phone && (
                    <Grid
                      component={"a"}
                      sx={{ color: "#111927" }}
                      href={`tel:${athlete?.phone}`}
                      container
                      alignItems={"center"}
                    >
                      <MdCall style={{ marginRight: 4 }} />
                      {athlete?.phone}
                    </Grid>
                  )}
                </TableCell>
                <TableCell align="right">
                  <PlayerPositionSelect
                    value={athlete?.playerPositionId}
                    playerId={athlete?.id}
                  />
                </TableCell>
                <TableCell align="right">
                  <PlayerGroupsSelect
                    value={athlete?.groupLeagueId}
                    playerId={athlete?.id}
                  />
                </TableCell>
                <TableCell align="right">
                  <StatusChip value={athlete?.status} />
                </TableCell>
                <TableCell
                  sx={{
                    position: "sticky",
                    right: -1,
                    backdropFilter: "blur(8px)",
                    bgcolor: "#ffffff40",
                  }}
                >
                  <ActionCard status={athlete?.status} playerId={athlete?.id} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && allAthletes?.allAthletes?.athletes?.length === 0 && (
        <Grid container justifyContent={"center"}>
          <Typography>Sporcu bulunamadı...</Typography>
        </Grid>
      )}
      <Grid
        container
        sx={{
          "& .MuiSelect-select-MuiInputBase-input:focus": {
            backgroundColor: "#FFF",
          },
        }}
        justifyContent={"flex-end"}
      >
        <TablePagination
          labelRowsPerPage="Satır sayısı:"
          sx={{ mt: 1 }}
          component="div"
          count={total}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </>
  );
}
