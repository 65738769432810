import { Grid, Typography } from "@mui/material";
import React from "react";
import UpdateDialog from "./UpdateGroupsDialog";
import DeleteDialog from "./DeleteGroupsDialog";
import { useQuery } from "@apollo/client";
import { LOGGED_IN_QUERY } from "../../../queries";

export default function GroupsCard({ opt }) {
  const { data: loggedInUser } = useQuery(LOGGED_IN_QUERY);
  return (
    <Grid container sx={{ pb: "32px" }}>
      <Grid item xs>
        <Grid container>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              fontWeight: 500,
              flexWrap: "wrap",
            }}
            variant="body1"
          >
            {opt.groupName}{" "}
            <svg
              style={{ margin: "0 16px" }}
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
            >
              <path
                d="M7.50008 14.6663H5.83341C3.53223 14.6663 1.66675 12.8009 1.66675 10.4997C1.66675 8.19849 3.53223 6.33301 5.83341 6.33301H7.50008M12.5001 14.6663H14.1667C16.4679 14.6663 18.3334 12.8009 18.3334 10.4997C18.3334 8.19849 16.4679 6.33301 14.1667 6.33301H12.5001M5.83341 10.4997L14.1667 10.4997"
                stroke="#6C737F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <Typography component={"span"} variant="body2">
              {opt?.leagues?.map((l) => l?.leagueName).join(", ")}
            </Typography>
          </Typography>
        </Grid>
        <Grid container>
          <Typography variant="body2">{opt.totalAthletes} Sporcu</Typography>
        </Grid>
      </Grid>
      {loggedInUser?.loggedInUser?.role === "SUPERADMIN" && (
        <Grid item>
          <Grid container>
            <Grid item sx={{ pr: "7px", cursor: "pointer" }}>
              <UpdateDialog opt={opt} title={opt?.groupName} />
            </Grid>
            <Grid tem sx={{ pr: "10px", cursor: "pointer" }}>
              <DeleteDialog opt={opt} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
