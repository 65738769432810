import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import Logo from "../loginPage/image 3.png";

export default function LoginHeader() {
  return (
    <Paper
      sx={{
        width: "100%",
        zIndex: 1,
        position: "relative",
        top: 0,
      }}
      elevation={0}
    >
      <Grid
        container
        spacing={1}
        sx={{ p: "8px 24px" }}
        justifyContent={"center"}
        alignItems="center"
      >
        <Grid item>
          <img style={{ objectFit: "cover" }} src={Logo} alt="göztepe-logo" />
        </Grid>
        <Grid item>
          <Typography variant="captionTitle" sx={{ fontSize: "16px" }}>
            Göztepe Spor Kulübü
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
