import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as yup from "yup";
import { useFormik } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_PROFILE_MUTATION } from "../mutations";
import toast from "react-hot-toast";
import { LOGGED_IN_QUERY } from "../queries";
import ProfilAvatar from "./Profil/components/ProfilAvatar";
import ProfilDetail from "./Profil/components/ProfilDetail";
import { MuiTelInput } from "mui-tel-input";
import moment from "moment";

const validationSchema = yup.object({
  firstName: yup.string().required("Bu alan zorunludur"),
  lastName: yup.string().required("Bu alan zorunludur"),
});

export default function Profil() {
  // Password visibility state
  // const [showPassword, setShowPassword] = useState(false);
  const { data: loggedInUser } = useQuery(LOGGED_IN_QUERY);

  const [editMyProfile] = useMutation(UPDATE_PROFILE_MUTATION);

  const [editScreen, setEditScreen] = useState(true);
  const handleEditScreen = () => {
    setEditScreen(!editScreen);
  };
  const handleCancelEdit = () => {
    setEditScreen(true);
    formik.resetForm();
  };

  // Formik form handling
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: loggedInUser?.loggedInUser?.firstName,
      lastName: loggedInUser?.loggedInUser?.lastName,
      email: loggedInUser?.loggedInUser?.email,
      phone: loggedInUser?.loggedInUser?.phone,
      dateOfBirth: loggedInUser?.loggedInUser?.dateOfBirth,
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      editMyProfile({
        variables: {
          data: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone,
            dateOfBirth: values.dateOfBirth,
            // password: values.password ? values.password : undefined,
            role: values.role,
          },
        },
      })
        .then((data) => {
          toast.success(`Profil başarıyla güncellendi `);
        })
        .catch((err) => {
          toast.error(err.message);
        });
      handleEditScreen();
    },
  });
  return (
    <Grid container sx={{ bgcolor: "#FFF", pt: "4px" }}>
      <ProfilAvatar />
      <Grid container sx={{ p: { sm: "32px 80px", xs: "10px 12px" } }}>
        <form onSubmit={formik.handleSubmit}>
          {editScreen ? (
            <ProfilDetail formik={formik} handleEditScreen={handleEditScreen} />
          ) : (
            <Grid container>
              <Grid
                container
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{
                  pb: "24px",
                  "& .MuiButton-root": {
                    px: "10px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontStyle: "normal",
                    color: "#111927",
                    fontFeatureSettings: `'clig' off, 'liga' off`,
                  }}
                  variant="h6"
                >
                  Hesap Bilgileri
                </Typography>
                <Grid item>
                  {" "}
                  <Button onClick={handleCancelEdit} sx={{ color: "#6C737F" }}>
                    İptal
                  </Button>
                  <Button type="submit">Kaydet</Button>
                </Grid>
              </Grid>

              <Grid
                container
                sx={{
                  boxShadow: "0px 0px 0px 1px #F2F4F7",
                  borderRadius: "8px",
                  px: "24px",
                  minHeight: "330px",
                  height: "100%",
                }}
              >
                <Grid
                  container
                  alignItems={"center"}
                  sx={{ minHeight: "58px", py: "12px" }}
                >
                  <Grid item xs={12} md={4}>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontFamily: "Inter",
                        pb: { xs: "12px", md: "0px" },
                      }}
                      variant="body1"
                    >
                      Ad
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "8px",
                      },
                    }}
                    xs={12}
                    md={8}
                  >
                    <TextField
                      id="firstName"
                      name="firstName"
                      fullWidth
                      size="small"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                      type="text"
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems={"center"}
                  sx={{ minHeight: "58px", pb: "12px" }}
                >
                  <Grid item xs={12} md={4}>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontFamily: "Inter",
                        pb: { xs: "12px", md: "0px" },
                      }}
                      variant="body1"
                    >
                      Soyad
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "8px",
                      },
                    }}
                    xs={12}
                    md={8}
                  >
                    <TextField
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                      }
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                      name="lastName"
                      id="lastName"
                      type="text"
                      fullWidth
                      size="small"
                    ></TextField>
                  </Grid>
                </Grid>

                <Grid
                  container
                  alignItems={"center"}
                  sx={{ minHeight: "58px", pb: "12px" }}
                >
                  <Grid item xs={12} md={4}>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontFamily: "Inter",
                        pb: { xs: "12px", md: "0px" },
                      }}
                      variant="body1"
                    >
                      Eposta Adresi
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={8}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "8px",
                      },
                      "& .Mui-disabled": {
                        WebkitTextFillColor: "#6C737F",
                      },
                    }}
                  >
                    <TextField
                      disabled
                      fullWidth
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      size="small"
                      name="email"
                      id="email"
                    ></TextField>
                  </Grid>
                </Grid>

                <Grid
                  container
                  alignItems={"center"}
                  sx={{ minHeight: "58px", pb: "12px" }}
                >
                  <Grid item xs={12} md={4}>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontFamily: "Inter",
                        pb: { xs: "12px", md: "0px" },
                      }}
                      variant="body1"
                    >
                      Telefon Numarası
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={8}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "8px",
                        paddingLeft: "5px",
                        maxHeight: "37px",
                        height: "100%",
                      },
                    }}
                  >
                    <MuiTelInput
                      forceCallingCode={true}
                      international
                      defaultCountry="TR"
                      fullWidth
                      inputProps={{
                        placeholder: "Telefon numarasını girin",
                      }}
                      id="phone"
                      name="phone"
                      value={formik.values.phone}
                      onChange={(value) =>
                        formik.handleChange({
                          target: {
                            name: "phone",
                            value,
                          },
                        })
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems={"center"}
                  sx={{ minHeight: "58px", pb: "12px" }}
                >
                  <Grid item xs={12} md={4}>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontFamily: "Inter",
                        pb: { xs: "12px", md: "0px" },
                      }}
                      variant="body1"
                    >
                      Doğum Tarihi
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "8px",
                        maxHeight: "37px",
                        height: "100%",
                      },
                      "& .MuiFormControl-root": {
                        width: "100% !important",
                      },
                    }}
                    xs={12}
                    md={8}
                  >
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        id="dateOfBirth"
                        name="dateOfBirth"
                        value={moment(formik.values.dateOfBirth)}
                        onChange={(date) =>
                          formik.setFieldValue("dateOfBirth", date)
                        }
                        placeholderText="Doğum Tarihi*"
                        dateFormat="DD/MM/YYYY"
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </form>
      </Grid>
    </Grid>
  );
}
