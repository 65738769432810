import Ksk from "../src/components/matchResults/image/knk.png";
import Altay from "../src/components/matchResults/image/altay.png";
import Göztepe from "../src/components/matchResults/image/göztepe.png";
import Konak from "../src/components/matchResults/image/konak.png";
import Menemen from "../src/components/matchResults/image/menemen.png";
import moment from "moment";
export const applyLogin = (value) => {
  localStorage.setItem("login", JSON.stringify(value));
};
export const getLogin = () => {
  let newObject = localStorage.getItem("login");
  return JSON.parse(newObject);
};

export const DRAWER_WIDTH = 240;

export const athleteTabsOptions = [
  {
    id: 1,
    title: "Sporcu Tarihçesi",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M9.99999 12.5C7.35826 12.5 5.00899 13.7755 3.51332 15.755C3.19141 16.181 3.03046 16.394 3.03572 16.6819C3.03979 16.9043 3.17946 17.1849 3.35446 17.3222C3.58098 17.5 3.89488 17.5 4.52269 17.5H15.4773C16.1051 17.5 16.419 17.5 16.6455 17.3222C16.8205 17.1849 16.9602 16.9043 16.9643 16.6819C16.9695 16.394 16.8086 16.181 16.4867 15.755C14.991 13.7755 12.6417 12.5 9.99999 12.5Z"
          stroke="#111927"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.99999 10C12.0711 10 13.75 8.32107 13.75 6.25C13.75 4.17893 12.0711 2.5 9.99999 2.5C7.92893 2.5 6.24999 4.17893 6.24999 6.25C6.24999 8.32107 7.92893 10 9.99999 10Z"
          stroke="#111927"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
  },
  {
    id: 2,
    title: "Kişisel Ölçüm Detayları",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M12.0833 4.58333L13.3333 5.83334M9.58334 7.08334L10.8333 8.33334M7.08334 9.58334L8.33334 10.8333M4.58334 12.0833L5.83334 13.3333M2.13805 14.6381L5.36191 17.862C5.52691 18.027 5.60942 18.1095 5.70455 18.1404C5.78824 18.1676 5.87838 18.1676 5.96207 18.1404C6.05721 18.1095 6.13971 18.027 6.30472 17.862L17.8619 6.30477C18.0269 6.13976 18.1094 6.05726 18.1403 5.96212C18.1675 5.87844 18.1675 5.78829 18.1403 5.70461C18.1094 5.60947 18.0269 5.52697 17.8619 5.36196L14.638 2.1381C14.473 1.9731 14.3905 1.89059 14.2954 1.85968C14.2117 1.83249 14.1216 1.83249 14.0379 1.85968C13.9428 1.89059 13.8602 1.9731 13.6952 2.1381L2.13805 13.6953C1.97304 13.8603 1.89054 13.9428 1.85963 14.0379C1.83244 14.1216 1.83244 14.2118 1.85963 14.2955C1.89054 14.3906 1.97304 14.4731 2.13805 14.6381Z"
          stroke="#111927"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
  },
  {
    id: 3,
    title: "Sporcu İstatikleri",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
      >
        <path
          d="M18 17.5H4.33333C3.86662 17.5 3.63327 17.5 3.45501 17.4092C3.29821 17.3293 3.17072 17.2018 3.09083 17.045C3 16.8667 3 16.6334 3 16.1667V2.5M18 5.83333L13.4714 10.3619C13.3064 10.5269 13.2239 10.6094 13.1288 10.6404C13.0451 10.6675 12.9549 10.6675 12.8712 10.6404C12.7761 10.6094 12.6936 10.5269 12.5286 10.3619L10.9714 8.80474C10.8064 8.63973 10.7239 8.55723 10.6288 8.52632C10.5451 8.49912 10.4549 8.49912 10.3712 8.52632C10.2761 8.55723 10.1936 8.63973 10.0286 8.80474L6.33333 12.5M18 5.83333H14.6667M18 5.83333V9.16667"
          stroke="#111927"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
  },
  {
    id: 4,
    title: "Ödeme Bilgileri",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
      >
        <path
          d="M18.8333 8.33333H2.16663M9.66663 11.6667H5.49996M2.16663 6.83333L2.16663 13.1667C2.16663 14.1001 2.16663 14.5668 2.34828 14.9233C2.50807 15.2369 2.76304 15.4919 3.07664 15.6517C3.43316 15.8333 3.89987 15.8333 4.83329 15.8333L16.1666 15.8333C17.1 15.8333 17.5668 15.8333 17.9233 15.6517C18.2369 15.4919 18.4919 15.2369 18.6516 14.9233C18.8333 14.5668 18.8333 14.1001 18.8333 13.1667V6.83334C18.8333 5.89991 18.8333 5.4332 18.6516 5.07668C18.4919 4.76308 18.2369 4.50811 17.9233 4.34832C17.5668 4.16667 17.1 4.16667 16.1666 4.16667L4.83329 4.16667C3.89987 4.16667 3.43316 4.16667 3.07664 4.34832C2.76304 4.50811 2.50807 4.76308 2.34828 5.07668C2.16663 5.4332 2.16663 5.89991 2.16663 6.83333Z"
          stroke="#111927"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
  },
];

export const getAidatNameFromDate = (date) => {
  // this will produce "Mayıs Ayı Aidatı" for example
  // from a date string like "2024-05-01T00:00:00.000Z"
  const month = moment(date).format("MMMM");
  return `${month} Ayı Aidatı`;
};

export const athletesFilterOptions = [
  {
    id: 1,
    name: "Oyuncu Pozisyonu",
    value: "",
    type: "position",
  },
  {
    id: 2,
    name: "Grup",
    value: "",
    type: "group",
  },
  {
    id: 3,
    name: "Ay'a göre filtrele",
    value: "",
    type: "month",
  },
];
export const months = [
  { id: 1, name: "Ocak" },
  { id: 2, name: "Şubat" },
  { id: 3, name: "Mart" },
  { id: 4, name: "Nisan" },
  { id: 5, name: "Mayıs" },
  { id: 6, name: "Haziran" },
  { id: 7, name: "Temmuz" },
  { id: 8, name: "Ağustos" },
  { id: 9, name: "Eylül" },
  { id: 10, name: "Ekim" },
  { id: 11, name: "Kasım" },
  { id: 12, name: "Aralık" },
];
export const drawerMenuOptions = [
  {
    title: "Ana Sayfa",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M6.77168 11.6667C7.14172 13.1043 8.4468 14.1667 10 14.1667C11.5532 14.1667 12.8583 13.1043 13.2283 11.6667M9.18141 2.30333L3.52949 6.69927C3.15168 6.99312 2.96278 7.14005 2.82669 7.32405C2.70614 7.48704 2.61633 7.67065 2.56169 7.86588C2.5 8.08627 2.5 8.32558 2.5 8.80421V14.8333C2.5 15.7667 2.5 16.2335 2.68166 16.59C2.84144 16.9036 3.09641 17.1585 3.41002 17.3183C3.76654 17.5 4.23325 17.5 5.16667 17.5H14.8333C15.7668 17.5 16.2335 17.5 16.59 17.3183C16.9036 17.1585 17.1586 16.9036 17.3183 16.59C17.5 16.2335 17.5 15.7667 17.5 14.8333V8.80421C17.5 8.32558 17.5 8.08627 17.4383 7.86588C17.3837 7.67065 17.2939 7.48704 17.1733 7.32405C17.0372 7.14005 16.8483 6.99312 16.4705 6.69927L10.8186 2.30333C10.5258 2.07562 10.3794 1.96177 10.2178 1.918C10.0752 1.87938 9.92484 1.87938 9.78221 1.918C9.62057 1.96177 9.47418 2.07562 9.18141 2.30333Z"
          stroke=""
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    to: "/",
  },
  {
    title: "Sporcularımız",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M15 13.1974C16.2133 13.8069 17.2534 14.785 18.0127 16.008C18.1631 16.2502 18.2382 16.3713 18.2642 16.539C18.3171 16.8798 18.084 17.2988 17.7666 17.4336C17.6104 17.5 17.4347 17.5 17.0833 17.5M13.3333 9.6102C14.5681 8.99657 15.4167 7.72238 15.4167 6.25C15.4167 4.77762 14.5681 3.50343 13.3333 2.8898M11.6667 6.25C11.6667 8.32107 9.98774 10 7.91667 10C5.8456 10 4.16667 8.32107 4.16667 6.25C4.16667 4.17893 5.8456 2.5 7.91667 2.5C9.98774 2.5 11.6667 4.17893 11.6667 6.25ZM2.13269 15.782C3.46128 13.7871 5.55781 12.5 7.91667 12.5C10.2755 12.5 12.3721 13.7871 13.7006 15.782C13.9917 16.219 14.1372 16.4375 14.1205 16.7166C14.1074 16.9339 13.965 17.2 13.7913 17.3313C13.5683 17.5 13.2615 17.5 12.648 17.5H3.1853C2.57181 17.5 2.26507 17.5 2.04203 17.3313C1.86837 17.2 1.72591 16.9339 1.71286 16.7166C1.69611 16.4375 1.84164 16.219 2.13269 15.782Z"
          stroke=""
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    to: "/sporcular",
  },
  {
    title: "Maç Sonuçları",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          opacity="0.12"
          d="M2.5 6.5C2.5 5.09987 2.5 4.3998 2.77248 3.86502C3.01217 3.39462 3.39462 3.01217 3.86502 2.77248C4.3998 2.5 5.09987 2.5 6.5 2.5H13.5C14.9001 2.5 15.6002 2.5 16.135 2.77248C16.6054 3.01217 16.9878 3.39462 17.2275 3.86502C17.5 4.3998 17.5 5.09987 17.5 6.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V6.5Z"
          fill=""
        />
        <path
          d="M6.66667 12.5V14.1667M10 9.16667V14.1667M13.3333 5.83333V14.1667M6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5V6.5C17.5 5.09987 17.5 4.3998 17.2275 3.86502C16.9878 3.39462 16.6054 3.01217 16.135 2.77248C15.6002 2.5 14.9001 2.5 13.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5Z"
          stroke=""
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    to: "/mac-sonuclari",
  },

  {
    title: "Antrenman Planları",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M17.5 8.33342H2.5M13.3333 1.66675V5.00008M6.66667 1.66675V5.00008M6.5 18.3334H13.5C14.9001 18.3334 15.6002 18.3334 16.135 18.0609C16.6054 17.8212 16.9878 17.4388 17.2275 16.9684C17.5 16.4336 17.5 15.7335 17.5 14.3334V7.33342C17.5 5.93328 17.5 5.23322 17.2275 4.69844C16.9878 4.22803 16.6054 3.84558 16.135 3.6059C15.6002 3.33341 14.9001 3.33341 13.5 3.33341H6.5C5.09987 3.33341 4.3998 3.33341 3.86502 3.6059C3.39462 3.84558 3.01217 4.22803 2.77248 4.69844C2.5 5.23322 2.5 5.93328 2.5 7.33341V14.3334C2.5 15.7335 2.5 16.4336 2.77248 16.9684C3.01217 17.4388 3.39462 17.8212 3.86502 18.0609C4.3998 18.3334 5.09987 18.3334 6.5 18.3334Z"
          stroke=""
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    to: "/antrenman-planlari",
  },
  {
    title: "Aidat Raporları",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M11.6667 1.89136V5.33348C11.6667 5.80018 11.6667 6.03354 11.7575 6.2118C11.8374 6.3686 11.9649 6.49609 12.1217 6.57598C12.2999 6.66681 12.5333 6.66681 13 6.66681H16.4421M6.66667 12.5001V15.0001M13.3333 10.8334V15.0001M10 8.75008V15.0001M16.6667 8.3236V14.3334C16.6667 15.7335 16.6667 16.4336 16.3942 16.9684C16.1545 17.4388 15.7721 17.8212 15.3016 18.0609C14.7669 18.3334 14.0668 18.3334 12.6667 18.3334H7.33334C5.9332 18.3334 5.23314 18.3334 4.69836 18.0609C4.22795 17.8212 3.8455 17.4388 3.60582 16.9684C3.33334 16.4336 3.33334 15.7335 3.33334 14.3334V5.66675C3.33334 4.26662 3.33334 3.56655 3.60582 3.03177C3.8455 2.56137 4.22795 2.17892 4.69836 1.93923C5.23314 1.66675 5.9332 1.66675 7.33334 1.66675H10.0098C10.6213 1.66675 10.927 1.66675 11.2147 1.73582C11.4698 1.79707 11.7137 1.89808 11.9374 2.03515C12.1897 2.18975 12.4059 2.40594 12.8382 2.83832L15.4951 5.49517C15.9275 5.92755 16.1437 6.14374 16.2983 6.39604C16.4353 6.61972 16.5364 6.86358 16.5976 7.11867C16.6667 7.40639 16.6667 7.71213 16.6667 8.3236Z"
          stroke=""
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    to: "/aidat-raporlari",
  },
];
export const settingsOptions = [
  {
    title: "Genel",
    to: "/ayarlar",
  },
  {
    title: "Ligler",
    to: "/ligler",
  },
  {
    title: "Gruplar",
    to: "/gruplar",
  },
];
export const homeTeamOptions = [
  { title: "Minikler A" },
  { title: "Minikler B" },
  { title: "Midiler A1" },
  { title: "Midiler A2" },
  { title: "Midiler B" },
  { title: "Yıldızlar A" },
  { title: "Yıldızlar B" },
  { title: "Gençler A1" },
  { title: "Gençler A2" },
  { title: "Gençler B1" },
  { title: "Gençler B2" },
];

export const athletesTeamOptions = [
  { title: "Tüm Sporcular" },
  // { title: "Takımda Olan Sporcular" },
  // { title: "Spor Okulunda Olanlar" },
];
export const athletesTeamOptionss = [
  { title: "Koçlarımız" },
  // { title: "Takımda Olan Sporcular" },
  // { title: "Spor Okulunda Olanlar" },
];

export const DuesReportsOptions = [
  { title: "Tüm Sporcular" },
  { title: "Aidatı Eksik Sporcular" },
];
export const TrainingPlansOptions = [
  { title: "İzmir Atatürk Spor Salonu" },
  { title: "Balçova" },
  { title: "Halkapınar Küçük S." },
  { title: "İztik S.S." },
  { title: "Gaziemir" },
  { title: "Karabağlar S.S." },
];
export const settingsOption = [
  { title: "Genel", url: "/ayarlar" },
  { title: "Ligler", url: "/ligler" },
  { title: "Gruplar", url: "/gruplar" },
];

export const MatchResult = [
  {
    title: "GENÇLER LİGİ - GENÇLER A1",
    date: "Salı, 4 Haziran 2024",
    dateName: "Maç Tarihi",
    matchDate: "Maç Saati",
    matchDateHour: "16:00",
    location: "İzmir Atatürk Spor Salonu",
    matchLocation: "Maç Mekanı",
    name: "Göztepe Spor Kulübü",
    level: "Gençler A1",
    results: "3-0",
    resultsname: "(25-15,25-12,25-10)",
    name2: "Altay Spor Kulübü",
    level2: "Gençler A1",
    png1: Göztepe,
    png2: Altay,
  },
  {
    title: "GENÇLER LİGİ - GENÇLER B2",
    date: "Pazar, 2 Haziran 2024",
    dateName: "Maç Tarihi",
    matchDate: "Maç Saati",
    matchDateHour: "14:00",
    location: "İzmir Atatürk Spor Salonu",
    matchLocation: "Maç Mekanı",
    name: "Karşıyaka Spor Kulübü",
    level: "Gençler B",
    results: "1-3",
    resultsname: "(25-15,25-12,25-10)",
    name2: "Göztepe Spor Kulübü",
    level2: "Gençler B2",
    png1: Ksk,
    png2: Göztepe,
  },
  {
    title: "MİNİKLER LİGİ - MİNİKLER A",
    date: "Pazar, 2 Haziran 2024",
    dateName: "Maç Tarihi",
    matchDate: "Maç Saati",
    matchDateHour: "14:00",
    location: "İzmir Atatürk Spor Salonu",
    matchLocation: "Maç Mekanı",
    name: "Göztepe Spor Kulübü",
    level: "Minikler A",
    results: "6-0",
    resultsname: "(25-15,25-12)",
    name2: "Menemen Belediye Spor",
    level2: "Minikler A",
    png1: Göztepe,
    png2: Menemen,
  },
  {
    title: "GENÇLER LİGİ - GENÇLER B2",
    date: "Pazar, 2 Haziran 2024",
    dateName: "Maç Tarihi",
    matchDate: "Maç Saati",
    matchDateHour: "14:00",
    location: "İzmir Atatürk Spor Salonu",
    matchLocation: "Maç Mekanı",
    name: "Konak Belediyesi",
    level: "Minikler A",
    results: "1-3",
    resultsname: "(25-15,25-12)",
    name2: "Göztepe Spor Kulübü",
    level2: "Gençler A",
    png1: Konak,
    png2: Göztepe,
  },
  {
    title: "GENÇLER LİGİ - GENÇLER A1",
    date: "Salı, 4 Haziran 2024",
    dateName: "Maç Tarihi",
    matchDate: "Maç Saati",
    matchDateHour: "16:00",
    location: "İzmir Atatürk Spor Salonu",
    matchLocation: "Maç Mekanı",
    name: "Göztepe Spor Kulübü",
    level: "Gençler A1",
    results: "3-0",
    resultsname: "(25-15,25-12,25-10)",
    name2: "Altay Spor Kulübü",
    level2: "Gençler A1",
    png1: Göztepe,
    png2: Altay,
  },
  {
    title: "GENÇLER LİGİ - GENÇLER B2",
    date: "Pazar, 2 Haziran 2024",
    dateName: "Maç Tarihi",
    matchDate: "Maç Saati",
    matchDateHour: "14:00",
    location: "İzmir Atatürk Spor Salonu",
    matchLocation: "Maç Mekanı",
    name: "Karşıyaka Spor Kulübü",
    level: "Gençler B",
    results: "1-3",
    resultsname: "(25-15,25-12,25-10)",
    name2: "Göztepe Spor Kulübü",
    level2: "Gençler B2",
    png1: Ksk,
    png2: Göztepe,
  },
];
