import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import { LinearProgress } from "@mui/material";
import { Grid } from "@mui/material";
import React from "react";

export default function HomeInfoCard({
  title,
  info,
  progress,
  bgcolor,
  month,
  smallerInfo,
}) {
  return (
    <Grid item md={3} container sm={6} xs={12}>
      <Grid
        container
        sx={{
          p: "24px 32px",
          borderRadius: 2.5,
          bgcolor: bgcolor ? bgcolor : "white",
          boxShadow:
            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
          position: "relative",
        }}
      >
        {month && (
          <Typography
            variant="h6"
            color={bgcolor ? "white" : "#6C737F"}
            sx={{ fontWeight: "bold", mb: 1 }}
          >
            {month}
          </Typography>
        )}
        <Grid container direction="column">
          <Typography variant="caption" color={bgcolor ? "white" : "#6C737F"}>
            {title}
          </Typography>
          {info && (
            <Grid container alignItems={"center"}>
              <Typography
                variant={smallerInfo ? "h6" : "h5"}
                color={bgcolor ? "white" : "#111927"}
              >
                {info}
              </Typography>
              {!!progress && (
                <Grid item xs sx={{ ml: 1 }}>
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress variant="determinate" value={progress} />
                  </Box>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
