import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { Avatar } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function AvatarCard({ firstName, lastName, email, playerId }) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Grid
      onClick={() => navigate(`${location?.pathname}?sporcu=${playerId}`)}
      sx={{ cursor: "pointer" }}
      container
      alignItems={"center"}
      flexWrap={"nowrap"}
    >
      <Grid item>
        <Avatar
          sx={{
            fontSize: 14,
            color: "primary.main",
            bgcolor: "primary.100",
            textTransform: "uppercase",
          }}
        >
          {`${firstName?.charAt(0)}${lastName?.charAt(0)}`}
        </Avatar>
      </Grid>
      <Grid item xs>
        <Grid container sx={{ pl: 2 }}>
          <Typography
            sx={{
              "-webkit-box-orient": "vertical",
              "-webkit-line-clamp": "1",
              display: "-webkit-box",
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordBreak: { sm: 'unset', xs: 'break-all' }

            }}
            variant="body1"
            fontWeight={500}
          >
            {`${firstName} ${lastName}`}
          </Typography>
          <Grid container>
            <Typography
              sx={{
                "-webkit-box-orient": "vertical",
                "-webkit-line-clamp": "1",
                display: "-webkit-box",
                overflow: "hidden",
                textOverflow: "ellipsis",
                wordBreak: { sm: 'unset', xs: 'break-all' }
              }}
              variant="body2"
            >
              {email}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
