import React from "react";
import { Button, Grid, IconButton, Menu, MenuItem } from "@mui/material";
import DuesPaymentAction from "./DuesPaymentAction";
import { NavLink, useSearchParams } from "react-router-dom";

export default function DuesAction({ paymentStatus, playerId, button }) {
  let [searchParams] = useSearchParams();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const handleClickOpen = () => {
    setDialogOpen(true);
    handleClose();
  };
  return (
    <Grid container justifyContent={"center"}>
      {button ? (
        <Grid container justifyContent={"flex-start"}>
          {" "}
          <Button size="small" onClick={handleClickOpen} variant="text">
            Ödeme Al
          </Button>
        </Grid>
      ) : (
        <IconButton onClick={handleClick} size="small">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M9.99996 10.8334C10.4602 10.8334 10.8333 10.4603 10.8333 10C10.8333 9.5398 10.4602 9.16671 9.99996 9.16671C9.53972 9.16671 9.16663 9.5398 9.16663 10C9.16663 10.4603 9.53972 10.8334 9.99996 10.8334Z"
              fill="#6C737F"
            />
            <path
              d="M9.99996 5.00004C10.4602 5.00004 10.8333 4.62694 10.8333 4.16671C10.8333 3.70647 10.4602 3.33337 9.99996 3.33337C9.53972 3.33337 9.16663 3.70647 9.16663 4.16671C9.16663 4.62694 9.53972 5.00004 9.99996 5.00004Z"
              fill="#6C737F"
            />
            <path
              d="M9.99996 16.6667C10.4602 16.6667 10.8333 16.2936 10.8333 15.8334C10.8333 15.3731 10.4602 15 9.99996 15C9.53972 15 9.16663 15.3731 9.16663 15.8334C9.16663 16.2936 9.53972 16.6667 9.99996 16.6667Z"
              fill="#6C737F"
            />
            <path
              d="M9.99996 10.8334C10.4602 10.8334 10.8333 10.4603 10.8333 10C10.8333 9.5398 10.4602 9.16671 9.99996 9.16671C9.53972 9.16671 9.16663 9.5398 9.16663 10C9.16663 10.4603 9.53972 10.8334 9.99996 10.8334Z"
              stroke="#6C737F"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9.99996 5.00004C10.4602 5.00004 10.8333 4.62694 10.8333 4.16671C10.8333 3.70647 10.4602 3.33337 9.99996 3.33337C9.53972 3.33337 9.16663 3.70647 9.16663 4.16671C9.16663 4.62694 9.53972 5.00004 9.99996 5.00004Z"
              stroke="#6C737F"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9.99996 16.6667C10.4602 16.6667 10.8333 16.2936 10.8333 15.8334C10.8333 15.3731 10.4602 15 9.99996 15C9.53972 15 9.16663 15.3731 9.16663 15.8334C9.16663 16.2936 9.53972 16.6667 9.99996 16.6667Z"
              stroke="#6C737F"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </IconButton>
      )}
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {paymentStatus !== "Paid" && (
          <MenuItem onClick={handleClickOpen}>Ödeme al</MenuItem>
        )}
        {!searchParams.get("sporcu") && (
          <MenuItem
            onClick={handleClose}
            component={NavLink}
            to={`?sporcu=${playerId}`}
          >
            Ödemeleri görüntüle
          </MenuItem>
        )}
        {/* <MannualAddDues ><MenuItem >Aidat ekle</MenuItem></MannualAddDues> */}
      </Menu>
      <DuesPaymentAction
        playerId={playerId}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
      />
    </Grid>
  );
}
