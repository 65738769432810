import { Grid, TableCell, TableRow } from "@mui/material";
import React from "react";
import moment from "moment";
import DeleteCoachDialog from "./DeleteCoachDialog";
import CoachUpdateDialog from "./CoachUpdateDialog";
import CoachAvatar from "./CoachAvatar";

export default function CoachTableBody({ athlete }) {
  return (
    <TableRow
      key={athlete.id}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        minWidth: "1000px",
        width: "100%",
      }}
    >
      <TableCell sx={{ minWidth: "150px" }} component="th" scope="row">
        {
          <CoachAvatar
            firstName={athlete.firstName}
            lastName={athlete.lastName}
            email={athlete.email}
          />
        }
      </TableCell>
      <TableCell sx={{ minWidth: "150px" }} align="right">
        {athlete.createdAt
          ? moment(athlete.createdAt).format("DD/MM/YYYY")
          : "-"}
      </TableCell>
      <TableCell sx={{ minWidth: "150px" }} align="right">
        {athlete?.teams?.map((coachs) => coachs.groupLeagueName)}
      </TableCell>
      <TableCell sx={{ minWidth: "150px" }} align="right">
        <Grid container>
          <CoachUpdateDialog
            title={athlete?.teams?.map((coachs) => coachs.groupLeagueName)}
            athlete={athlete}
          />
          <DeleteCoachDialog athlete={athlete} />
        </Grid>
      </TableCell>
    </TableRow>
  );
}
