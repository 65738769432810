import { Grid, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react'
import LogoPNG from '../../assets/logo.png';
export default function GoztepeLogo() {
    return (
        <Grid container flexWrap={"nowrap"} alignItems={"center"} sx={{ mb: 2 }} >
             <ListItemIcon
            sx={{
              minWidth: 0,  
                ml:1,
              justifyContent: 'center',
            }}
          >
         <img alt="göztepe" width="40px" height="40px" src={LogoPNG} />
          </ListItemIcon>
          <ListItemText sx={{ml:1}} variant="body1" primary={"Göztepe Spor Kulübü"} />
            
            
        </Grid>
    )
}
