import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Grid, TablePagination } from "@mui/material";

function createData(name, calories, fat, carbs, protein, x) {
  return { name, calories, fat, carbs, protein, x };
}

const rows = [
  createData("Göztepe Spor Kulübü	", 10, 10, 0, "+27", 27),
  createData("Karşıyaka Voleybol Kulübü", 10, 9, 1, "+20", 24),
  createData("Bornova Belediye Spor", 10, 7, 3, "+15", 21),
  createData("Konak Belediyesi	", 10, 8, 2, "+12", 18),
  createData("Bucaspor", 10, 5, 5, "+6", 10),
];

export default function LeagueaTable() {
  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <TableContainer sx={{ boxShadow: "unset" }} component={Paper}>
      <Table
        sx={{
          "& .MuiTableCell-root": {
            border: "unset",
            textAlign: "left",
          },
          "& thead .MuiTableCell-root": {
            fontWeight: 600,
            fontSize: 12,
            color: "#111927",
            letterSpacing: "0.5px",
            textTransform: "uppercase",
          },
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Sıra</TableCell>
            <TableCell sx={{ minWidth: "136px" }} align="right">
              Takım Adı
            </TableCell>
            <TableCell sx={{ minWidth: "136px" }} align="right">
              Oynanan Maç
            </TableCell>
            <TableCell sx={{ minWidth: "136px" }} align="right">
              Galibiyet
            </TableCell>
            <TableCell sx={{ minWidth: "136px" }} align="right">
              Mağlubiyet
            </TableCell>
            <TableCell sx={{ minWidth: "136px" }} align="right">
              Set Averajı
            </TableCell>
            <TableCell sx={{ minWidth: "136px" }} align="right">
              Puan
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell>
              <TableCell align="right">{row.x}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Grid
        container
        sx={{
          "& .MuiSelect-select-MuiInputBase-input:focus": {
            backgroundColor: "#FFF",
          },
        }}
        justifyContent={"flex-end"}
      >
        {" "}
        <TablePagination
          labelRowsPerPage="Satır sayısı:"
          sx={{ mt: 1 }}
          component="div"
          count={100}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </TableContainer>
  );
}
