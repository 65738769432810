import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";
export default function PaymentStatusChip({ value = "", ödemeDurumu }) {
  const theme = useTheme();

  let status = {
    Paid: {
      title: "TAMAMLANDI",
      sx: {
        bgcolor: theme.palette.success[100],
        color: theme.palette.success.main,
        borderRadius: "100px",
        p: "0px 16px",
      },
    },
    Unpaid: {
      title: ödemeDurumu,
      sx: {
        background: "#f044381f",
        color: "#B42318",
        borderRadius: "100px",
        p: "0px 16px",
      },
    },
    PartiallyPaid: {
      title: "ÖDEME EKSİK",
      sx: {
        background: "#ffe22159",
        color: "#d39b07",
        borderRadius: "100px",
        p: "0px 16px",
      },
    },
  };
  const selectedData = status[value];
  return (
    <Grid container>
      <Grid sx={selectedData?.sx} item>
        <Typography variant="caption">{selectedData?.title}</Typography>
      </Grid>
    </Grid>
  );
}
