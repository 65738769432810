import { Grid, Typography } from '@mui/material'
import React from 'react'

export default function AthleteStatisticsCard() {
    return (
        <Grid container justifyContent={"center"}>
            <Typography variant='subtitle1'>Yapım Aşamasında...</Typography>
        </Grid>
    )
}
