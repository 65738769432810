import * as React from "react";
import {
  Dialog,
  DialogTitle,
  Menu,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { IconButton } from "@mui/material";
import { Grid } from "@mui/material";
import { Badge } from "@mui/material";
import { IoNotificationsOutline } from "react-icons/io5";
import CloseIcon from "@mui/icons-material/Close";
import NotificationCard from "./NotificationCard";
import NotificationMobilCard from "./NotificationMobilCard";
import { useMutation, useQuery } from "@apollo/client";
import { LOGGED_IN_QUERY, NOTIFICATIONS_QUERY } from "../../queries";
import { NOTIFICATION_MUTATION } from "../../mutations";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NotificationMenu() {
  const { data: notifications } = useQuery(NOTIFICATIONS_QUERY);
  console.log("oya", notifications);

  const { data: loggedInUser } = useQuery(LOGGED_IN_QUERY);

  const [markMyNotificationsAsRead] = useMutation(NOTIFICATION_MUTATION, {
    refetchQueries: ["loggedInUser", "notifications"],
  });

  const isMobil = useMediaQuery("(max-width:600px)");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    markMyNotificationsAsRead();
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <Badge
          sx={{
            "& .MuiBadge-badge": {
              right: 6,
              top: 6,
            },
          }}
          color="primary"
          variant="dot"
          invisible={!loggedInUser?.loggedInUser?.unreadNotificationCount}
        >
          <IoNotificationsOutline fill="#6C737F" />
        </Badge>
      </IconButton>
      {!isMobil && (
        <Menu
          MenuListProps={{
            dense: true,
          }}
          PaperProps={{
            sx: {
              borderRadius: "20px",
              boxShadow:
                "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
              background: "#FFF",
              maxWidth: "538px",
              maxHeight: "349px",
              width: "100%",
            },
          }}
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Grid container>
            <Grid container sx={{ p: "32px 24px 16px 24px" }}>
              <Typography sx={{ fontSize: "18px" }} variant="captionTitle">
                Bildirimler
              </Typography>
            </Grid>
            {notifications?.getMyNotifications?.notifications?.map(
              (dat, index) => {
                return <NotificationCard dat={dat} index={index} />;
              }
            )}
          </Grid>
        </Menu>
      )}

      {/* mobil icin */}
      {isMobil && (
        <Dialog
          TransitionComponent={Transition}
          sx={{
            "& .MuiPaper-root": {
              borderRadius: 0,
            },
          }}
          fullScreen={isMobil}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle
            component={Grid}
            container
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant="h6">Bildirimler</Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon sx={{ color: "#6C737F", width: 20, height: 20 }} />
            </IconButton>
          </DialogTitle>
          <Grid container>
            {notifications?.getMyNotifications?.notifications?.map(
              (da, indexs) => {
                return <NotificationMobilCard da={da} indexs={indexs} />;
              }
            )}
          </Grid>
        </Dialog>
      )}
    </>
  );
}
