import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Grid, TablePagination } from "@mui/material";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData(
    "15/04/2024",
    "14:00",
    "Göztepe Spor Kulübü",
    "Altay Spor Kulübü	",
    "3-0 (25-15, 25-12, 25-10)"
  ),
  createData(
    "22/04/2024",
    "16:00",
    "Bucaspor",
    "Göztepe Spor Kulübü",
    "0-3 (10-25, 12-25, 14-25)"
  ),
  createData(
    "29/04/2024",
    "18:00",
    "Göztepe Spor Kulübü",
    "Karşıyaka Voleybol Kulübü",
    "3-1 (25-22, 23-25, 25-18, 25-20)"
  ),
  createData(
    "06/05/2024",
    "14:00",
    "Konak Belediyesi",
    "Göztepe Spor Kulübü",
    "2-3 (25-23, 19-25, 25-22, 18-25, 13-15)"
  ),
  createData(
    "13/05/2024",
    "16:00",
    "Göztepe Spor Kulübü",
    "Menemen Belediye Spor",
    "3-0 (25-8, 25-10, 25-12)"
  ),
];

export default function FixtureTable() {
  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <TableContainer sx={{ boxShadow: "unset" }} component={Paper}>
      <Table
        sx={{
          "& .MuiTableCell-root": {
            border: "unset",
            textAlign: "left",
          },
          "& thead .MuiTableCell-root": {
            fontWeight: 600,
            fontSize: 12,
            color: "#111927",
            letterSpacing: "0.5px",
            textTransform: "uppercase",
          },
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ minWidth: "55px" }}>Maç No</TableCell>
            <TableCell sx={{ minWidth: "100px" }} align="right">
              Tarih
            </TableCell>
            <TableCell sx={{ minWidth: "70px" }} align="right">
              Saat
            </TableCell>
            <TableCell sx={{ minWidth: "120px" }} align="right">
              Ev Sahİbİ Takım
            </TableCell>
            <TableCell sx={{ minWidth: "136px" }} align="right">
              Deplasman Takımı{" "}
            </TableCell>
            <TableCell sx={{ minWidth: "156px" }} align="right">
              Set Sonuç
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Grid
        container
        sx={{
          "& .MuiSelect-select-MuiInputBase-input:focus": {
            backgroundColor: "#FFF",
          },
        }}
        justifyContent={"flex-end"}
      >
        {" "}
        <TablePagination
          labelRowsPerPage="Satır sayısı:"
          sx={{ mt: 1 }}
          component="div"
          count={100}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </TableContainer>
  );
}
