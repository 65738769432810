import { useMutation } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { DELETE_GROUPS_MUTATION } from "../../../mutations";
import toast from "react-hot-toast";

export default function DeleteGroupsDialog({ opt }) {
  const [deleteGroupSegment] = useMutation(DELETE_GROUPS_MUTATION, {
    refetchQueries: ["allGroupSegments"],
  });

  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const handleClickDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };
  const isMobil = useMediaQuery("(max-width:600px)");
  const handleDelete = () => {
    deleteGroupSegment({
      variables: {
        deleteGroupSegmentId: opt.id,
      },
    })
      .then(() => {
        toast.success("İşlem başarılı.");
        handleDeleteClose();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
  return (
    <>
      <Grid
        container
        onClick={handleClickDeleteOpen}
        sx={{ "& .MuiButton-root": { minWidth: "0px", p: 0 } }}
      >
        <Button>
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
          >
            <path
              d="M4.16667 5.5L4.16667 12.1667C4.16667 13.7177 4.16667 14.4933 4.3706 15.1209C4.78276 16.3894 5.77727 17.3839 7.04576 17.7961C7.6734 18 8.44893 18 10 18V18C11.5511 18 12.3266 18 12.9542 17.7961C14.2227 17.3839 15.2172 16.3894 15.6294 15.1209C15.8333 14.4933 15.8333 13.7177 15.8333 12.1667L15.8333 5.5M4.16667 5.5H2.5M4.16667 5.5L7.5 5.5M15.8333 5.5H17.5M15.8333 5.5L12.5 5.5M7.5 5.5V5.5C7.5 4.72343 7.5 4.33515 7.62687 4.02886C7.79602 3.62048 8.12048 3.29602 8.52886 3.12687C8.83515 3 9.22343 3 10 3V3C10.7766 3 11.1649 3 11.4711 3.12687C11.8795 3.29602 12.204 3.62048 12.3731 4.02886C12.5 4.33515 12.5 4.72343 12.5 5.5V5.5M7.5 5.5L12.5 5.5M7.91667 8.41667L7.91667 14.25M12.0833 8.41667L12.0833 14.25"
              stroke="#6C737F"
              stroke-width="1.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Button>
      </Grid>
      <Dialog
        fullScreen={isMobil}
        open={deleteOpen}
        onClose={handleClickDeleteOpen}
        aria-labelledby="responsive-dialog-title"
        sx={{ "& .MuiDialog-paper": { width: "100%" }, width: "100%" }}
      >
        <DialogTitle sx={{ p: "24px 24px 8px 24px" }}>Grubu Sil</DialogTitle>
        <DialogContent sx={{ bgcolor: "#FFF", p: "8px 24px 0px 24px" }}>
          <DialogContentText>
            Grubu silmek istediğinize emin misiniz?
          </DialogContentText>
        </DialogContent>
        <Grid container justifyContent={"end"} sx={{ padding: "24px" }}>
          <DialogActions>
            <Button
              variant="contained"
              color="inherit"
              onClick={handleDeleteClose}
            >
              İptal
            </Button>
            <Button
              variant="contained"
              onClick={() => handleDelete()}
              color="error"
            >
              Sil
            </Button>
          </DialogActions>
        </Grid>
      </Dialog>
    </>
  );
}
