import { MenuItem } from "@mui/material";
import { Select } from "@mui/material";
import React, { useEffect } from "react";
import { ALL_PLAYER_POSITIONS_QUERY } from "../../../queries";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_ATHLETE_PLAYER_POSITION_MUTATION } from "../../../mutations";
import toast from "react-hot-toast";

export default function PlayerPositionSelect({ value = "", playerId }) {
  const { data: allPlayerPositions } = useQuery(ALL_PLAYER_POSITIONS_QUERY, {
    fetchPolicy: "cache-and-network",
  });
  const [updateAthletePlayerPosition] = useMutation(
    UPDATE_ATHLETE_PLAYER_POSITION_MUTATION
  );
  const [posiiton, setPosiiton] = React.useState(value);

  const handleChange = (event) => {
    const playerPositionId = event.target.value;

    updateAthletePlayerPosition({
      variables: {
        playerPositionId: playerPositionId,
        updateAthletePlayerPositionId: playerId,
      },
    })
      .then(() => {
        toast.success("Oyuncu pozisyonu başarıyla güncellendi.");
      })
      .catch((err) => {
        toast.error(err.message);
      });

    setPosiiton(playerPositionId);
  };
  useEffect(() => {
    setPosiiton(value);
  }, [value]);
  return (
    <>
      <Select fullWidth value={posiiton} onChange={handleChange}>
        {allPlayerPositions?.allPlayerPositions?.map((pos) => (
          <MenuItem key={pos?.id} value={pos?.id}>
            {pos?.name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
