import React, { useEffect } from "react";
import { settingsOption } from "../../utils";
import { Tab, Tabs } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";

export default function SettingsTabs() {
  const [value, setValue] = React.useState(0);
  const { pathname } = useLocation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (settingsOption.find((opt) => opt.url === pathname)) {
      if (pathname === "/ayarlar") {
        setValue(0);
      } else if (pathname === "/ligler") {
        setValue(1);
      } else if (pathname === "/gruplar") {
        setValue(2);
      }
    }
  }, [pathname]);
  return (
    <Tabs
      sx={{
        "& .MuiTabs-scroller": { height: "40px" },
        "& .MuiTabs-scrollButtons": {
          height: 40,
          width: {
            xs: "20px",
            sm: "20px",
            md: "40px",
          },
        },
      }}
      variant="scrollable"
      scrollButtons="auto"
      value={value}
      onChange={handleChange}
      aria-label="basic tabs example"
    >
      {settingsOption.map((opt, index) => (
        <Tab
          component={NavLink}
          to={opt.url}
          sx={{
            textTransform: "unset",
            mr: 3,
            p: 0,
            minHeight: 40,
            minWidth: "inherit",
          }}
          key={index}
          label={opt.title}
        />
      ))}
    </Tabs>
  );
}
