import React from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { UPDATE_PASSWORD_MUTATION } from "../../../mutations";

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required("Mevcut Şifre gereklidir."),
  newPassword: Yup.string()
    .min(6, "Yeni Şifre en az 6 karakter olmalıdır.")
    .required("Yeni Şifre gereklidir."),
  newPasswordConfirmation: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Yeni şifreler eşleşmiyor.")
    .required("Yeni şifreyi tekrar giriniz."),
});

export default function SettingsPassword() {
  const [changePassword] = useMutation(UPDATE_PASSWORD_MUTATION);

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        await changePassword({
          variables: {
            currentPassword: values.currentPassword,
            newPassword: values.newPassword,
            newPasswordConfirmation: values.newPasswordConfirmation,
          },
        });
        toast.success(`Şifre başarıyla güncellendi.`);
        resetForm();
      } catch (err) {
        toast.error(err.message);
      }
    },
  });

  const { handleSubmit, errors, touched } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <Grid container sx={{ pb: "24px" }}>
        <Grid
          container
          sx={{
            maxHeight: "625px",
            height: "100%",
            p: "32px 24px",
            borderRadius: "20px",
            boxShadow:
              "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
            bgcolor: 'white',
          }}
        >
          <Grid item xs={12} md={2} sx={{ pb: { xs: "12px", md: "0px" } }}>
            <Typography
              sx={{
                fontWeight: 600,
              }}
              variant="subtitle1"
            >
              Şifreyi Yenile
            </Typography>
          </Grid>

          <Grid item xs={12} md={10}>
            <Grid container sx={{ minHeight: "74px" }}>
              <TextField
                id="currentPassword"
                name="currentPassword"
                type="password"
                placeholder="Eski Şifre"
                fullWidth
                value={formik.values.currentPassword}
                error={touched.currentPassword && !!errors.currentPassword}
                helperText={
                  touched.currentPassword ? errors.currentPassword : ""
                }
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid container sx={{ minHeight: "74px" }}>
              <TextField
                id="newPassword"
                name="newPassword"
                type="password"
                placeholder="Yeni Şifre"
                fullWidth
                value={formik.values.newPassword}
                error={touched.newPassword && !!errors.newPassword}
                helperText={touched.newPassword ? errors.newPassword : ""}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid container sx={{ minHeight: "74px" }}>
              <TextField
                type="password"
                id="newPasswordConfirmation"
                name="newPasswordConfirmation"
                placeholder="Yeni Şifreyi Tekrar Gir"
                fullWidth
                value={formik.values.newPasswordConfirmation}
                error={
                  touched.newPasswordConfirmation &&
                  !!errors.newPasswordConfirmation
                }
                helperText={
                  touched.newPasswordConfirmation
                    ? errors.newPasswordConfirmation
                    : ""
                }
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid container justifyContent="space-between">
              <Button
                type="submit"
                sx={{
                  width: { xs: "100%", sm: "unset" },
                }}
                variant="contained"
              >
                Şifreyi Yenile
              </Button>
              {/* <Button sx={{ width: { xs: "100%", sm: "unset" } }} variant="text">
              Şifreni mi unuttun?
            </Button> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
