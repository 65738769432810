import { Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import LoginHeader from "../loginPage/LoginHeader";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object({
  passwordConfirmation: Yup.string()
    .required("Zorunlu alan.")
    .min(8, "Parolanız 8 karakterden az olmamalı.")
    .oneOf([Yup.ref("password")], "Parolalar eşleşmiyor"),

  password: Yup.string()
    .required("Zorunlu alan.")
    .min(8, "Parolanız 8 karakterden az olmamalı."),
});

export default function ResetPassword() {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    React.useState(false);

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: {
      passwordConfirmation: "",
      password: "",
    },
    validationSchema,
    validateOnMount: false,

    onSubmit: (values) => {},
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordConfirmationVisibility = () => {
    setShowPasswordConfirmation(!showPasswordConfirmation);
  };

  return (
    <>
      <LoginHeader />
      <Grid
        sx={{ height: "calc(100vh - 60px)" }}
        justifyContent={"center"}
        alignItems={"center"}
        container
      >
        <Grid
          container
          sx={{
            minHeight: "327px",
            maxWidth: "444px",
            width: "100%",
            borderRadius: "20px",
            bgcolor: "#FFF",
            boxShadow:
              "0px 0px 0px 0.5px #00000008, 0px 5px 22px 0px #0000000a",
          }}
        >
          <Grid
            alignItems={"center"}
            container
            sx={{ p: "32px 24px 16px 24px" }}
          >
            <Typography variant="captionTitle" sx={{ fontSize: "18px" }}>
              Şifreyi Yenile
            </Typography>
          </Grid>

          <Grid container sx={{ p: "16px 24px 32px 24px" }}>
            <form onSubmit={handleSubmit}>
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Grid
                        alignItems={"center"}
                        container
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <VisibilityOff /> : <VisibilityIcon />}
                      </Grid>
                    </InputAdornment>
                  ),
                }}
                sx={{ minHeight: 76 }}
                id="password"
                label="Yeni Şifre"
                variant="filled"
                type={showPassword ? "text" : "password"}
                fullWidth
                name="password"
                value={values.password}
                error={touched.password && !!errors.password}
                helperText={touched.password ? errors.password : ""}
                onChange={handleChange}
                onBlur={handleChange}
              />
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Grid
                        alignItems={"center"}
                        container
                        onClick={togglePasswordConfirmationVisibility}
                      >
                        {showPasswordConfirmation ? (
                          <VisibilityOff />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </Grid>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  pb: "16px",
                  minHeight: 76,
                }}
                id="passwordConfirmation"
                label="Yeni Şifre Tekrar"
                variant="filled"
                type={showPasswordConfirmation ? "text" : "password"}
                fullWidth
                name="passwordConfirmation"
                value={values.passwordConfirmation}
                error={
                  touched.passwordConfirmation && !!errors.passwordConfirmation
                }
                helperText={
                  touched.passwordConfirmation
                    ? errors.passwordConfirmation
                    : ""
                }
                onChange={handleChange}
                onBlur={handleChange}
              />
              <Grid container sx={{ pb: "16px" }}>
                <Button
                  type="submit"
                  // disabled={loading}
                  fullWidth
                  variant="contained"
                >
                  Şifreyi Yenile
                </Button>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
