import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
export default function MatchResultsDetailTable() {
  return (
    <TableContainer
      sx={{
        boxShadow: "0px 0px 0px 0.5px #00000008, 0px 5px 22px 0px #0000000a",
        borderRadius: "20px",
        mb: "24px",
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">
              <Typography variant="TableTitle">Skor Tablosu</Typography>
            </TableCell>

            <TableCell align="center">
              <Typography variant="TableTitle">1.Set</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="TableTitle">2.Set</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="TableTitle">3.Set</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="left">
              <Typography variant="body1">Göztepe Spor Kulübü</Typography>
            </TableCell>

            <TableCell align="center">
              <Typography sx={{ color: "#111927" }} variant="h6">
                25
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography sx={{ color: "#111927" }} variant="h6">
                25
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography sx={{ color: "#111927" }} variant="h6">
                25
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">
              <Typography variant="body1">Altay Spor Kulübü</Typography>
            </TableCell>

            <TableCell align="center">
              <Typography sx={{ color: "#11192761" }} variant="h6">
                12
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography sx={{ color: "#11192761" }} variant="h6">
                12
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography sx={{ color: "#11192761" }} variant="h6">
                10
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
