import { Grid, Typography } from "@mui/material";
import React from "react";
import SectionTitle from "../components/title/SectionTitle";
import SettingsNotifications from "./settings/GeneralSettings/SettingsNotifications";
import SettingsPassword from "./settings/GeneralSettings/SettingsPassword";
import SettingsTabs from "./settings/SettingsTabs";

export default function Settings() {
  return (
    <>
      <SectionTitle
        title="Ayarlar"
        description="Sistem ayarlarınızı buradan yapabilirsiniz."
      />
      <Grid container sx={{ px: "24px", py: "16px", pt: 0 }}>


        <Grid container sx={{ mt: 3, }}>
          <Grid
            container
            sx={{
              p: "8px 24px 8px",
              borderRadius: "20px",
              boxShadow:
                "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
              bgcolor: 'white',
              mb: "24px"
            }}
          >
            <SettingsTabs />
          </Grid>
          <Grid container sx={{ pb: "24px", }}>
            <Grid
              container
              sx={{
                p: "32px 24px",
                borderRadius: "20px",
                boxShadow:
                  "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                bgcolor: 'white',
              }}
            >
              <Grid item xs={6} md={2}>
                <Typography
                  sx={{
                    fontWeight: 600,
                  }}
                  variant="subtitle1"
                >
                  Dil
                </Typography>
              </Grid>
              <Grid item xs={6} md={10}>
                <Typography sx={{ fontWeight: 500 }} variant="body1">
                  Türkçe
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <SettingsNotifications />
          <SettingsPassword />
        </Grid>
      </Grid>
    </>
  );
}
