import React from "react";
import {
  Grid,
  Typography,
  IconButton,
  useMediaQuery,
  Select,
  MenuItem,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";

export default function CalendarNoteTakingDialog({
  data,
  setData,
  setText,
  open,
  setOpen,
  events,
  setEvents,
}) {
  const handledialogClose = () => {
    setData(null);
    setOpen(false);
    setText("");
  };
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleSetData = (myTitle, myDate) => {
    setEvents([
      ...events,
      { title: "BURASI GRUP ADI OLACAK", start: data?.start, end: data?.end },
    ]);
  };
  const isMobil = useMediaQuery("(max-width:600px)");
  const handleSendButton = () => {
    handleSetData();
    handledialogClose();
  };
  return (
    <Dialog
      fullScreen={isMobil}
      open={open}
      onClose={handledialogClose}
      PaperProps={{
        sx: { borderRadius: { xs: 0, sm: "20px" } },
      }}
    >
      <DialogTitle
        component={Grid}
        container
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h6">Antrenman Planları Ekle</Typography>
        <IconButton
          aria-label="close"
          onClick={handledialogClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ color: "#6C737F", width: 20, height: 20 }} />
        </IconButton>
      </DialogTitle>
      <Grid container>
        <DialogContent>
          <Grid spacing={2} container>
            <Grid item xs={12}>
              <Grid
                container
                sx={{
                  p: { xs: 2, sm: 3 },
                  borderRadius: "20px",
                  padding: "24px",
                  alignSelf: "stretch",
                  gap: "16px",
                  bgcolor: "#fff",
                  boxShadow:
                    "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                }}
              >
                <Grid container>
                  <Select
                    id="demo-simple-select"
                    value={age}
                    label="Grup"
                    fullWidth
                    onChange={handleChange}
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </Grid>
                <Grid container>
                  <Select
                    id="demo-simple-select"
                    value={age}
                    fullWidth
                    label="Antrenman Salonu"
                    onChange={handleChange}
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </Grid>
                <Grid container>
                  <Select
                    label="Haftalık pazar Günü"
                    value={age}
                    fullWidth
                    onChange={handleChange}
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Select
                      label="Başlangıç Saati"
                      id="demo-simple-select"
                      value={age}
                      fullWidth
                      onChange={handleChange}
                    >
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Select
                      label="Bitiş Saati"
                      value={age}
                      fullWidth
                      onChange={handleChange}
                    >
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
                <Grid container sx={{ mt: 6 }} justifyContent={"flex-end"}>
                  <Button
                    sx={{ mr: 2 }}
                    variant="contained"
                    color="secondary"
                    onClick={handledialogClose}
                  >
                    İptal
                  </Button>
                  <Button variant="contained" onClick={handleSendButton}>
                    Antrenman Planı Ekle
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Grid>
    </Dialog>
  );
}
