import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_GROUPS_MUTATION } from "../../../mutations";
import toast from "react-hot-toast";
import { ALL_LEAGUES_QUERY } from "../../../queries";

export default function CreateLeaguesDialog({ handleClose, isMobil, open }) {
  const [text, setText] = useState("");
  const [selectedLeagues, setSelectedLeagues] = useState([]);

  const { data: allLeagues } = useQuery(ALL_LEAGUES_QUERY, {
    skip: !open,
    fetchPolicy: "cache-and-network",
  });

  const [createGroupSegment] = useMutation(CREATE_GROUPS_MUTATION, {
    refetchQueries: ["allGroupSegments"],
  });
  useEffect(() => {
    setText("");
    setSelectedLeagues([]);
  }, [open]);
  const handleCreate = () => {
    createGroupSegment({
      variables: {
        name: text,
        groupLeagueIds: selectedLeagues.map((s) => s?.id),
      },
    })
      .then(() => {
        toast.success("İşlem başarılı.");
        handleClose();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
  return (
    <Dialog
      fullScreen={isMobil}
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: { borderRadius: { xs: 0, sm: "20px" } },
        component: "form",
        onSubmit: (event) => {
          event.preventDefault();

          handleClose();
        },
      }}
    >
      <DialogTitle
        component={Grid}
        container
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h6">Grup Ekle</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ color: "#6C737F", width: 20, height: 20 }} />
        </IconButton>
      </DialogTitle>
      <Grid container>
        <DialogContent>
          <Grid spacing={2} container>
            <Grid item xs={12}>
              <Grid
                container
                sx={{
                  p: { xs: 2, sm: 3 },
                  borderRadius: "20px",
                  padding: "24px",
                  alignSelf: "stretch",
                  gap: "16px",
                  bgcolor: "#fff",
                  boxShadow:
                    "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                }}
              >
                <Grid container>
                  <Autocomplete
                    sx={{
                      "&:hover .MuiAutocomplete-input": {
                        minWidth: 30,
                      },
                    }}
                    multiple
                    value={selectedLeagues}
                    onChange={(event, newValue) => {
                      setSelectedLeagues(newValue);
                    }}
                    fullWidth
                    id="multiple-limit-tags"
                    options={allLeagues?.allLeagues || []}
                    getOptionLabel={(option) => option.leagueName}
                    renderInput={(params) => (
                      <TextField {...params} label="Lig seçin" placeholder="" />
                    )}
                  />
                </Grid>
                <Grid container>
                  <TextField
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    type="text"
                    label="Grup Adı"
                    fullWidth
                  />
                </Grid>
                <Grid container sx={{ mt: 3 }} justifyContent={"flex-end"}>
                  <Button
                    sx={{ mr: 2 }}
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                  >
                    İptal
                  </Button>
                  <Button
                    disabled={!text || !selectedLeagues?.length > 0}
                    variant="contained"
                    onClick={() => handleCreate()}
                    type="submit"
                  >
                    Grup Ekle
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Grid>
    </Dialog>
  );
}
