import { Button, ButtonGroup, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { athleteTabsOptions } from "../../../utils";
import AthleteStatisticsCard from "./AthleteStatisticsCard";
import PaymentInformationCard from "./PaymentInformationCard";
import PersonalMeasureDetails from "./PersonalMeasureDetails";
import SporHistory from "./SporHistory";

export default function AthleteDetailTabs() {
  const [selected, setSelected] = useState(1);
  const location = useLocation();
  const onlyPayment = location?.pathname?.includes("aidat");

  useEffect(() => {
    if (location?.pathname?.includes("aidat")) {
      setSelected(4);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelected = (value) => {
    setSelected(value);
  };
  return (
    <Grid container>
      <Grid container sx={{ my: 2 }}>
        {!onlyPayment && (
          <ButtonGroup
            sx={{
              "& .MuiButtonGroup-grouped": {
                minWidth: { xs: "fit-content" },
              },
              overflow: {
                xs: "scroll",
                sm: "auto",
              },
            }}
            size="small"
            variant="contained"
            color="secondary"
            fullWidth
            aria-label="Basic button group"
          >
            {athleteTabsOptions.map((opt, index) => (
              <Button
                onClick={() => handleSelected(opt.id)}
                startIcon={opt.icon}
                sx={{ bgcolor: selected === opt.id ? "#F8F9FA" : "white" }}
                key={index}
              >
                <Typography fontWeight={600} variant="body1">
                  {opt.title}
                </Typography>
              </Button>
            ))}
          </ButtonGroup>
        )}
      </Grid>
      <Grid
        container
        sx={{
          p: 3,
          bgcolor: "#fff",
          borderRadius: 5,
          boxShadow:
            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
        }}
      >
        {selected === 1 && <SporHistory />}

        {selected === 2 && <PersonalMeasureDetails />}
        {selected === 3 && <AthleteStatisticsCard />}
        {selected === 4 && <PaymentInformationCard />}
      </Grid>
    </Grid>
  );
}
