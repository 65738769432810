import { Tab } from "@mui/material";
import { Tabs } from "@mui/material";
import { Grid } from "@mui/material";
import React from "react";
import { homeTeamOptions } from "../../utils";
import TableContainer from "../container/TableContainer.js";
import FixtureTable from "../table/FixtureTable";
import LeagueaTable from "../table/LeagueTable";

export default function HomeTeamCard() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container sx={{ p: 3 }}>
      <Tabs
        sx={{}}
        variant="scrollable"
        scrollButtons="auto"
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        {homeTeamOptions.map((opt, index) => (
          <Tab
            sx={{
              textTransform: "unset",
              mr: 3,
              p: 0,
              minHeight: 40,
              minWidth: "inherit",
            }}
            key={index}
            label={opt.title}
          />
        ))}
      </Tabs>

      <TableContainer title="Takım Lig Durumu" table={<LeagueaTable />} />
      <Grid container sx={{ mt: 1 }}>
        <TableContainer title="Fikstür" table={<FixtureTable />} />
      </Grid>
    </Grid>
  );
}
