import {
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_ATHLETE_MUTATION } from "../../../mutations";
import { useSearchParams } from "react-router-dom";
import { MuiTelInput } from "mui-tel-input";

import moment from "moment";

import {
  ALL_GROUPS_QUERY,
  ALL_PLAYER_POSITIONS_QUERY,
  GET_ATHLETE_DETAIL_QUERY,
} from "../../../queries";
const validationSchema = Yup.object({
  firstName: Yup.string().required("Bu alan boş bırakılamaz."),
  lastName: Yup.string().required("Bu alan boş bırakılamaz."),

  dateOfBirth: Yup.string().required("Bu alan boş bırakılamaz."),
  phone: Yup.string().required("Bu alan boş bırakılamaz."),
  groupLeagueId: Yup.string().required("Bu alan boş bırakılamaz."),
  // height: Yup.number().required("Bu alan boş bırakılamaz."),
  // jumpHeight: Yup.number().required("Bu alan boş bırakılamaz."),
  // weight: Yup.number().required("Bu alan boş bırakılamaz."),
  // exitSpeed: Yup.number().required("Bu alan boş bırakılamaz."),
});

export default function SporHistory() {
  const [editScreen, setEditScreen] = React.useState(false);
  const [updateAthlete] = useMutation(UPDATE_ATHLETE_MUTATION);
  let [searchParams] = useSearchParams();
  let playerId = searchParams.get("sporcu");
  const handleEditScreen = () => {
    setEditScreen(!editScreen);
    formik.resetForm();
  };
  const { data: allPlayerPositions } = useQuery(ALL_PLAYER_POSITIONS_QUERY);

  const { data: allGroupLeagueJunctions } = useQuery(ALL_GROUPS_QUERY);

  const { data: getAthlete } = useQuery(GET_ATHLETE_DETAIL_QUERY, {
    variables: {
      getAthleteId: playerId,
    },
    skip: !playerId,
    fetchPolicy: "cache-and-network",
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: getAthlete?.getAthlete?.firstName,
      lastName: getAthlete?.getAthlete?.lastName,
      email: getAthlete?.getAthlete?.email,
      dateOfBirth: getAthlete?.getAthlete?.dateOfBirth,
      phone: getAthlete?.getAthlete?.phone,
      playerPositionId: getAthlete?.getAthlete?.playerPositionId,
      groupLeagueId: getAthlete?.getAthlete?.groupLeagueId,
      height: getAthlete?.getAthlete?.height,
      jumpHeight: getAthlete?.getAthlete?.jumpHeight,
      weight: getAthlete?.getAthlete?.weight,
      exitSpeed: getAthlete?.getAthlete?.exitSpeed,
      paymentAmount: getAthlete?.getAthlete?.specialPaymentAmount,
    },
    validationSchema,
    onSubmit: async (values) => {
      updateAthlete({
        variables: {
          data: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone,
            groupLeagueId: values.groupLeagueId,
            dateOfBirth: values.dateOfBirth,
            playerPositionId: values.playerPositionId,
            jumpHeight:
              values.jumpHeight === "-" ? "0" : values.jumpHeight.toString(),
            exitSpeed:
              values.exitSpeed === "-" ? "0" : values.exitSpeed.toString(),
            height: values.height === "-" ? "0" : values.height.toString(),
            weight: values.weight === "-" ? "0" : values.weight.toString(),
            specialPaymentAmount: values?.paymentAmount || 0,
          },
          updateAthleteId: playerId,
        },
      })
        .then((data) => {
          toast.success(`Sporcu durumu başarıyla güncellendi `);
        })
        .catch((err) => {
          toast.error(err.message);
        });
      handleEditScreen();
    },
  });

  return (
    <Grid container sx={{ px: { sm: "24px", xs: "5px" }, py: "12px" }}>
      <Grid container>
        <form onSubmit={formik.handleSubmit}>
          {editScreen ? (
            <Grid container>
              <Grid
                container
                spacing={2}
                sx={{ py: "12px" }}
                alignItems={"center"}
              >
                <Grid item md={2} xs={12}>
                  <Typography fontWeight={500} variant="body1">
                    Ad
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    id="firstName"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <Typography fontWeight={500} variant="body1">
                    Soyad
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    id="lastName"
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{ pb: "12px" }}
                alignItems={"center"}
              >
                {" "}
                <Grid item md={2} xs={12}>
                  <Typography fontWeight={500} variant="body1">
                    E-posta
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  {" "}
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    id="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <Typography fontWeight={500} variant="body1">
                    Doğum Tarihi
                  </Typography>
                </Grid>{" "}
                <Grid
                  item
                  md={4}
                  xs={12}
                  sx={{
                    "& .MuiFormControl-root": {
                      width: "100% !important",
                    },
                    "& .MuiOutlinedInput-root": {
                      maxHeight: "37.13px",
                      height: "100%",
                    },
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      id="dateOfBirth"
                      name="dateOfBirth"
                      value={moment(formik.values.dateOfBirth)}
                      onChange={(date) =>
                        formik.setFieldValue("dateOfBirth", date)
                      }
                      placeholderText="Doğum Tarihi*"
                      dateFormat="DD/MM/YYYY"
                      error={
                        formik.touched.dateOfBirth &&
                        Boolean(formik.errors.dateOfBirth)
                      }
                      helperText={
                        formik.touched.dateOfBirth && formik.errors.dateOfBirth
                      }
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{ pb: "12px" }}
                alignItems={"center"}
              >
                <Grid item md={2} xs={12}>
                  <Typography fontWeight={500} variant="body1">
                    Telefon
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      maxHeight: "37px",
                      height: "100%",
                      paddingLeft: "5px",
                    },
                  }}
                >
                  <MuiTelInput
                    forceCallingCode={true}
                    international
                    defaultCountry="TR"
                    fullWidth
                    id="phone"
                    name="phone"
                    inputProps={{
                      placeholder: "Telefon numarasını girin",
                    }}
                    value={formik.values.phone}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                    onChange={(value) =>
                      formik.handleChange({
                        target: {
                          name: "phone",
                          value,
                        },
                      })
                    }
                  />
                </Grid>

                <Grid item md={2} xs={12}>
                  <Typography
                    sx={{ minWidth: "130px" }}
                    fontWeight={500}
                    variant="body1"
                  >
                    Oyuncu Pozisyonu
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Select
                    size="small"
                    sx={{
                      maxHeight: "37px",
                      borderRadius: "12px",
                    }}
                    fullWidth
                    variant="outlined"
                    id="playerPositionId"
                    name="playerPositionId"
                    value={formik.values.playerPositionId}
                    onChange={formik.handleChange}
                  >
                    {allPlayerPositions?.allPlayerPositions?.map((pos) => (
                      <MenuItem key={pos?.id} value={pos?.id}>
                        {pos?.name}
                      </MenuItem>
                    ))}
                  </Select>

                  {formik.touched.playerPositionId &&
                    formik.errors.playerPositionId && (
                      <Grid
                        item
                        sx={{
                          maxHeight: "37px",
                          borderRadius: "12px",
                          color: "#d32f2f",
                          fontFeatureSettings: "'clig' off, 'liga' off",
                          fontFamily: "Inter",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "20px",
                          letterSpacing: "0.4px",
                          padding: "0px 14px",
                        }}
                      >
                        {formik.errors.playerPositionId}
                      </Grid>
                    )}
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                sx={{ pb: "12px" }}
                alignItems={"center"}
              >
                <Grid item md={2} xs={12}>
                  <Typography fontWeight={500} variant="body1">
                    Grup
                  </Typography>
                </Grid>{" "}
                <Grid item md={4} xs={12}>
                  <Select
                    sx={{
                      maxHeight: "37px",
                      borderRadius: "12px",
                    }}
                    labelId="demo-simple-select-label"
                    size="small"
                    fullWidth
                    variant="outlined"
                    id="groupLeagueId"
                    name="groupLeagueId"
                    value={formik.values.groupLeagueId}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.groupLeagueId &&
                      Boolean(formik.errors.groupLeagueId)
                    }
                    helperText={
                      formik.touched.groupLeagueId &&
                      formik.errors.groupLeagueId
                    }
                  >
                    {allGroupLeagueJunctions?.allGroupLeagueJunctions?.map(
                      (pos) => (
                        <MenuItem key={pos?.id} value={pos?.id}>
                          {pos?.groupLeagueName}
                        </MenuItem>
                      )
                    )}
                  </Select>

                  {formik.touched.groupLeagueId &&
                    formik.errors.groupLeagueId && (
                      <Grid
                        item
                        sx={{
                          maxHeight: "37px",
                          borderRadius: "12px",
                          color: "#d32f2f",
                          fontFeatureSettings: "'clig' off, 'liga' off",
                          fontFamily: "Inter",
                          fontSize: "10px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "20px",
                          letterSpacing: "0.4px",
                          padding: "0px 14px",
                        }}
                      >
                        {formik.errors.groupLeagueId}
                      </Grid>
                    )}
                </Grid>
                <Grid item md={2} xs={12}>
                  <Typography fontWeight={500} variant="body1">
                    Boy
                  </Typography>
                </Grid>{" "}
                <Grid item md={4} xs={12}>
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    id="height"
                    name="height"
                    type="number"
                    value={formik.values.height}
                    onChange={formik.handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">cm</InputAdornment>
                      ),
                    }}
                    error={
                      formik.touched.height && Boolean(formik.errors.height)
                    }
                    helperText={formik.touched.height && formik.errors.height}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{ pb: "12px" }}
                alignItems={"center"}
              >
                <Grid item md={2} xs={12}>
                  <Typography fontWeight={500} variant="body1">
                    Sıçrama Yüksekliği
                  </Typography>
                </Grid>{" "}
                <Grid item md={4} xs={12}>
                  {" "}
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    id="jumpHeight"
                    name="jumpHeight"
                    type="number"
                    value={formik.values.jumpHeight}
                    onChange={formik.handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">cm</InputAdornment>
                      ),
                    }}
                    error={
                      formik.touched.jumpHeight &&
                      Boolean(formik.errors.jumpHeight)
                    }
                    helperText={
                      formik.touched.jumpHeight && formik.errors.jumpHeight
                    }
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <Typography fontWeight={500} variant="body1">
                    Kilo
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    id="weight"
                    name="weight"
                    type="number"
                    value={formik.values.weight}
                    onChange={formik.handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">kg</InputAdornment>
                      ),
                    }}
                    error={
                      formik.touched.weight && Boolean(formik.errors.weight)
                    }
                    helperText={formik.touched.weight && formik.errors.weight}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{ pb: "12px" }}
                alignItems={"center"}
              >
                <Grid item md={2} xs={12}>
                  <Typography fontWeight={500} variant="body1">
                    Çıkış Hızı
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  {" "}
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    id="exitSpeed"
                    name="exitSpeed"
                    type="number"
                    value={formik.values.exitSpeed}
                    onChange={formik.handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">m/s</InputAdornment>
                      ),
                    }}
                    error={
                      formik.touched.exitSpeed &&
                      Boolean(formik.errors.exitSpeed)
                    }
                    helperText={
                      formik.touched.exitSpeed && formik.errors.exitSpeed
                    }
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <Typography fontWeight={500} variant="body1">
                    Aidat
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    id="paymentAmount"
                    name="paymentAmount"
                    type="number"
                    value={formik.values.paymentAmount}
                    onChange={formik.handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">TL</InputAdornment>
                      ),
                    }}
                    error={
                      formik.touched.exitSpeed &&
                      Boolean(formik.errors.exitSpeed)
                    }
                    helperText={
                      formik.touched.exitSpeed && formik.errors.exitSpeed
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{ pb: "12px" }}
                justifyContent={"end"}
              >
                {" "}
                <Grid item sx={{ pr: "10px" }}>
                  <Button
                    onClick={handleEditScreen}
                    variant="contained"
                    color="secondary"
                    sx={{ minWidth: 76 }}
                  >
                    İptal
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ minWidth: 96 }}
                  >
                    Kaydet
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid container sx={{ pt: "12px", pb: "12px" }}>
                <Grid
                  item
                  md={2}
                  xs={6}
                  sm={2}
                  sx={{ pb: { xs: "12px", sm: "0px" } }}
                >
                  <Typography fontWeight={500} variant="body1">
                    Ad
                  </Typography>
                </Grid>
                <Grid item md={4} sm={5} xs={6}>
                  <Typography variant="body1">
                    {formik.values.firstName}
                  </Typography>
                </Grid>
                <Grid item md={2} sm={4} xs={6}>
                  <Typography fontWeight={500} variant="body1">
                    Soyad
                  </Typography>
                </Grid>
                <Grid item md={4} sm={1} xs={6}>
                  <Typography variant="body1">
                    {formik.values.lastName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ pb: "12px" }}>
                <Grid
                  item
                  sx={{ pb: { xs: "12px", sm: "0px" } }}
                  sm={2}
                  md={2}
                  xs={6}
                >
                  <Typography fontWeight={500} variant="body1">
                    E-posta
                  </Typography>
                </Grid>
                <Grid item md={4} sm={5} xs={6}>
                  <Typography
                    sx={{
                      "-webkit-box-orient": "vertical",
                      "-webkit-line-clamp": "1",
                      display: "-webkit-box",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      wordBreak: "break-word",
                    }}
                    variant="body1"
                  >
                    {formik.values.email}
                  </Typography>
                </Grid>
                <Grid item md={2} sm={4} xs={6}>
                  <Typography
                    sx={{ pr: 1, minWidth: "140px" }}
                    fontWeight={500}
                    variant="body1"
                  >
                    Doğum Tarihi
                  </Typography>
                </Grid>{" "}
                <Grid item md={4} sm={1} xs={6}>
                  {" "}
                  <Typography variant="body1">
                    {formik.values.dateOfBirth
                      ? moment(formik.values.dateOfBirth).format("DD/MM/YYYY")
                      : ""}{" "}
                  </Typography>
                </Grid>{" "}
              </Grid>
              <Grid container sx={{ pb: "12px" }}>
                <Grid item md={2} sm={2} xs={6}>
                  <Typography
                    sx={{ pb: { xs: "12px", sm: "0px" } }}
                    fontWeight={500}
                    variant="body1"
                  >
                    Telefon
                  </Typography>
                </Grid>
                <Grid item md={4} sm={5} xs={6}>
                  {" "}
                  <Typography
                    sx={{
                      wordBreak: "break-word",
                      "-webkit-box-orient": "vertical",
                      "-webkit-line-clamp": "1",
                      display: "-webkit-box",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    variant="body1"
                  >
                    {formik.values.phone}
                  </Typography>
                </Grid>
                <Grid item md={2} sm={4} xs={6}>
                  <Typography fontWeight={500} variant="body1">
                    Oyuncu Pozisyonu
                  </Typography>
                </Grid>
                <Grid item md={4} sm={1} xs={6}>
                  <Typography variant="body1">
                    {
                      allPlayerPositions?.allPlayerPositions?.find(
                        (pos) => pos.id === formik.values.playerPositionId
                      )?.name
                    }
                  </Typography>
                </Grid>{" "}
              </Grid>
              <Grid container sx={{ pb: "12px" }}>
                {" "}
                <Grid
                  sx={{ pb: { xs: "12px", sm: "0px" } }}
                  sm={2}
                  item
                  md={2}
                  xs={6}
                >
                  <Typography fontWeight={500} variant="body1">
                    Grup
                  </Typography>
                </Grid>{" "}
                <Grid item sm={5} md={4} xs={6}>
                  {" "}
                  <Typography
                    sx={{
                      "-webkit-box-orient": "vertical",
                      "-webkit-line-clamp": "1",
                      display: "-webkit-box",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      wordBreak: "break-word",
                    }}
                    variant="body1"
                  >
                    {
                      allGroupLeagueJunctions?.allGroupLeagueJunctions?.find(
                        (seg) => seg.id === formik.values.groupLeagueId
                      )?.groupLeagueName
                    }
                  </Typography>
                </Grid>
                <Grid item md={2} sm={4} xs={6}>
                  <Typography fontWeight={500} variant="body1">
                    Boy
                  </Typography>
                </Grid>{" "}
                <Grid item md={4} sm={1} xs={6}>
                  {" "}
                  <Typography variant="body1">
                    {formik.values.height || "-"} cm
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ pb: "12px" }}>
                {" "}
                <Grid item md={2} sm={2} xs={6}>
                  <Typography
                    sx={{ pb: { xs: "12px", sm: "0px" } }}
                    fontWeight={500}
                    variant="body1"
                  >
                    Sıçrama Yüksekliği
                  </Typography>
                </Grid>{" "}
                <Grid item md={4} sm={5} xs={6}>
                  {" "}
                  <Typography variant="body1">
                    {formik.values.jumpHeight || "-"} cm
                  </Typography>
                </Grid>
                <Grid item md={2} sm={4} xs={6}>
                  <Typography fontWeight={500} variant="body1">
                    Kilo
                  </Typography>
                </Grid>{" "}
                <Grid item md={4} sm={1} xs={6}>
                  <Typography variant="body1">
                    {formik.values.weight || "-"} kg
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ pb: "12px" }}>
                <Grid item sm={2} md={2} xs={6}>
                  <Typography
                    sx={{ pb: { xs: "12px", sm: "0px" } }}
                    fontWeight={500}
                    variant="body1"
                  >
                    Çıkış Hızı
                  </Typography>
                </Grid>
                <Grid item md={4} sm={5} xs={6}>
                  <Typography variant="body1">
                    {formik.values.exitSpeed || "-"} m/s
                  </Typography>
                </Grid>
                <Grid item md={2} sm={4} xs={6}>
                  <Typography fontWeight={500} variant="body1">
                    Aidat
                  </Typography>
                </Grid>
                <Grid item md={4} sm={1} xs={6}>
                  <Typography sx={{ minWidth: "150px" }} variant="body1">
                    {formik.values.paymentAmount || "-"} TL
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent={"end"}>
                <Button
                  onClick={handleEditScreen}
                  color="secondary"
                  variant="text"
                  startIcon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_63_17023)">
                        <path
                          d="M9.16663 3.33332H5.66663C4.26649 3.33332 3.56643 3.33332 3.03165 3.6058C2.56124 3.84549 2.17879 4.22794 1.93911 4.69834C1.66663 5.23312 1.66663 5.93319 1.66663 7.33332V14.3333C1.66663 15.7335 1.66663 16.4335 1.93911 16.9683C2.17879 17.4387 2.56124 17.8212 3.03165 18.0608C3.56643 18.3333 4.26649 18.3333 5.66663 18.3333H12.6666C14.0668 18.3333 14.7668 18.3333 15.3016 18.0608C15.772 17.8212 16.1545 17.4387 16.3941 16.9683C16.6666 16.4335 16.6666 15.7335 16.6666 14.3333V10.8333M6.6666 13.3333H8.06206C8.46971 13.3333 8.67353 13.3333 8.86535 13.2873C9.03541 13.2464 9.19798 13.1791 9.3471 13.0877C9.5153 12.9847 9.65942 12.8405 9.94767 12.5523L17.9166 4.58332C18.607 3.89296 18.607 2.77368 17.9166 2.08332C17.2263 1.39296 16.107 1.39296 15.4166 2.08332L7.44765 10.0523C7.1594 10.3405 7.01527 10.4847 6.9122 10.6528C6.82082 10.802 6.75348 10.9645 6.71265 11.1346C6.6666 11.3264 6.6666 11.5302 6.6666 11.9379V13.3333Z"
                          stroke="#6C737F"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_63_17023">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  }
                >
                  Düzenle
                </Button>
              </Grid>
            </Grid>
          )}
        </form>
      </Grid>
    </Grid>
  );
}
