import { gql } from "@apollo/client";
import { ATHLETE_FRAGMENT } from "./fragments";

export const LOGIN_MUTATION = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      user {
        email
        firstName
        lastName
        password
        id
      }
      token
    }
  }
`;

export const SIGNUP_MUTATION = gql`
  mutation Signup(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $groupLeagueIds: [ID!]
  ) {
    signup(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      groupLeagueIds: $groupLeagueIds
    ) {
      token
      isNewUser
      user {
        createdAt
        email
        firstName
        id
        lastName
        password
        phone
        role
        updatedAt
        teams {
          leagueId
          id
          groupSegmentId
          groupLeagueName
        }
      }
    }
  }
`;
export const UPDATE_ATHLETE_PLAYER_POSITION_MUTATION = gql`
  mutation updateAthletePlayerPosition(
    $playerPositionId: String!
    $updateAthletePlayerPositionId: String!
  ) {
    updateAthletePlayerPosition(
      playerPositionId: $playerPositionId
      id: $updateAthletePlayerPositionId
    ) {
      athlete {
        playerPositionId
      }
      message
    }
  }
`;

export const UPDATE_PLAYER_GROUPS_MUTATION = gql`
  mutation updateAthleteGroupLeague($groupLeagueId: String!, $userId: String!) {
    updateAthleteGroupLeague(groupLeagueId: $groupLeagueId, id: $userId) {
      athlete {
        groupLeagueId
      }
      message
    }
  }
`;

export const UPDATE_ATHLETE_MUTATION = gql`
  mutation updateAthlete(
    $data: UpdateAthleteInput!
    $updateAthleteId: String!
  ) {
    updateAthlete(data: $data, id: $updateAthleteId) {
      athlete {
        ...AthleteFragment
      }
    }
  }
  ${ATHLETE_FRAGMENT}
`;
export const COLLECT_PAYMENT_MUTATION = gql`
  mutation collectPayment($amount: Float!, $collectPaymentId: ID!) {
    collectPayment(amount: $amount, id: $collectPaymentId) {
      payment {
        id
        amount
        status
        paidAmount
      }
    }
  }
`;

export const COMPLETE_PAYMENTS_MUTATION = gql`
  mutation completePayments($paymentIds: [ID!]!) {
    completePayments(paymentIds: $paymentIds) {
      message
      payments {
        id
        status
        paidAmount
        debtAmount
      }
    }
  }
`;

export const CREATE_ATHLETE_MEASUREMENT_MUTATION = gql`
  mutation createAthleteMeasurement(
    $value: String!
    $measurementType: String!
    $athleteId: String!
    $createdAt: DateTime
  ) {
    createAthleteMeasurement(
      value: $value
      measurementType: $measurementType
      athleteId: $athleteId
      createdAt: $createdAt
    ) {
      id
      value
      type
      createdAt
    }
  }
`;
export const UPDATE_ATHLETE_MEASUREMENT_MUTATION = gql`
  mutation updateAthleteMeasurement(
    $updateAthleteMeasurementValue2: Float!
    $updateAthleteMeasurementId: String!
  ) {
    updateAthleteMeasurement(
      value: $updateAthleteMeasurementValue2
      id: $updateAthleteMeasurementId
    ) {
      id
      value
      type
    }
  }
`;

export const CREATE_ATHLETE_MUTATION = gql`
  mutation ceateAthlete($data: CreateAthleteInput!) {
    createAthlete(data: $data) {
      athlete {
        ...AthleteFragment
      }
    }
  }
  ${ATHLETE_FRAGMENT}
`;

export const UPDATE_PROFILE_MUTATION = gql`
  mutation editMyProfile($data: UpdateUserInput!) {
    editMyProfile(data: $data) {
      id
      firstName
      lastName
      email
      phone
      dateOfBirth
      password
      receiveNewSporcu
      receivePaymentMade
      receiveGameResults
      receiveEmails
    }
  }
`;

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation changePassword(
    $newPasswordConfirmation: String!
    $newPassword: String!
    $currentPassword: String!
  ) {
    changePassword(
      newPasswordConfirmation: $newPasswordConfirmation
      newPassword: $newPassword
      currentPassword: $currentPassword
    ) {
      message
      success
      __typename
    }
  }
`;

export const DELETE_LEAGUE_MUTATION = gql`
  mutation deleteLeague($deleteLeagueId: String!) {
    deleteLeague(id: $deleteLeagueId) {
      leagueName
      id
      totalAthletes
    }
  }
`;

export const CREATE_LEAGUE_MUTATION = gql`
  mutation createLeague($name: String!) {
    createLeague(name: $name) {
      id
      leagueName
      totalAthletes
    }
  }
`;
export const UPDATE_LEAGUE_MUTATION = gql`
  mutation updateLeague($name: String!, $updateLeagueId: String!) {
    updateLeague(name: $name, id: $updateLeagueId) {
      id
      leagueName
      totalAthletes
    }
  }
`;

export const UPDATE_GROUPS_MUTATION = gql`
  mutation updateGroupSegment(
    $groupLeagueIds: [ID!]!
    $updateGroupSegmentId: String!
    $name: String
  ) {
    updateGroupSegment(
      groupLeagueIds: $groupLeagueIds
      id: $updateGroupSegmentId
      name: $name
    ) {
      groupMaxAge
      groupMinAge
      groupName
      id
      isSportSchool
      totalAthletes
      leagues {
        id
        leagueName
      }
    }
  }
`;
export const DELETE_GROUPS_MUTATION = gql`
  mutation deleteGroupSegment($deleteGroupSegmentId: String!) {
    deleteGroupSegment(id: $deleteGroupSegmentId) {
      groupMaxAge
      totalAthletes
      groupName
      id
    }
  }
`;

export const CREATE_GROUPS_MUTATION = gql`
  mutation createGroupSegment($groupLeagueIds: [ID!]!, $name: String!) {
    createGroupSegment(groupLeagueIds: $groupLeagueIds, name: $name) {
      id
      groupName
      totalAthletes
      leagues {
        id
        leagueName
      }
    }
  }
`;
export const DELETE_USER_MUTATION = gql`
  mutation deleteUser($deleteUserId: ID!) {
    deleteUser(id: $deleteUserId) {
      firstName
      lastName
      id
      role
      profilePhoto
      teams {
        id
        coachId
        groupLeagueName
        createdAt
      }
    }
  }
`;
export const UPDATE_USER_MUTATION = gql`
  mutation updateUser($data: UpdateUserInput!, $updateUserId: ID!) {
    updateUser(data: $data, id: $updateUserId) {
      email
      id
      lastName
      firstName
      role
      teams {
        createdAt
        groupLeagueName
        id
      }
    }
  }
`;

export const CREATE_COACH_MUTATION = gql`
  mutation adminAddCoach(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $groupLeagueIds: [ID!]
  ) {
    adminAddCoach(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      groupLeagueIds: $groupLeagueIds
    ) {
      id
    }
  }
`;

export const NOTIFICATION_MUTATION = gql`
  mutation mutation {
    markMyNotificationsAsRead
  }
`;
