import { Button, Grid, SvgIcon, Typography } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";

export default function CardTitle({ res, index, hideButton }) {
  return (
    <Grid
      container
      sx={{
        p: { xs: "16px 0px 8px 16px", sm: "16px 16px 8px 24px" },
        flexWrap: "nowrap",
        borderBottom: { xs: "1px solid  #f3f4f6 ", lg: "none" },
      }}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Typography
        sx={{
          textTransform: "uppercase",
          lineHeight: "12px",
          color: "#111927",
        }}
        variant="caption"
      >
        {res?.title}
      </Typography>

      {!hideButton && (
        <Grid item component={NavLink} to={`/mac-sonuclari/${index}`}>
          <Button
            sx={{
              padding: "0px 16px 0px 0px",
              minWidth: { xs: "max-content", sm: "unset" },
              fontSize: {
                xs: "13px",
                sm: "15px",
                xl: "20px",
              },
              "& .MuiButton-icon": { m: 0, pl: { xs: 0, sm: "8px" } },
            }}
            endIcon={
              <SvgIcon
                sx={{
                  width: { xs: "20px", sm: "24px" },
                  height: { xs: "20px", sm: "24px" },
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="inherit"
                  height="inherit"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z"
                    fill="#6366F1"
                  />
                </svg>
              </SvgIcon>
            }
          >
            Detaylara Git
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
