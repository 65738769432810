import { Grid, Typography } from "@mui/material";
import React from "react";

export default function MatchResultTeamDetail({
  names,
  levels,
  png,
  flexDirection,
  end,
  justifyContent,
  paddingRight,
  paddingLeft,
  column,
  textAlign,
  flexEnd,
}) {
  return (
    <Grid item xs={6} sm={6} md={6} lg={4.5}>
      <Grid container alignItems={"center"}>
        <Grid
          container
          sx={{
            flexDirection: {
              xs: column,
              sm: column,
              md: flexDirection,
              lg: flexDirection,
            },
          }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid>
            <img
              style={{ width: "80px", height: "80px" }}
              alt="göztepe-logo"
              src={png}
            />
          </Grid>

          <Grid
            item
            xs
            sx={{
              pt: { xs: "8px", lg: "block" },
              pl: { lg: paddingLeft, md: paddingLeft, xs: "block" },
              pr: paddingRight,
            }}
          >
            <Grid container justifyContent={justifyContent}>
              <Typography
                sx={{
                  fontSize: {
                    xs: "18px",
                    sm: "19px",
                    md: "19px",
                    lg: "20px",
                    xl: "26px",
                  },
                  textAlign: {
                    lg: end,
                    md: "center",
                    sm: "center",
                    xs: "center",
                  },
                }}
                variant="captionTitle"
              >
                {names}
              </Typography>
            </Grid>
            <Grid
              container
              sx={{
                justifyContent: {
                  xs: "center",
                  sm: "center",
                  md: flexEnd,
                  lg: textAlign,
                },
              }}
            >
              <Typography
                sx={{
                  fontStyle: "normal",
                  fontSize: { lg: "14px", xl: "14px" },
                }}
                variant="body1"
              >
                {levels}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
