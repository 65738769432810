import React from "react";
import ApexChart from "react-apexcharts";
import { useTheme } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";
import moment from 'moment';
const useChartOptions = ({ categories }) => {
  const theme = useTheme();

  return {
    chart: {
      background: "transparent",
      stacked: false,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: ["#9E77ED"],
    dataLabels: {
      enabled: false,
    },
    fill: {
      gradient: {
        opacityFrom: 0.4,
        opacityTo: 0.1,
        stops: [0, 100],
      },
      type: "gradient",
    },
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    theme: {
      mode: theme.palette.mode,
    },
    tooltip: {
      y: {
        formatter: (value) => value,
      },
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories,
      labels: {
        offsetY: 5,
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
    yaxis: {
      categories: [0, 5, 10, 15, 20, 25],

      labels: {
        offsetX: -10,
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
  };
};

export const AreaChart = ({ applicationsByDateRange, labels, title }) => {

  const categories = labels?.map(range => moment(range).format('DD/MM/YYYY'));
  const chartOptions = useChartOptions({
    categories: categories,
  });
  const chartSeries = applicationsByDateRange?.map((range) => range?.count);

  return (
    <Grid container>
      {chartSeries?.length === 0 && <Grid sx={{ my: 2 }} container justifyContent={"center"}>
        <Typography>
          Lütfen ölçüm ekleyiniz...
        </Typography>
      </Grid>}
      {chartSeries?.length > 0 && <ApexChart
        style={{ width: '100%', overflow: 'hidden' }} // Ensure minimum width of 1000px
        height={400}
        options={chartOptions}
        series={[
          {
            name: title,
            data: chartSeries,


          },
        ]}
        type="area"
      />}
    </Grid>
  );
};

export const LineChartMui = ({ data }) => {
  let myArr = data?.datas?.map(r => {
    return {
      count: r?.value
    }
  })
  let labels = data?.datas?.map(r => {
    return r?.createdAt;
  })
  console.log('qwfqwfwq', labels)

  return (
    <>
      <AreaChart
        title={data?.label}
        labels={labels}
        applicationsByDateRange={myArr}

      />
    </>
  );
};
