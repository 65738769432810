import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FaPlus } from "react-icons/fa6";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useMutation, useQuery } from "@apollo/client";
import { ALL_GROUPS_QUERY, ALL_PLAYER_POSITIONS_QUERY } from "../../queries";
import { CREATE_ATHLETE_MUTATION } from "../../mutations";
import toast from "react-hot-toast";
import { MuiTelInput } from "mui-tel-input";

import { ATHLETE_FRAGMENT } from "../../fragments";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
const AddAthleteDialog = () => {
  const isMobil = useMediaQuery("(max-width:600px)");
  const [open, setOpen] = React.useState(false);
  const { data: allPlayerPositions } = useQuery(ALL_PLAYER_POSITIONS_QUERY);
  const { data: allGroupLeagueJunctions } = useQuery(ALL_GROUPS_QUERY);
  const [ceateAthlete] = useMutation(CREATE_ATHLETE_MUTATION);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      birthDate: null,
      phone: "",
      parentPhone: "",
      playerPosition: "",

      height: "",
      weight: "",
      jumpHeight: "",
      blockHeight: "",
      spikeHeight: "",
      groupLeagueId: "",
      exitSpeed: "",
      paymentAmount: null,
      passiveAthlete: false,
    },

    validationSchema: Yup.object({
      firstName: Yup.string().required("Ad boş bırakılamaz"),
      lastName: Yup.string().required("Soyad boş bırakılamaz"),

      birthDate: Yup.date().nullable().required("Doğum tarihi boş bırakılamaz"),
      phone: Yup.string().required("Telefon numarası boş bırakılamaz"),
      parentPhone: Yup.string().required("Veli numarası boş bırakılamaz"),
      groupLeagueId: Yup.object().required("Zorunlu Alan"),
    }),
    onSubmit: (values, { resetForm }) => {
      try {
        ceateAthlete({
          variables: {
            data: {
              email: values.email,
              firstName: values.firstName,
              lastName: values.lastName,
              status: values.status,
              groupLeagueId: values.groupLeagueId?.id,
              dateOfBirth: values.birthDate,
              phone: values.phone,
              playerPositionId: values.playerPosition?.id,
              parentNumber: values.parentPhone,
              height: values.height.toString(),
              weight: values.weight.toString(),
              jumpHeight: values.jumpHeight.toString(),
              blockHeight: values.blockHeight.toString(),
              spikeHeight: values.spikeHeight.toString(),
              exitSpeed: values.exitSpeed.toString(),
              specialPaymentAmount: values.paymentAmount,
            },
          },

          update: (cache, { data }) => {
            cache.modify({
              fields: {
                allAthletes(existingAllAthletes = {}) {
                  const newActivityRef = cache.writeFragment({
                    data: data?.createAthlete?.athlete,
                    fragment: ATHLETE_FRAGMENT,
                  });
                  return {
                    ...existingAllAthletes,
                    athletes: [newActivityRef, ...existingAllAthletes.athletes],
                  };
                },
              },
            });
          },
        });

        toast.success("Sporcu başarıyla eklendi!");
      } catch (error) {
        toast.error(error.message);
      }

      handleClose();
    },
  });
  console.log("snjnsjnjns", formik.values);

  return (
    <>
      <Button
        onClick={handleClickOpen}
        sx={{
          width: {
            xs: "100%",
            sm: "unset",
          },
          minWidth: {
            xs: "100%",
            sm: "156px",
          },
          mt: { xs: 2, sm: 0 },
        }}
        startIcon={<FaPlus size={16} />}
        variant="contained"
      >
        Sporcu Ekle
      </Button>
      <Dialog
        PaperProps={{
          sx: { borderRadius: { xs: 0, sm: "20px" } },
        }}
        fullScreen={isMobil}
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          component={Grid}
          container
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          Sporcu Ekle
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon sx={{ color: "#6C737F", width: 20, height: 20 }} />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              sx={{
                bgcolor: "#fff",
                borderRadius: { xs: "8px", sm: "20px" },
                p: {
                  xs: 2,
                  sm: 3,
                },
              }}
            >
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12} sx={{ pt: "0px !important" }}>
                  <TextField
                    sx={{ minHeight: "76px" }}
                    placeholder="Ad*"
                    fullWidth
                    variant="outlined"
                    id="firstName"
                    {...formik.getFieldProps("firstName")}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                  />
                </Grid>
                <Grid item sm={6} xs={12} sx={{ pt: "0px !important" }}>
                  <TextField
                    sx={{ minHeight: "76px" }}
                    placeholder="Soyad*"
                    fullWidth
                    variant="outlined"
                    id="lastName"
                    {...formik.getFieldProps("lastName")}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                  />
                </Grid>

                <Grid item sm={6} xs={12} sx={{ pt: "0px !important" }}>
                  <TextField
                    sx={{ minHeight: "76px" }}
                    placeholder="Eposta adresi*"
                    fullWidth
                    variant="outlined"
                    id="email"
                    {...formik.getFieldProps("email")}
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                  sx={{
                    pt: "0px !important",

                    "& .MuiFormControl-root": {
                      width: "100% !important",
                    },
                    "& .MuiOutlinedInput-root": {
                      maxHeight: "53.13px",
                      height: "100%",
                    },
                  }}
                >
                  {console.log("qwfqwfqwfqwf", formik.errors)}
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      fullWidth
                      sx={{
                        maxHeight: "53.13px",
                        height: "100%",
                        "& fieldset": {
                          ...(Boolean(formik.errors.birthDate)
                            ? {
                                borderColor: (theme) =>
                                  theme.palette.error.main,
                              }
                            : {}),
                        },
                      }}
                      id="birthDate"
                      value={formik.values.birthDate}
                      onChange={(date) => {
                        formik.setFieldValue("birthDate", date);
                      }}
                      placeholderText="Doğum Tarihi*"
                      dateFormat="DD/MM/YYYY"
                    />
                    {formik.touched.birthDate && formik.errors.birthDate && (
                      <Typography
                        fontSize={10}
                        ml={"14px"}
                        fontWeight={400}
                        variant="caption"
                        color="error"
                        sx={{ top: -2, position: "relative" }}
                      >
                        Zorunlu Alan
                      </Typography>
                    )}
                  </LocalizationProvider>
                </Grid>

                <Grid
                  item
                  sm={6}
                  xs={12}
                  sx={{
                    minHeight: "76px",

                    pt: "0px !important",
                    "& .MuiOutlinedInput-root": { paddingLeft: "5px" },
                  }}
                >
                  <MuiTelInput
                    international
                    forceCallingCode={true}
                    defaultCountry="TR"
                    fullWidth
                    inputProps={{
                      placeholder: "Telefon numarasını girin*",
                    }}
                    id="phone"
                    value={formik.values.phone}
                    onChange={(value) => formik.setFieldValue("phone", value)}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />
                </Grid>

                <Grid
                  item
                  sm={6}
                  xs={12}
                  sx={{
                    minHeight: "76px",
                    pt: "0px !important",
                    "& .MuiOutlinedInput-root": { paddingLeft: "5px" },
                  }}
                >
                  <MuiTelInput
                    forceCallingCode={true}
                    international
                    defaultCountry="TR"
                    fullWidth
                    inputProps={{
                      placeholder: "Veli telefon numarasını girin*",
                    }}
                    id="prentPhone"
                    value={formik.values.parentPhone}
                    onChange={(value) =>
                      formik.setFieldValue("parentPhone", value)
                    }
                    error={
                      formik.touched.parentPhone &&
                      Boolean(formik.errors.parentPhone)
                    }
                    helperText={
                      formik.touched.parentPhone && formik.errors.parentPhone
                    }
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                  sx={{ pt: "0px !important", minHeight: 76 }}
                >
                  <Select
                    fullWidth
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <Typography variant="placeholder">
                            Oyuncu Pozisyonu
                          </Typography>
                        );
                      }

                      return selected?.name;
                    }}
                    id="playerPosition"
                    {...formik.getFieldProps("playerPosition")}
                  >
                    {allPlayerPositions?.allPlayerPositions?.map((position) => (
                      <MenuItem key={position?.id} value={position}>
                        {position?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                  sx={{ pt: "0px !important", minHeight: 76 }}
                >
                  <Select
                    fullWidth
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <Typography variant="placeholder">Grup*</Typography>
                        );
                      }
                      return selected?.groupLeagueName;
                    }}
                    displayEmpty
                    id="groupLeagueId"
                    name="groupLeagueId"
                    {...formik.getFieldProps("groupLeagueId")}
                    onChange={(event) => {
                      let myObj = event.target.value;
                      formik.setFieldValue(
                        "paymentAmount",
                        myObj?.paymentAmount
                      );
                      formik.setFieldValue("groupLeagueId", myObj);
                    }}
                    error={
                      formik.touched.groupLeagueId &&
                      Boolean(formik.errors.groupLeagueId)
                    }
                    helperText={
                      formik.touched.groupLeagueId &&
                      formik.errors.groupLeagueId
                    }
                  >
                    {allGroupLeagueJunctions?.allGroupLeagueJunctions?.map(
                      (group) => {
                        return (
                          <MenuItem value={group} key={group?.id}>
                            {group?.groupLeagueName}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                  {formik.touched.groupLeagueId &&
                    formik.errors.groupLeagueId && (
                      <Typography
                        fontSize={10}
                        ml={"14px"}
                        fontWeight={400}
                        variant="caption"
                        color="error"
                      >
                        Zorunlu Alan
                      </Typography>
                    )}
                </Grid>

                <Grid item sm={6} xs={12} sx={{ pt: "0px !important" }}>
                  <TextField
                    sx={{ minHeight: "76px" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">cm</InputAdornment>
                      ),
                    }}
                    placeholder="Boy"
                    fullWidth
                    type="number"
                    variant="outlined"
                    id="height"
                    {...formik.getFieldProps("height")}
                  />
                </Grid>
                <Grid item sm={6} xs={12} sx={{ pt: "0px !important" }}>
                  <TextField
                    sx={{ minHeight: "76px" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">kg</InputAdornment>
                      ),
                    }}
                    placeholder="Kilo"
                    fullWidth
                    type="number"
                    variant="outlined"
                    id="weight"
                    {...formik.getFieldProps("weight")}
                  />
                </Grid>
                <Grid item sm={6} xs={12} sx={{ pt: "0px !important" }}>
                  <TextField
                    sx={{ minHeight: "76px" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">cm</InputAdornment>
                      ),
                    }}
                    placeholder="Sıçrama Yüksekliği"
                    fullWidth
                    variant="outlined"
                    id="jumpHeight"
                    type="number"
                    {...formik.getFieldProps("jumpHeight")}
                  />
                </Grid>
                <Grid item sm={6} xs={12} sx={{ pt: "0px !important" }}>
                  <TextField
                    sx={{ minHeight: "76px" }}
                    placeholder="Blok Yüksekliği"
                    fullWidth
                    variant="outlined"
                    type="number"
                    id="blockHeight"
                    {...formik.getFieldProps("blockHeight")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">cm</InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item sm={6} xs={12} sx={{ pt: "0px !important" }}>
                  <TextField
                    sx={{ minHeight: "76px" }}
                    placeholder="Smaç Yüksekliği"
                    fullWidth
                    variant="outlined"
                    id="spikeHeight"
                    type="number"
                    {...formik.getFieldProps("spikeHeight")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">cm</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12} sx={{ pt: "0px !important" }}>
                  <TextField
                    sx={{ minHeight: "76px" }}
                    placeholder="Çıkış Hızı"
                    fullWidth
                    variant="outlined"
                    id="exitSpeed"
                    type="number"
                    {...formik.getFieldProps("exitSpeed")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">m/s</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                {formik.values.groupLeagueId && (
                  <Grid item sm={6} xs={12} sx={{ pt: "0px !important" }}>
                    <TextField
                      sx={{ minHeight: "76px" }}
                      placeholder="Aidat Alanı"
                      fullWidth
                      variant="outlined"
                      id="paymentAmount"
                      type="number"
                      {...formik.getFieldProps("paymentAmount")}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">TL</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                )}
                <Grid item md={12} sx={{ minHeight: "80px" }}>
                  <Grid container>
                    <Grid item>
                      <Switch
                        id="passiveAthlete"
                        {...formik.getFieldProps("passiveAthlete")}
                      />
                    </Grid>
                    <Grid item xs>
                      <Typography fontWeight={500}>
                        Pasif sporcu olarak kaydet
                      </Typography>
                      <Typography variant="body2">
                        Eklediğiniz sporcular varsayılan olarak aktif oyuncular
                        olarak kabul edilecektir.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    sx={{
                      mt: 3,
                      p: { xs: 0.5, sm: 0 },
                      position: {
                        xs: "fixed",
                        sm: "unset",
                        bottom: 0,
                        left: 0,
                        right: 0,
                      },
                    }}
                    justifyContent={"flex-end"}
                  >
                    <Button
                      type="submit"
                      fullWidth={isMobil}
                      variant="contained"
                      startIcon={<FaPlus size={16} />}
                    >
                      Sporcu Ekle
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddAthleteDialog;
