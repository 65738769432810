import { useMutation, useQuery } from "@apollo/client";
import { FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import React from "react";
import { LOGGED_IN_QUERY } from "../../../queries";
import { UPDATE_PROFILE_MUTATION } from "../../../mutations";
import toast from "react-hot-toast";

export default function SettingsNotifications() {
  const { data: loggedInUser } = useQuery(LOGGED_IN_QUERY);
  const [editMyProfile] = useMutation(UPDATE_PROFILE_MUTATION);

  const handleSwitchChange = (checked, text) => {
    if (text === "receiveNewSporcu") {
      editMyProfile({
        variables: {
          data: {
            receiveNewSporcu: checked,
          },
        },
      })
        .then(() => {
          toast.success("Değişiklikler Kaydedildi!");
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
    if (text === "receivePaymentMade") {
      editMyProfile({
        variables: {
          data: {
            receivePaymentMade: checked,
          },
        },
      })
        .then(() => {
          toast.success("Değişiklikler Kaydedildi!");
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };
  return (
    <Grid container sx={{ pb: "24px" }}>
      <Grid
        container
        sx={{
          p: "32px 24px",
          borderRadius: "20px",
          boxShadow:
            "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
          bgcolor: "white",
        }}
      >
        <Grid item xs={12} md={2} sx={{ pb: { xs: "12px", sm: "0px" } }}>
          <Typography
            sx={{
              fontWeight: 600,
            }}
            variant="subtitle1"
          >
            Bildirimler
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Grid container alignItems={"center"} sx={{ pb: "24px" }}>
            <Grid item xs>
              <Grid container>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  Yeni Sporcu
                </Typography>
              </Grid>
              <Grid container>
                <Typography variant="body2">
                  Sisteme yeni sporcu eklendiğinde bildirim gönder gönder.
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={loggedInUser?.loggedInUser?.receiveNewSporcu}
                    onChange={(e) =>
                      handleSwitchChange(e.target.checked, "receiveNewSporcu")
                    }
                    color="primary"
                  />
                }
              />
            </Grid>
          </Grid>
          <Grid container alignItems={"center"}>
            <Grid item xs>
              <Grid container>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  Aidat Ödemesi
                </Typography>
              </Grid>
              <Grid container>
                <Typography variant="body2">
                  Yeni bir aidat ödemesi eklendiğinde bildirim gönder.
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={loggedInUser?.loggedInUser?.receivePaymentMade}
                    onChange={(e) =>
                      handleSwitchChange(e.target.checked, "receivePaymentMade")
                    }
                    color="primary"
                  />
                }
              />
            </Grid>
          </Grid>{" "}
          {/* <Grid container alignItems={"center"}>
              <Grid item xs>
                <Grid container>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    Maç Sonucu
                  </Typography>
                </Grid>
                <Grid container>
                  <Typography variant="body2">
                    Maç sonuçlarını bildirim olarak gönder.
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      id="receiveGameResults"
                      name="receiveGameResults"
                      checked={formik.values.receiveGameResults}
                      onChange={handleSwitchChange}
                      color="primary"
                    />
                  }
                />
              </Grid>
            </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
}
