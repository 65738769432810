import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  CircularProgress,
  useMediaQuery,
  InputAdornment,
  DialogContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import MultipleSelectChip from "../../../components/card/MultipleSelectChip";
import { CREATE_COACH_MUTATION } from "../../../mutations";
import { ALL_GROUPS_QUERY } from "../../../queries";

const validationSchema = Yup.object({
  email: Yup.string().email("Geçersiz e-mail adresi").required("Zorunlu alan"),
  firstName: Yup.string().required("Zorunlu alan."),
  lastName: Yup.string().required("Zorunlu alan."),
  password: Yup.string()
    .required("Zorunlu alan.")
    .min(6, "Parola 6 karakterden az olmamalı."),
  select: Yup.array().min(1, "En az bir takım seçmelisiniz"),
});

export default function CreateCoach() {
  const [updateOpen, setUpdateOpen] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false);

  const [adminAddCoach, { loading }] = useMutation(CREATE_COACH_MUTATION, {
    refetchQueries: ["allUsers"],
  });

  const { data: rawAllGroupLeagues } = useQuery(ALL_GROUPS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      attachedToACoach: false,
    },
    skip: !updateOpen,
  });

  const allGroupLeagues = rawAllGroupLeagues?.allGroupLeagueJunctions || [];

  const isMobile = useMediaQuery("(max-width:600px)");

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    resetForm,
    setFieldValue,
    touched,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      select: [],
      showPassword: false,
    },
    validationSchema,
    onSubmit: (values) => {
      adminAddCoach({
        variables: {
          email: values.email,
          password: values.password,
          firstName: values.firstName,
          lastName: values.lastName,
          groupLeagueIds: values.select.map((item) => item.id),
        },
      })
        .then((data) => {
          toast.success("İşlem başarılı.");
          handleCloseUpdate();
        })
        .catch((err) => {
          toast.error(err.message);
        });
    },
  });
  console.log("values", values);
  const handleUptadeOpen = () => {
    setUpdateOpen(true);
  };

  const handleCloseUpdate = () => {
    setUpdateOpen(false);
    resetForm();

    setFormSubmitted(false);
  };

  const handleFormSubmit = () => {
    setFormSubmitted(true);
    handleSubmit();
  };

  return (
    <>
      <Grid item onClick={handleUptadeOpen}>
        <Button variant="contained">Koç Ekle</Button>
      </Grid>
      <Dialog
        PaperProps={{
          sx: {
            maxWidth: "696px",
            width: "100%",
          },
        }}
        fullWidth={true}
        fullScreen={isMobile}
        open={updateOpen}
        onClose={handleCloseUpdate}
      >
        <DialogTitle
          component={Grid}
          container
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="h6">Koç Ekle</Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseUpdate}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon sx={{ color: "#6C737F", width: 20, height: 20 }} />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Grid container sx={{ bgcolor: "#FFF", p: 3, borderRadius: 3 }}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleFormSubmit();
              }}
            >
              <TextField
                sx={{
                  minHeight: 76,
                }}
                id="firstName"
                label="Ad"
                variant="outlined"
                type="text"
                fullWidth
                name="firstName"
                value={values.firstName}
                error={formSubmitted && touched.firstName && !!errors.firstName}
                helperText={
                  formSubmitted && touched.firstName ? errors.firstName : ""
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextField
                sx={{
                  minHeight: 76,
                }}
                id="lastName"
                label="Soyad"
                variant="outlined"
                type="text"
                fullWidth
                name="lastName"
                value={values.lastName}
                error={formSubmitted && touched.lastName && !!errors.lastName}
                helperText={
                  formSubmitted && touched.lastName ? errors.lastName : ""
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextField
                sx={{
                  minHeight: 76,
                }}
                id="email"
                label="Eposta adresi"
                variant="outlined"
                type="email"
                fullWidth
                value={values.email}
                name="email"
                error={formSubmitted && touched.email && !!errors.email}
                helperText={formSubmitted && touched.email ? errors.email : ""}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <MultipleSelectChip
                options={allGroupLeagues}
                selectedOptions={values.select}
                setSelectedOptions={(value) => setFieldValue("select", value)}
                error={formSubmitted && touched.select && !!errors.select}
                helperText={
                  formSubmitted && touched.select ? errors.select : ""
                }
                handleFormSubmit={handleFormSubmit}
              />
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setFieldValue("showPassword", !values.showPassword)
                        }
                      >
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  minHeight: 76,
                }}
                id="password"
                label="Şifre"
                variant="outlined"
                type={values.showPassword ? "text" : "password"}
                fullWidth
                name="password"
                value={values.password}
                error={formSubmitted && touched.password && !!errors.password}
                helperText={
                  formSubmitted && touched.password ? errors.password : ""
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <Grid container justifyContent="center" alignItems="center">
                <Button
                  disabled={loading}
                  type="submit"
                  fullWidth
                  variant="contained"
                >
                  {loading ? <CircularProgress size="25px" /> : "Koç Ekle"}
                </Button>
              </Grid>
            </form>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
