import { Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import LoginHeader from "../loginPage/LoginHeader";
import { useFormik } from "formik";
import * as Yup from "yup";
import SignUpButtonCard from "./SignUpButtonCard";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Geçersiz e-mail adresi")
    .required("Lütfen sistemde kayıtlı olan e-mail adresinizi giriniz."),
});

export default function IforgatMyPasswordPage() {
  const {
    handleSubmit,
    handleChange,
    values,
    errors,

    touched,
  } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    validateOnMount: false,

    onSubmit: (values) => {},
  });

  return (
    <>
      <LoginHeader />

      <Grid
        sx={{ height: "calc(100vh - 60px)" }}
        alignItems={"center"}
        container
      >
        <Grid container justifyContent={"center"}>
          <Grid item sx={{ maxWidth: "444px", width: "100%" }}>
            <SignUpButtonCard />

            <Grid
              container
              sx={{
                minHeight: "232px",
                borderRadius: "20px",
                bgcolor: "#FFF",
                boxShadow:
                  "0px 0px 0px 0.5px #00000008, 0px 5px 22px 0px #0000000a",
              }}
            >
              <Grid
                alignItems={"center"}
                container
                sx={{ p: "32px 24px 16px 24px" }}
              >
                <Grid container>
                  <Typography variant="captionTitle" sx={{ fontSize: "18px" }}>
                    Şifremi Unuttum
                  </Typography>
                </Grid>
              </Grid>
              <form onSubmit={handleSubmit}>
                <Grid container sx={{ p: "16px 24px 32px 24px" }}>
                  <TextField
                    sx={{
                      pb: "16px",
                      minHeight: 76,
                    }}
                    id="email"
                    label="Eposta adresi"
                    variant="filled"
                    type="email"
                    fullWidth
                    value={values.email}
                    name="email"
                    error={touched.email && !!errors.email}
                    helperText={touched.email ? errors.email : ""}
                    onChange={handleChange}
                    onBlur={handleChange}
                  />
                  <Grid container justifyContent="center">
                    <Button
                      type="submit"
                      // disabled={loading}
                      fullWidth
                      variant="contained"
                    >
                      Sıfırlama linki gönder
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
