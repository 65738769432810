import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import AvatarCard from "../../components/card/athleteTable/AvatarCard";
import { useQuery } from "@apollo/client";

import { DUE_ATHLETES_LIST_QUERY } from "../../queries";
import StatusChip from "../../components/card/athleteTable/StatusChip";
import { NavLink } from "react-router-dom";

export default function HomeDuesTable() {
  const { data: allAthletes } = useQuery(DUE_ATHLETES_LIST_QUERY, {
    variables: {
      take: 5,
      hasUnpaidPayments: true,
      orderBy: {
        totalDebtAmount: "desc",
      },
    },
  });

  return (
    <Grid container spacing={5} sx={{ px: 3, pb: 3 }}>
      <Grid item xs={12} md={6}>
        <Grid
          container
          sx={{
            boxShadow:
              "0px 0px 0px 0.5px #00000008, 0px 5px 22px 0px #0000000a",
            p: 3,
            borderRadius: "8px",
            bgcolor: "#FFF",
          }}
        >
          <Grid
            container
            sx={{ borderBottom: "1px solid #d9d7d74a", pb: 1 }}
            alignItems={"center"}
          >
            <Grid item xs>
              <Grid container>
                <Typography sx={{ fontWeight: 700, fontSize: 18 }}>
                  En Çok Borcu olan Sporcular
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                component={NavLink}
                to={"/aidat-raporlari"}
                sx={{ color: "red" }}
              >
                <Button sx={{ fontSize: 14 }}>Daha Fazla</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 1 }}>
            {allAthletes?.allAthletes?.athletes?.map((athlete, index) => {
              return (
                <Grid
                  sx={{ py: 2 }}
                  container
                  key={index}
                  alignItems={"center"}
                  justifyContent="space-between"
                >
                  <Grid item xs>
                    <AvatarCard
                      playerId={athlete?.id}
                      firstName={athlete.firstName}
                      lastName={athlete.lastName}
                      email={athlete.email}
                    />
                  </Grid>
                  <Grid item>
                    <Typography sx={{ color: "red" }} variant="body1">
                      {athlete.totalDebtAmount} TL
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid
          container
          sx={{
            boxShadow:
              "0px 0px 0px 0.5px #00000008, 0px 5px 22px 0px #0000000a",
            p: 3,
            borderRadius: "8px",
            bgcolor: "#FFF",
          }}
        >
          <Grid
            container
            sx={{ borderBottom: "1px solid #d9d7d74a", pb: 1 }}
            alignItems={"center"}
          >
            <Grid item xs>
              <Grid container>
                {" "}
                <Typography sx={{ fontWeight: 700, fontSize: 18 }}>
                  Son Eklenen Sporcular
                </Typography>{" "}
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                component={NavLink}
                to={"/sporcular"}
                sx={{ color: "red" }}
              >
                <Button sx={{ fontSize: 14 }}>Daha Fazla</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 1 }}>
            {allAthletes?.allAthletes?.athletes?.map((athlete, index) => {
              return (
                <Grid
                  sx={{ py: 2 }}
                  container
                  key={index}
                  alignItems={"center"}
                >
                  <Grid item xs>
                    <AvatarCard
                      playerId={athlete?.id}
                      firstName={athlete.firstName}
                      lastName={athlete.lastName}
                      email={athlete.email}
                    />
                  </Grid>
                  <Grid item>
                    <StatusChip value={athlete?.status} />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
