import React from "react";
import {
  Grid,
  Typography,
  IconButton,
  useMediaQuery,
  TextField,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";

export default function CalendarDetailDialog({
  setDetailData,
  detailData,
  open,
  setOpen,
}) {
  const handledialogClose = () => {
    setDetailData(null);
    setOpen(false);
  };

  const isMobil = useMediaQuery("(max-width:600px)");
  return (
    <Dialog
      fullScreen={isMobil}
      open={open}
      onClose={handledialogClose}
      PaperProps={{
        sx: { borderRadius: { xs: 0, sm: "20px" } },
        component: "form",
        onSubmit: (event) => {
          event.preventDefault();

          handledialogClose();
        },
      }}
    >
      <DialogTitle
        component={Grid}
        container
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h6">{detailData?.title}</Typography>
        <IconButton
          aria-label="close"
          onClick={handledialogClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ color: "#6C737F", width: 20, height: 20 }} />
        </IconButton>
      </DialogTitle>
      <Grid container>
        <DialogContent>
          <Grid spacing={2} container>
            <Grid item xs={12}>
              <Grid
                container
                sx={{
                  p: { xs: 2, sm: 3 },
                  borderRadius: "20px",
                  padding: "24px",
                  alignSelf: "stretch",
                  gap: "16px",
                  bgcolor: "#fff",
                  boxShadow:
                    "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                }}
              >
                <Grid container>
                  <TextField
                    fullWidth
                    label="Antrenman Salonu"
                    disabled
                  ></TextField>
                </Grid>
                <Grid container>
                  <TextField fullWidth label="Tekrar" disabled></TextField>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Başlangıç Saati"
                      fullWidth
                      disabled
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Bitiş Saati"
                      fullWidth
                      disabled
                    ></TextField>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Grid>
    </Dialog>
  );
}
