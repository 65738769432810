import { Grid, Typography } from "@mui/material";
import React from "react";

export default function LeftCard({ res }) {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={2}
      xl={1}
      sx={{
        borderRight: {
          xs: "none",
          sm: "none",
          md: "none",
          lg: "1px solid #f3f4f6;",
        },
        borderBottom: { xs: "1px solid  #f3f4f6 ", lg: "none" },

        display: { xs: "flex", sm: "flex", md: "flex", lg: "block" },
      }}
    >
      <Grid container sx={{ pb: "16px" }}>
        <Typography
          sx={{
            fontSize: "12px",
            lineHeight: "166%",
            "-webkit-box-orient": "vertical",
            "-webkit-line-clamp": "1",
            display: "-webkit-box",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          variant="subtitle3"
        >
          {res?.date}
        </Typography>

        <Grid container>
          {" "}
          <Typography variant="caption2"> {res?.dateName} </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          pb: "16px",
          maxWidth: { xs: "67px", sm: "86px", md: "150px", lg: "unset" },
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            lineHeight: "166%",
          }}
          variant="subtitle3"
        >
          {res?.matchDateHour}
        </Typography>
        <Grid container>
          <Typography variant="caption2"> {res?.matchDate} </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ pb: "16px" }}>
        <Typography
          sx={{
            fontSize: "12px",
            lineHeight: "166%",
            "-webkit-box-orient": "vertical",
            "-webkit-line-clamp": "1",
            display: "-webkit-box",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          variant="subtitle3"
        >
          {res?.location}
        </Typography>
        <Grid container>
          <Typography variant="caption2"> {res?.matchLocation} </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
