import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import LoginHeader from "./LoginHeader";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { LOGIN_MUTATION } from "../../mutations";
import { applyLogin } from "../../utils";
import toast from "react-hot-toast";

const validationSchema = Yup.object({
  email: Yup.string().email("Geçersiz e-mail adresi").required("Zorunlu alan."),
  password: Yup.string()
    .required("Zorunlu alan.")
    .min(6, "Parolanız 6 karakterden az olmamalı."),
});

export default function Login() {
  const [login, { loading }] = useMutation(LOGIN_MUTATION);
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const { data } = await login({
          variables: {
            email: values.email,
            password: values.password,
          },
        });

        const myToken = data?.login?.token;
        if (myToken) {
          applyLogin(data.login);
          navigate("/");
        } else {
          toast.error("Bir hata oluştu lütfen sonra tekrar deneyiniz.");
        }
      } catch (error) {
        toast.error(error.message);
      }
    },
  });

  return (
    <>
      <LoginHeader />
      <Grid
        sx={{ height: "calc(100vh - 60px)" }}
        justifyContent={"center"}
        alignItems={"center"}
        container
      >
        <Grid
          container
          sx={{
            minHeight: "364px",
            maxWidth: "444px",
            width: "100%",
            borderRadius: "20px",
            bgcolor: "#FFF",
            boxShadow:
              "0px 0px 0px 0.5px #00000008, 0px 5px 22px 0px #0000000a",
          }}
        >
          <Grid
            alignItems={"center"}
            container
            sx={{ p: "32px 24px 16px 24px" }}
          >
            <Grid container>
              <Typography variant="captionTitle" sx={{ fontSize: "18px" }}>
                Giriş Yap
              </Typography>
            </Grid>

            {/* <Typography variant="body2" sx={{ fontFamily: "Inter", mr: "2px" }}>
              Hesabınız yok mu?
            </Typography>
            <Typography
              variant="body2"
              component={NavLink}
              to={"/kayit-ol"}
              sx={{ fontFamily: "Inter", color: "#6366F1" }}
            >
              Kayıt Ol
            </Typography> */}
          </Grid>

          <Grid container sx={{ p: "16px 24px 32px 24px" }}>
            <form onSubmit={handleSubmit}>
              <TextField
                sx={{
                  minHeight: 76,
                }}
                label="Eposta adresi"
                variant="filled"
                fullWidth
                value={values.email}
                name="email"
                error={touched.email && !!errors.email}
                helperText={touched.email ? errors.email : ""}
                onChange={handleChange}
              />
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Grid
                        alignItems={"center"}
                        container
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <VisibilityIcon />}
                      </Grid>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  minHeight: 76,
                  "& input::placeholder": {
                    color: "#6C737F",
                    opacity: 1,
                    fontSize: "14px",
                    fontWeight: 5000,
                    lineHeight: "24px",
                  },
                }}
                label="Şifre"
                variant="filled"
                type={showPassword ? "text" : "password"}
                fullWidth
                name="password"
                error={touched.password && !!errors.password}
                helperText={touched.password ? errors.password : ""}
                onChange={handleChange}
              />
              <Grid container sx={{ pb: "16px" }}>
                <Button
                  disabled={loading}
                  type="submit"
                  fullWidth
                  variant="contained"
                >
                  {loading ? <CircularProgress size="25px" /> : "Giriş Yap"}
                </Button>
              </Grid>
            </form>
            <Grid container justifyContent={"center"}>
              <Typography
                variant="body2"
                // component={NavLink}
                // to={"/sifremi-unuttum"}
                sx={{ fontFamily: "Inter", color: "#6366F1" }}
              >
                Şifreni mi unuttun?
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
