import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useQuery } from "@apollo/client";
import { ALL_USERS_QUERY } from "../../../queries";

import CoachTableBody from "./CoachTableBody";
import { Grid, Skeleton, TablePagination } from "@mui/material";

export default function CoachTable({ search = "", hasUnpaidPayments, filter }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { data: allUsers, loading } = useQuery(ALL_USERS_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      role: "COACH",
      skip: page * 10,
      take: rowsPerPage,
      search: search,
    },
  });

  const allAthletes = allUsers?.allUsers?.users || [];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  React.useEffect(() => {
    setPage(0);
  }, [search, filter]);

  return (
    <>
      <TableContainer>
        <Table
          sx={{
            "& .MuiTableCell-root": {
              border: "unset",
              textAlign: "left",
            },
            "& thead .MuiTableCell-root": {
              fontWeight: 600,
              fontSize: 12,
              color: "#111927",
              letterSpacing: "0.5px",
              textTransform: "uppercase",
            },
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>AD SOYAD </TableCell>
              <TableCell align="right">YARATILMA TARİHİ</TableCell>
              <TableCell align="right">TAKIM</TableCell>
              <TableCell align="right">Aksiyonlar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading &&
              !allUsers?.allUsers?.users &&
              [1, 2, 3, 4, 6, 7, 8, 9, 10].map((s) => (
                <Skeleton
                  sx={{ my: 1.5, bgcolor: "#dcdee29e", borderRadius: "12px" }}
                  variant="rectangular"
                  width={"1351px"}
                  height={"67.953px"}
                />
              ))}
            {allAthletes?.map((athlete) => {
              return <CoachTableBody athlete={athlete} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid
        container
        sx={{
          "& .MuiSelect-select-MuiInputBase-input:focus": {
            backgroundColor: "#FFF",
          },
        }}
        justifyContent={"flex-end"}
      >
        <TablePagination
          labelRowsPerPage="Satır sayısı:"
          sx={{ mt: 1 }}
          component="div"
          count={allUsers?.allUsers?.total || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </>
  );
}
