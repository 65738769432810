import IforgatMyPasswordPage from "./components/forgatPassword/IforgatMyPasswordPage";
import Login from "./components/loginPage/Login";
import MatchResults from "./components/matchResults/MatchResults";
import MatchResultsDetails from "./components/matchResults/MatchResultsDetails";
import Athletes from "./pages/Athletes";
import DuesReports from "./pages/DuesReports";
import Home from "./pages/Home";
import Profil from "./pages/Profil";
import IForgatMyPasswordPageDetailPage from "./components/forgatPassword/IForgatMyPasswordPageDetailPage";
import PrivacyPolicy from "./components/privacyPolicy/PrivacyPolicy";
import ResetPassword from "./components/forgatPassword/ResetPassword";
import TrainingPlans from "./components/trainingPlans/TrainingPlans";
import Settings from "./pages/Settings";
import SettingsLeagues from "./pages/settings/leaguesSetting/SettingsLeagues";
import SettingsGroups from "./pages/settings/groupsSettings/SettingsGroups";
import CoachSettings from "./pages/settings/CoachSettings/CoachSettings";
export const superAdminAccess = [{ path: "/koclar", role: "SUPERADMIN" }];
export const dashboardRoutes = [
  { index: true, exact: true, path: "/", element: <Home /> },
  { index: true, exact: true, path: "/profil", element: <Profil /> },
  { index: true, exact: true, path: "/koclar", element: <CoachSettings /> },
  {
    index: false,
    exact: false,
    path: "/aidat-raporlari",
    element: <DuesReports />,
  },
  {
    index: false,
    exact: true,
    path: "/mac-sonuclari",
    element: <MatchResults />,
  },
  {
    index: false,
    exact: true,
    path: "/mac-sonuclari/:id",
    element: <MatchResultsDetails />,
  },
  {
    index: false,
    exact: true,
    path: "/antrenman-planlari",
    element: <TrainingPlans />,
  },
  {
    index: false,
    exact: true,
    path: "/ayarlar",
    element: <Settings />,
  },
  {
    index: false,
    exact: true,
    path: "/ligler",
    element: <SettingsLeagues />,
  },
  {
    index: false,
    exact: true,
    path: "/gruplar",
    element: <SettingsGroups />,
  },
  { index: false, exact: true, path: "/sporcular", element: <Athletes /> },
  { index: false, exact: true, path: "/sporcular/:id", element: <Athletes /> },
];

export const loginRoutes = [
  {
    index: false,
    exact: true,
    path: "/login",
    element: <Login />,
  },
  // {
  //   index: false,
  //   exact: true,
  //   path: "/kayit-ol",
  //   element: <SignUpPage />,
  // },
  {
    index: false,
    exact: true,
    path: "/sifremi-unuttum",
    element: <IforgatMyPasswordPage />,
  },
  {
    index: false,
    exact: true,
    path: "/kullanici-sözlesmesi",
    element: <PrivacyPolicy />,
  },
  {
    index: false,
    exact: true,
    path: "/eposta-kontrol",
    element: <IForgatMyPasswordPageDetailPage />,
  },
  {
    index: false,
    exact: true,
    path: "/sifreyi-yenile",
    element: <ResetPassword />,
  },
];
