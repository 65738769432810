import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";

import MenuIcon from "@mui/icons-material/Menu";
import { Grid } from "@mui/material";
import GoztepeLogo from "../logo/GoztepeLogo";
import DrawerMenu from "../menu/DrawerMenu";
import { DRAWER_WIDTH } from "../../utils";

const openedMixin = (theme) => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  "& a": {
    borderRadius: 4,
  },
  overflowX: "hidden",
  "& span": {
    display: "none",
  },
  "& .MuiListItemText-root": {
    display: "none",
  },
  "& #expand": {
    display: "none",
  },
  width: `calc(72px + 1px)`,
});

const DrawerHeader = styled("div")(({ theme, open }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...(!open && {
    justifyContent: "center",
  }),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer({ open, setOpen }) {
  const handleDrawerClose = () => {
    setOpen(!open);
  };

  return (
    <Drawer
      sx={{
        display: { sm: "none", xs: "none", md: "none", lg: "block" },
        height: "100vh",
      }}
      variant="permanent"
      open={open}
    >
      <DrawerHeader open={open}>
        <IconButton onClick={handleDrawerClose}>
          <MenuIcon />
        </IconButton>
      </DrawerHeader>
      <Grid sx={{ p: 1 }} container>
        <GoztepeLogo />
        <DrawerMenu open={open} setOpen={setOpen} />
      </Grid>
    </Drawer>
  );
}
