import { Grid, ListItemIcon, ListItemText } from "@mui/material";
import { Collapse } from "@mui/material";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { settingsOptions } from "../../utils";
import { useTheme } from "@mui/material/styles";
import { HiChevronDown } from "react-icons/hi2";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <div {...other} />;
})(({ theme, expand }) => ({
  display: "flex",
  alignItems: "center",
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
export default function SettingsCollapse({open,setOpen}) {
  const theme = useTheme();

  const [expanded, setExpanded] = useState(true);
  const handleExpandClick = () => {
    if(open){
        setExpanded(!expanded);
    }else{
        setExpanded(true);
    }
    setOpen(true)
    
  };
  useEffect(()=>{
if(!open){
    setExpanded(false)  
}
  },[open])
  return (
    <>
      <Grid
        onClick={handleExpandClick}
        justifyContent={"space-between"}
        sx={{
          p: "8px 16px",
          mb: 0.5,
          cursor: "pointer",
          minHeight:46,
          borderRadius: "12px",
          "&:hover": {
            bgcolor: "#1119270A",
          },
          "& svg": { stroke: "#6C737F" },
          "&.active p ": { color: theme.palette.primary.main },
          "&.active": {
            color: theme.palette.primary.main,
            bgcolor: theme.palette.primary[100],
          },
          "&.active svg": {
            stroke: theme.palette.primary.main,
          },
        }}
        alignItems="center"
        container
        flexWrap={"nowrap"}
      >
        <Grid item>
          <Grid container   flexWrap={"nowrap"}  alignItems={"center"}>
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: 1,
                justifyContent: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M7.82925 16.1427L8.31629 17.238C8.46107 17.5641 8.69735 17.8412 8.99647 18.0356C9.2956 18.23 9.64471 18.3335 10.0015 18.3334C10.3582 18.3335 10.7073 18.23 11.0065 18.0356C11.3056 17.8412 11.5419 17.5641 11.6867 17.238L12.1737 16.1427C12.3471 15.754 12.6387 15.43 13.007 15.2168C13.3777 15.0029 13.8065 14.9119 14.232 14.9566L15.4237 15.0834C15.7784 15.1209 16.1364 15.0547 16.4543 14.8929C16.7721 14.731 17.0362 14.4803 17.2144 14.1714C17.3929 13.8626 17.4779 13.5086 17.4592 13.1525C17.4405 12.7963 17.3188 12.4532 17.1089 12.1649L16.4033 11.1955C16.1521 10.8477 16.0179 10.4291 16.02 10.0001C16.0199 9.57224 16.1554 9.15537 16.407 8.80934L17.1126 7.8399C17.3225 7.55154 17.4442 7.20847 17.4629 6.85231C17.4817 6.49615 17.3966 6.1422 17.2181 5.83341C17.0399 5.52444 16.7758 5.27382 16.458 5.11194C16.1401 4.95005 15.7821 4.88386 15.4274 4.92138L14.2357 5.04823C13.8102 5.09292 13.3814 5.00185 13.0107 4.78804C12.6417 4.57363 12.35 4.24788 12.1774 3.85749L11.6867 2.76212C11.5419 2.43606 11.3056 2.15901 11.0065 1.96458C10.7073 1.77015 10.3582 1.66669 10.0015 1.66675C9.64471 1.66669 9.2956 1.77015 8.99647 1.96458C8.69735 2.15901 8.46107 2.43606 8.31629 2.76212L7.82925 3.85749C7.65669 4.24788 7.36498 4.57363 6.99592 4.78804C6.62526 5.00185 6.19647 5.09292 5.77092 5.04823L4.57555 4.92138C4.22082 4.88386 3.86283 4.95005 3.54498 5.11194C3.22712 5.27382 2.96306 5.52444 2.78481 5.83341C2.60632 6.1422 2.52129 6.49615 2.54003 6.85231C2.55877 7.20847 2.68046 7.55154 2.89036 7.8399L3.59592 8.80934C3.84753 9.15537 3.98303 9.57224 3.98295 10.0001C3.98303 10.4279 3.84753 10.8448 3.59592 11.1908L2.89036 12.1603C2.68046 12.4486 2.55877 12.7917 2.54003 13.1479C2.52129 13.504 2.60632 13.858 2.78481 14.1667C2.96324 14.4756 3.22733 14.726 3.54514 14.8879C3.86294 15.0498 4.22085 15.1161 4.57555 15.0788L5.76721 14.9519C6.19277 14.9072 6.62156 14.9983 6.99221 15.2121C7.36265 15.4259 7.65572 15.7517 7.82925 16.1427Z"
                  stroke="#6C737F"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.99998 12.5001C11.3807 12.5001 12.5 11.3808 12.5 10.0001C12.5 8.61937 11.3807 7.50008 9.99998 7.50008C8.61927 7.50008 7.49998 8.61937 7.49998 10.0001C7.49998 11.3808 8.61927 12.5001 9.99998 12.5001Z"
                  stroke="#6C737F"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </ListItemIcon>
            <ListItemText variant="body1" primary={"Ayarlar"} />
          </Grid>
        </Grid>
        <ExpandMore
            id="expand"
          expand={expanded}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <HiChevronDown fill={"#6C737F"} />
        </ExpandMore>
      </Grid>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Grid container sx={{ pl: 4.5, pr: 2 }}>
          {settingsOptions.map((opt, index) => (
            <Grid
              alignItems="center"
              container
              key={index}
              to={opt.to}
              component={NavLink}
              sx={{
                p: "8px 16px",
                mb: 0.5,
                cursor: "pointer",
                borderRadius: "12px",
                "&:hover": {
                  bgcolor: "#1119270A",
                },
                "& svg": { stroke: "#6C737F" },

                "&.active p ": { color: theme.palette.primary.main },
                "&.active": {
                  color: theme.palette.primary.main,
                  bgcolor: theme.palette.primary[100],
                },
                "&.active svg": {
                  stroke: theme.palette.primary.main,
                },
              }}
            >
              <Typography variant="body1">{opt.title}</Typography>
            </Grid>
          ))}
        </Grid>
      </Collapse>
    </>
  );
}
