import { MenuItem } from "@mui/material";
import { Select } from "@mui/material";
import React, { useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { ALL_GROUPS_QUERY } from "../../../queries";
import { UPDATE_PLAYER_GROUPS_MUTATION } from "../../../mutations";
import toast from "react-hot-toast";

export default function PlayerGroupsSelect({ value = "", playerId }) {
  const [posiiton, setPosiiton] = React.useState(value);
  const { data: allGroupLeagueJunctions } = useQuery(ALL_GROUPS_QUERY, {
    fetchPolicy: "cache-and-network",
  });
  const [updateAthleteGroupLeague] = useMutation(UPDATE_PLAYER_GROUPS_MUTATION);

  const handleChange = (event) => {
    const groupLeagueId = event.target.value;

    updateAthleteGroupLeague({
      variables: {
        groupLeagueId: groupLeagueId,
        userId: playerId,
      },
    })
      .then(() => {
        toast.success("Grup segmenti başarıyla güncellendi.");
      })
      .catch((err) => {
        toast.error(err.message);
      });

    setPosiiton(groupLeagueId);
  };
  useEffect(() => {
    setPosiiton(value);
  }, [value]);
  return (
    <>
      <Select sx={{ maxWidth: 240 }} fullWidth value={posiiton} onChange={handleChange}>
        {allGroupLeagueJunctions?.allGroupLeagueJunctions?.map((pos) => (
          <MenuItem key={pos?.id} value={pos?.id}>
            {pos?.groupLeagueName}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
