import { Grid, Typography } from "@mui/material";
import React from "react";

export default function MatchResultsMobileCard({ res }) {
  return (
    <Grid
      item
      sx={{
        pt: "16px",
        display: { xs: "block", sm: "block", md: "block", lg: "none" },
      }}
      xs={12}
    >
      <Grid container justifyContent={"center"}>
        <Typography
          sx={{
            fontStyle: "normal",
            color: "#111927",
            fontFeatureSettings: `'clig' off, 'liga' off`,
            fontSize: { xs: "17px" },
          }}
          variant="h5"
        >
          {res?.results}
        </Typography>
      </Grid>
      <Grid container justifyContent={"center"}>
        <Typography sx={{ fontStyle: "normal" }} variant="body1">
          {res?.resultsname}
        </Typography>
      </Grid>
    </Grid>
  );
}
