import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation } from "@apollo/client";
import { CREATE_LEAGUE_MUTATION } from "../../../mutations";
import toast from "react-hot-toast";

export default function CreateLeaguesDialog({ handleClose, isMobil, open }) {
  const [text, setText] = useState("");

  const [createLeague] = useMutation(CREATE_LEAGUE_MUTATION, {
    refetchQueries: ["allLeagues"],
  });
  useEffect(() => {
    setText("");
  }, [open]);
  const handleCreate = () => {
    createLeague({
      variables: {
        name: text,
      },
    })
      .then(() => {
        toast.success("İşlem başarılı.");
        handleClose();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
  return (
    <Dialog
      fullScreen={isMobil}
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: { borderRadius: { xs: 0, sm: "20px" } },
        component: "form",
        onSubmit: (event) => {
          event.preventDefault();

          handleClose();
        },
      }}
    >
      <DialogTitle
        component={Grid}
        container
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h6">Lig Ekle</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon sx={{ color: "#6C737F", width: 20, height: 20 }} />
        </IconButton>
      </DialogTitle>
      <Grid container>
        <DialogContent>
          <Grid spacing={2} container>
            <Grid item xs={12}>
              <Grid
                container
                sx={{
                  p: { xs: 2, sm: 3 },
                  borderRadius: "20px",
                  padding: "24px",
                  alignSelf: "stretch",
                  gap: "16px",
                  bgcolor: "#fff",
                  boxShadow:
                    "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                }}
              >
                <Grid container>
                  <TextField
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    type="text"
                    label="Lig Adı"
                    fullWidth
                  />
                </Grid>
                <Grid container sx={{ mt: 3 }} justifyContent={"flex-end"}>
                  <Button
                    sx={{ mr: 2 }}
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                  >
                    İptal
                  </Button>
                  <Button
                    disabled={!text}
                    variant="contained"
                    onClick={() => handleCreate()}
                    type="submit"
                  >
                    Lig Ekle
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Grid>
    </Dialog>
  );
}
