import React from "react";
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

export default function CalendarHeader({
  age,
  handleChange,
  handleNext,
  handlePrev,
}) {
  return (
    <>
      <Grid
        sx={{ pb: { sm: "24px", xs: "8px" } }}
        container
        alignItems={"center"}
      >
        <Grid item>
          <Grid container>
            <Grid item sx={{ marginRight: "8px" }}>
              <Typography sx={{ fontFamily: "Plus Jakarta Sans" }} variant="h5">
                Haziran
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ fontWeight: 400 }} variant="h5">
                2024
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          <Grid container justifyContent={"end"} alignItems={"center"}>
            <Grid item>
              <Grid container sx={{ justifyContent: "end", display: "flex" }}>
                <Grid item>
                  <IconButton aria-label="geri-butonu" onClick={handlePrev}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M15.705 7.41L14.295 6L8.29504 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z"
                        fill="#6C737F"
                      />
                    </svg>
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton aria-label="ileri-butonu" onClick={handleNext}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M9.70504 6L8.29504 7.41L12.875 12L8.29504 16.59L9.70504 18L15.705 12L9.70504 6Z"
                        fill="#6C737F"
                      />
                    </svg>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            {/* aşağıdaki select tablet ve üzeri görünümler için */}
            <Grid
              item
              sx={{
                justifyContent: "end",
                display: { sm: "flex", xs: "none" },
                width: "100%",
                maxWidth: "200px",
              }}
            >
              <FormControl
                sx={{
                  width: "100%",
                  maxWidth: "200px",
                  "& .MuiInputLabel-root": {
                    paddingTop: "20px",
                    fontSize: "11px",
                    fontWeight: 500,
                    lineHeight: "12px",
                    letterSpacing: "0.15px",
                    fontFamily: "Inter",
                  },
                  "& .MuiOutlinedInput-input": {
                    p: "14px 2px 0px 2px !important",
                  },
                  "& .MuiSelect-select": {
                    fontSize: "14px !important",
                    fontWeight: 500,
                    lineHeight: "12px",
                    letterSpacing: "0.15px",
                    fontFamily: "Inter",
                  },
                }}
              >
                <InputLabel htmlFor="age-select">Görünüm</InputLabel>
                <Select
                  sx={{
                    p: "6px 12px 4px 12px",
                    borderRadius: "8px",
                    maxHeight: "48px",
                  }}
                  value={age}
                  onChange={handleChange}
                >
                  <MenuItem value={"day"}>Günlük</MenuItem>
                  <MenuItem value={"week"}>Haftalık</MenuItem>
                  <MenuItem value={"month"}>Aylık</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* aşağıdaki select sadece mobildeki görünüm için */}

      <Grid
        container
        sx={{
          display: { sm: "none", xs: "flex" },
          width: "100%",
          pb: "24px",
        }}
      >
        <FormControl
          sx={{
            width: "100%",

            "& .MuiInputLabel-root": {
              paddingTop: "20px",
              fontSize: "11px",
              fontWeight: 500,
              lineHeight: "12px",
              letterSpacing: "0.15px",
              fontFamily: "Inter",
            },
            "& .MuiOutlinedInput-input": {
              p: "14px 2px 0px 2px !important",
            },
            "& .MuiSelect-select": {
              fontSize: "14px !important",
              fontWeight: 500,
              lineHeight: "12px",
              letterSpacing: "0.15px",
              fontFamily: "Inter",
            },
          }}
        >
          <InputLabel htmlFor="age-select">Görünüm</InputLabel>
          <Select
            sx={{
              p: "6px 12px 4px 12px",
              borderRadius: "8px",
              maxHeight: "48px",
            }}
            value={age}
            onChange={handleChange}
          >
            <MenuItem value={"day"}>Günlük</MenuItem>
            <MenuItem value={"week"}>Haftalık</MenuItem>
            <MenuItem value={"month"}>Aylık</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </>
  );
}
