import { Grid } from "@mui/material";
import React from "react";
import HomeInfoCard from "../components/card/HomeInfoCard";
import HomeTeamCard from "../components/card/HomeTeamCard";
import SectionTitle from "../components/title/SectionTitle";
import { useTheme } from "@mui/material/styles";
import { useQuery } from "@apollo/client";
import { GROUP_LEAGUE_COUNT, MY_ATHLETE_STATISTICS } from "../queries";
import HomeDuesTable from "./home/HomeDuesTable";

export default function Home() {
  const theme = useTheme();

  const { data: rawLeagueData } = useQuery(GROUP_LEAGUE_COUNT);
  const leagueCount = rawLeagueData?.myGroupLeagueCount;

  const { data: rawAthleteStatistics } = useQuery(MY_ATHLETE_STATISTICS);
  const myAthleteStatistics = rawAthleteStatistics?.myAthleteStatistics || {
    totalAthletes: 0,
    activeAthletes: 0,
    inactiveAthletes: 0,
    myTotalAthletes: 0,
  };
  const activePercentage = Math.round(
    (myAthleteStatistics.activeAthletes / myAthleteStatistics.myTotalAthletes) *
      100
  );

  return (
    <Grid container>
      <SectionTitle
        title="Ana Sayfa"
        description="Sisteme kayıtlı sporcularınıza buradan ulaşabilirsiniz..."
      />
      <Grid container sx={{ my: 3, px: 3 }}>
        <Grid container spacing={4}>
          <HomeInfoCard title="Takım Sayım" info={leagueCount || "-"} />
          <HomeInfoCard
            title="Takımlarımdaki Sporcu Sayısı"
            info={myAthleteStatistics.myTotalAthletes || "-"}
          />
          <HomeInfoCard
            title="Aktif Sporcu Oranı"
            info={activePercentage ? `% ${activePercentage}` : "-"}
            progress={activePercentage || 0}
          />
          <HomeInfoCard
            title="Toplam Sporcu Sayısı"
            bgcolor={theme.palette.primary.main}
            info={myAthleteStatistics.totalAthletes || "-"}
          />
        </Grid>
      </Grid>

      <HomeDuesTable />
      <Grid container sx={{ p: "12px 0", bgcolor: "white" }}>
        <HomeTeamCard />
      </Grid>
    </Grid>
  );
}
