import React, { useCallback, useMemo, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { Grid } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import "../../index.css";
import "moment/locale/tr";
import CalendarNoteTakingDialog from "./CalendarNoteTakingDialog";
import CalendarDetailDialog from "./CalendarDetailDialog";
import CalendarHeader from "./CalendarHeader";

const localizer = momentLocalizer(moment);
moment.locale("tr");

export default function CalendarCard() {
  const theme = useTheme();
  const [age, setAge] = React.useState("week");
  const [data, setData] = useState(null);
  const [text, setText] = useState("");
  const [open, setOpen] = React.useState(false);
  // DETAY POPUP BAŞLADI
  const [detailOpen, setDetailOpen] = React.useState(false);

  const [detailData, setDetailData] = React.useState(null);
  // DETAY POPUP BİTTİ

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [events, setEvents] = useState([]);

  const handleSelectSlot = useCallback(({ start, end }) => {
    setData({ start, end });
    setOpen(true);
  }, []);

  const { scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(2015, 3, 12),
      scrollToTime: new Date(1970, 1, 1, 6),
    }),
    []
  );

  // ileri geri butonlarının fonksiyonları
  const handleNext = () => {};

  const handlePrev = () => {};
  const customDayPropGetter = (date) => {
    // Mevcut tarihi al
    const today = new Date();
    // Tarihin gün, ay ve yıl değerlerini kontrol et
    if (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    ) {
      return {
        className: "current-day",
        style: {
          backgroundColor: "white",
        },
      };
    }
    return {};
  };
  return (
    <Grid container sx={{ pt: 2 }}>
      <CalendarNoteTakingDialog
        setData={setData}
        setText={setText}
        data={data}
        events={events}
        text={text}
        setEvents={setEvents}
        setOpen={setOpen}
        open={open}
      />
      <CalendarDetailDialog
        setOpen={setDetailOpen}
        open={detailOpen}
        detailData={detailData}
        setDetailData={setDetailData}
      />

      <CalendarHeader
        age={age}
        handleNext={handleNext}
        handleChange={handleChange}
        handlePrev={handlePrev}
      />

      <Grid
        container
        sx={{
          ...(age === "month" ? { height: 500 } : {}),
          "& .rbc-events-container": { m: 0 },
          "& .rbc-time-view": {
            border: "unset",
            borderRadius: "12px",
            boxShadow:
              "0px 0px 0px 0.5px #00000008, 0px 5px 22px 0px #0000000a",
          },
          "& .rbc-event": {
            bgcolor: theme.palette.primary.main,
            borderRadius: "4px",
          },
          "& .rbc-label": {
            color: "#111927",
            fontStyle: "normal",
            fontSize: "16px",
            fontFamily: "Inter",
            fontWeight: 400,
            lineHeight: "24px",
            fontFeatureSettings: "clig' off, 'liga' off",
          },
        }}
        className="my-calendar"
      >
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ width: "100%" }}
          toolbar={false}
          view={age}
          step={30}
          onSelectEvent={(e) => {
            setDetailData(e);
            setDetailOpen(true);
          }}
          onSelectSlot={handleSelectSlot}
          dayPropGetter={customDayPropGetter}
          scrollToTime={scrollToTime}
          selectable
        />
      </Grid>
    </Grid>
  );
}
