import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation } from "@apollo/client";
import { UPDATE_LEAGUE_MUTATION } from "../../../mutations";
import toast from "react-hot-toast";

export default function UpdateDialog({ opt, title }) {
  const [updateLeague] = useMutation(UPDATE_LEAGUE_MUTATION, {
    refetchQueries: ["allLeagues"],
  });
  const [updateOpen, setUpdateOpen] = React.useState(false);
  const [text, setText] = React.useState(opt?.leagueName);
  const handleUptadeOpen = () => {
    setUpdateOpen(true);
  };
  useEffect(() => {
    if (title) {
      setText(title);
    }
  }, [title]);
  const handleCloseUpdate = () => {
    setUpdateOpen(false);
  };
  const isMobil = useMediaQuery("(max-width:600px)");

  const handleUpdate = () => {
    updateLeague({
      variables: {
        name: text,
        updateLeagueId: opt.id,
      },
    })
      .then(() => {
        toast.success("İşlem başarılı.");
        handleCloseUpdate();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
  return (
    <>
      <Grid
        item
        onClick={handleUptadeOpen}
        sx={{ "& .MuiButton-root": { minWidth: "0px", p: 0 } }}
      >
        <Button>
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
          >
            <path
              d="M3.9264 14.8317C3.95809 14.5465 3.97394 14.4038 4.01709 14.2705C4.05538 14.1523 4.10947 14.0397 4.17791 13.9359C4.25504 13.819 4.35651 13.7175 4.55944 13.5146L13.6695 4.40448C14.4315 3.64252 15.6669 3.64252 16.4289 4.40448C17.1908 5.16645 17.1908 6.40184 16.4289 7.1638L7.31876 16.2739C7.11583 16.4768 7.01436 16.5783 6.89739 16.6554C6.79361 16.7238 6.68106 16.7779 6.56279 16.8162C6.42949 16.8594 6.28687 16.8752 6.00163 16.9069L3.66699 17.1663L3.9264 14.8317Z"
              stroke="#6C737F"
              stroke-width="1.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Button>
      </Grid>
      <Dialog
        PaperProps={{
          sx: {
            maxWidth: "696px",
            width: "100%",
          },
        }}
        fullWidth={true}
        fullScreen={isMobil}
        open={updateOpen}
        onClose={handleCloseUpdate}
      >
        <DialogTitle
          component={Grid}
          container
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="h6">Lig Düzenle</Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseUpdate}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon sx={{ color: "#6C737F", width: 20, height: 20 }} />
          </IconButton>
        </DialogTitle>
        <Grid container>
          <DialogContent>
            <Grid spacing={2} container>
              <Grid item xs={12}>
                <Grid
                  container
                  sx={{
                    p: { xs: 2, sm: 3 },
                    borderRadius: "20px",
                    padding: "24px",
                    alignSelf: "stretch",
                    gap: "16px",
                    bgcolor: "#fff",
                    boxShadow:
                      "0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px 0px rgba(0, 0, 0, 0.04)",
                  }}
                >
                  <Grid container>
                    <TextField
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                      type="text"
                      label="Lig Adı"
                      fullWidth
                    />
                  </Grid>
                  <Grid container sx={{ mt: 3 }} justifyContent={"flex-end"}>
                    <Button
                      sx={{ mr: 2 }}
                      variant="contained"
                      color="secondary"
                      onClick={handleCloseUpdate}
                    >
                      İptal
                    </Button>
                    <Button
                      disabled={!text}
                      onClick={() => handleUpdate()}
                      variant="contained"
                      type="submit"
                    >
                      Kaydet
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Grid>
      </Dialog>
    </>
  );
}
