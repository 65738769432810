import React, { useState } from "react";
import { Collapse, Grid, TableCell, TableRow } from "@mui/material";
import PersonalMeasueMenu from "../../../components/menu/PersonalMeasueMenu";
import moment from "moment";
import { minBy, maxBy } from "lodash";
import { LineChartMui } from "../../../components/charts/LineChartMui";

export default function PersonalMeasureRow({ data }) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <TableRow
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        aria-expanded={expanded}
        aria-label="show more"
      >
        <TableCell component="th" scope="row">
          {data.label}
        </TableCell>

        <TableCell align="right">{`${
          data?.lastData?.value ? data?.lastData?.value : "-"
        } ${data?.lastData?.value ? data?.measure : ""}`}</TableCell>
        <TableCell align="right">
          {data?.lastData?.createdAt
            ? moment(data?.lastData?.createdAt).format("DD/MM/YYYY")
            : "-"}
        </TableCell>
        <TableCell align="right">
          {minBy(data?.datas, "value")?.value} -{" "}
          {maxBy(data?.datas, "value")?.value}{" "}
          {minBy(data?.datas, "value")?.value ? data?.measure : ""}
        </TableCell>

        <TableCell align="left">
          <Grid
            container
            alignItems="center"
            sx={{ maxWidth: "121px", minWidth: "121px" }}
          >
            <PersonalMeasueMenu
              expanded={expanded}
              handleExpandClick={handleExpandClick}
              data={data}
            />
          </Grid>
        </TableCell>
      </TableRow>
      {expanded && (
        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <div style={{ width: "100%", overflowX: "auto" }}>
                <LineChartMui data={data} />
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
