import { Grid } from "@mui/material";
import React from "react";
import SectionTitle from "../title/SectionTitle";
import MatchCard from "../matchResults/MatchCard";

export default function MatchResults() {
  return (
    <Grid container>
      <SectionTitle
        title="Maç Sonuçları"
        description="Takımlarınızın maç sonuçlarını bu ekrandan takip edebilirsiniz."
      />
      <Grid container sx={{ pt: 3.5 }}>
        <MatchCard />
      </Grid>
    </Grid>
  );
}
