import { Route, Routes } from "react-router-dom";
import { dashboardRoutes, loginRoutes } from "./routes";
import { ThemeProvider } from "@mui/material/styles";
import { goztepeTheme } from "./theme";
import "@fontsource/inter";
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/plus-jakarta-sans";
import "@fontsource/plus-jakarta-sans/700.css";
import React from "react";
import HomeRoutes from "./protectedRoutes/HomeRoutes";
import LoginRoutes from "./protectedRoutes/LoginRoutes";
import ScrollToTop from "./ScrollToTop";

function App() {

  return (
    <ThemeProvider theme={goztepeTheme}>
      <ScrollToTop />
      <Routes>
        <Route element={<HomeRoutes />}>
          {dashboardRoutes.map((r, index) => (
            <Route key={index} path={r.path} element={r.element} />
          ))}
          
        </Route>

        <Route element={<LoginRoutes />}>
          {loginRoutes.map((r, index) => (
            <Route key={index} path={r.path} element={r.element} />
          ))}
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
