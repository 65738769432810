import { Box, Drawer, IconButton, useMediaQuery } from "@mui/material";
import React from "react";
import GoztepeLogo from "../logo/GoztepeLogo";
import DrawerMenu from "../menu/DrawerMenu";
import { styled } from "@mui/material/styles";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

const DrawerHeader = styled("div")(({ theme, open }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...(!open && {
    justifyContent: "center",
  }),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
export default function MobileDrawer({ open, setOpen }) {
  const matches = useMediaQuery("(max-width:1200px)");

  return matches ? (
    <Drawer
      sx={{ display: { lg: "none" } }}
      open={open}
      onClose={() => setOpen(false)}
    >
      <Box sx={{ width: 250, p: 1 }} role="presentation">
        <DrawerHeader open={open}>
          <IconButton onClick={() => setOpen(false)}>
            <MenuOpenIcon />
          </IconButton>
        </DrawerHeader>
        <GoztepeLogo />
        <DrawerMenu open={open} setOpen={setOpen} />
      </Box>
    </Drawer>
  ) : null;
}
