import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";

export default function StatusChip({ value = "" }) {
  const theme = useTheme();

  let status = {
    ACTIVE: {
      title: "AKTİF",
      sx: {
        bgcolor: theme.palette.success[100],
        color: theme.palette.success.main,
        borderRadius: "100px",
        p: "2px 24px",
      },
    },
    PASSIVE: {
      title: "PASİF",
      sx: {
        bgcolor: "#38425014",
        color: "#38425080",
        borderRadius: "100px",
        p: "2px 24px",
      },
    },
  };
  const selectedData = status[value];
  return (
    <>
      <Grid container>
        <Grid sx={selectedData?.sx} item>
          <Typography variant="caption">{selectedData?.title}</Typography>
        </Grid>
      </Grid>
    </>
  );
}
