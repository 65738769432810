import React from "react";
import {
  IconButton,
  Button,
  Grid,
  InputAdornment,
  useMediaQuery,
  CircularProgress,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useMutation, useQuery } from "@apollo/client";
import { CREATE_ATHLETE_MEASUREMENT_MUTATION } from "../../mutations";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import { GET_ATHLETE_DETAIL_QUERY } from "../../queries";
import { styled } from "@mui/material/styles";

import { SlArrowDown } from "react-icons/sl";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const validationSchema = Yup.object({
  measurementDate: Yup.date().required("Ölçüm tarihi zorunludur."),
  measurementValue: Yup.number().required("Ölçüm değeri zorunludur."),
});

export default function PersonalMeasueMenu({
  data,
  handleExpandClick,
  expanded,
}) {
  let [searchParams] = useSearchParams();
  let athleteId = searchParams.get("sporcu");
  const [createAthleteMeasurement, { loading }] = useMutation(
    CREATE_ATHLETE_MEASUREMENT_MUTATION
  );
  let playerId = searchParams.get("sporcu");

  const { data: getAthlete } = useQuery(GET_ATHLETE_DETAIL_QUERY, {
    variables: {
      getAthleteId: playerId,
    },
    skip: !playerId,
  });

  // Dialog state
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleClickDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    formik.resetForm();
  };
  const isMobil = useMediaQuery("(max-width:600px)");

  // Formik form
  const formik = useFormik({
    initialValues: {
      measurementDate: new Date(),
      measurementValue: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log('qwfqwfqwfwq', new Date(values?.measurementDate))
      try {
        await createAthleteMeasurement({
          variables: {
            createdAt: values?.measurementDate,
            measurementType: data?.type,
            value: values.measurementValue.toString(),
            athleteId: athleteId,
          },
        });
        data?.refetch();
      } catch (error) {
        toast(error.message);
      }
      handleCloseDialog();
    },
  });
  return (
    <>
      <ExpandMore
        expand={expanded}
        onClick={handleExpandClick}
        aria-expanded={expanded}
        aria-label="show more"
      >
        <SlArrowDown fontSize={14} />
      </ExpandMore>

      <Button
        sx={{ p: 1, fontSize: 14 }}
        size="small"
        variant="text"
        onClick={handleClickDialog}
      >
        Ölçüm Ekle
      </Button>

      <Dialog
        fullScreen={isMobil}
        PaperProps={{
          sx: {
            borderRadius: { xs: 0, sm: "20px" },
            height: "100%",
            maxHeight: { sm: "448px", xs: "100%" },
            maxWidth: "696px",
            width: "100%",
          },
        }}
        maxWidth="lg"
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {" "}
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle
            component={Grid}
            container
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            {data?.label} Ölçüm Ekle
            <IconButton
              aria-label="close"
              onClick={handleCloseDialog}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon sx={{ color: "#6C737F", width: 20, height: 20 }} />
            </IconButton>
          </DialogTitle>

          <Grid
            container
            sx={{
              "& .MuiDialogContent-root": {
                padding: { xs: "20px", sm: "20px 24px" },
              },
            }}
          >
            {" "}
            <DialogContent>
              <Grid
                container
                sx={{
                  p: { sm: "24px", xs: "20px" },
                  bgcolor: "#FFF",
                  borderRadius: "20px",
                  height: { xs: "100%" },
                }}
              >
                <Grid container>
                  <Typography sx={{ fontWeight: 400 }}>
                    {getAthlete?.getAthlete?.firstName}{" "}
                    {getAthlete?.getAthlete?.lastName} isimli sporcunun son
                    ölçümü{" "}
                    <strong>
                      {" "}
                      {data?.lastData?.createdAt
                        ? moment(data?.lastData?.createdAt).format("DD/MM/YYYY")
                        : "-"}
                    </strong>{" "}
                    tarihinde{" "}
                    <strong>
                      {" "}
                      {`${data?.lastData?.value ? data?.lastData?.value : "-"
                        } ${data?.lastData?.value ? data?.measure : ""}`}
                    </strong>
                    'dır.
                  </Typography>
                </Grid>
                <Grid container sx={{ py: "24px" }}>
                  <Grid
                    container
                    sx={{
                      minHeight: "74px",

                      "& .MuiFormControl-root": {
                        width: "100% !important",
                      },
                      "& .MuiOutlinedInput-root": {
                        maxHeight: "53.13px",
                        height: "100%",
                      },
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        id="measurementDate"
                        value={moment(formik.values.measurementDate)}
                        onChange={(date) => {
                          formik.setFieldValue("measurementDate", date)
                        }}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Ölçüm Tarihi"
                        error={
                          formik.touched.measurementDate &&
                          Boolean(formik.errors.measurementDate)
                        }
                        helperText={
                          formik.touched.measurementDate &&
                          formik.errors.measurementDate
                        }
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid container sx={{ minHeight: "74px" }}>
                    <TextField
                      sx={{
                        "& input::placeholder": {
                          color: "#6C737F",
                          opacity: 1,
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {data?.measure}
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      placeholder="Değer Giriniz"
                      variant="outlined"
                      id="measurementValue"
                      type="number"
                      error={
                        formik.touched.measurementValue &&
                        Boolean(formik.errors.measurementValue)
                      }
                      helperText={
                        formik.touched.measurementValue &&
                        formik.errors.measurementValue
                      }
                      onChange={formik.handleChange}
                      value={formik.values.measurementValue}
                    />
                  </Grid>
                </Grid>
                <Grid container justifyContent={"end"}>
                  <Button
                    sx={{ mr: "10px" }}
                    variant="outlined"
                    onClick={handleCloseDialog}
                  >
                    İptal
                  </Button>
                  <Button disabled={loading} variant="contained" type="submit">
                    {loading ? <CircularProgress size="25px" /> : "Ölçüm Ekle"}
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
        </form>
      </Dialog>
    </>
  );
}
