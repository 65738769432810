import { useQuery } from "@apollo/client";
import { Navigate, Outlet } from "react-router-dom";
import { LOGGED_IN_QUERY } from "../queries";

const LoginRoutes = () => {

    const { data: loggedInUser, loading } = useQuery(LOGGED_IN_QUERY, {
        fetchPolicy: 'network-only'
    });
    if (loading) {
        return ''
    }
    if (!loggedInUser?.loggedInUser?.id) {
        return <Outlet />  // or loading indicator, etc...
    }
    return <Navigate to={"/"} replace />;
};
export default LoginRoutes;