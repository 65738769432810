import { Grid, Typography } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";

export default function SignUpButtonCard() {
  return (
    <Grid
      component={NavLink}
      to={"/login"}
      container
      sx={{ height: "20px", maxWidth: "444px", mb: "32px", px: 2 }}
    >
      <Grid item>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M15.8337 9.99984H4.16699M4.16699 9.99984L10.0003 15.8332M4.16699 9.99984L10.0003 4.1665"
            stroke="#111927"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </Grid>
      <Grid item sx={{ pl: "8px" }}>
        <Typography
          variant="body2"
          sx={{ color: "#111927", fontStyle: "normal", fontFamily: "Inter" }}
        >
          Giriş Yap
        </Typography>
      </Grid>
    </Grid>
  );
}
