import { Grid, IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import { UPDATE_ATHLETE_MUTATION } from "../../../mutations";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";

export default function ActionCard({ playerId, status }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [updateAthlete] = useMutation(UPDATE_ATHLETE_MUTATION);

  const handleStatusChange = async () => {
    try {
      await updateAthlete({
        variables: {
          updateAthleteId: playerId,
          data: { status: status === "ACTIVE" ? "PASSIVE" : "ACTIVE" },
        },
      });
      toast.success(`Sporcu durumu başarıyla güncellendi `);
      handleClose();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Grid container sx={{ justifyContent: { xl: "start", lg: "flex-end" } }}>
      <IconButton component={NavLink} to={`?sporcu=${playerId}`} size="small">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <g clip-path="url(#clip0_149_615)">
            <path
              d="M9.16663 3.33344H5.66663C4.26649 3.33344 3.56643 3.33344 3.03165 3.60593C2.56124 3.84561 2.17879 4.22806 1.93911 4.69847C1.66663 5.23324 1.66663 5.93331 1.66663 7.33344V14.3334C1.66663 15.7336 1.66663 16.4336 1.93911 16.9684C2.17879 17.4388 2.56124 17.8213 3.03165 18.061C3.56643 18.3334 4.26649 18.3334 5.66663 18.3334H12.6666C14.0668 18.3334 14.7668 18.3334 15.3016 18.061C15.772 17.8213 16.1545 17.4388 16.3941 16.9684C16.6666 16.4336 16.6666 15.7336 16.6666 14.3334V10.8334M6.6666 13.3334H8.06206C8.46971 13.3334 8.67353 13.3334 8.86535 13.2874C9.03541 13.2466 9.19798 13.1792 9.3471 13.0878C9.5153 12.9848 9.65942 12.8406 9.94767 12.5524L17.9166 4.58344C18.607 3.89309 18.607 2.7738 17.9166 2.08344C17.2263 1.39309 16.107 1.39308 15.4166 2.08344L7.44765 10.0524C7.1594 10.3406 7.01527 10.4848 6.9122 10.653C6.82082 10.8021 6.75348 10.9647 6.71265 11.1347C6.6666 11.3265 6.6666 11.5304 6.6666 11.938V13.3334Z"
              stroke="#6C737F"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_149_615">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </IconButton>
      <IconButton onClick={handleClick} size="small">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M9.99996 10.8334C10.4602 10.8334 10.8333 10.4603 10.8333 10C10.8333 9.5398 10.4602 9.16671 9.99996 9.16671C9.53972 9.16671 9.16663 9.5398 9.16663 10C9.16663 10.4603 9.53972 10.8334 9.99996 10.8334Z"
            fill="#6C737F"
          />
          <path
            d="M9.99996 5.00004C10.4602 5.00004 10.8333 4.62694 10.8333 4.16671C10.8333 3.70647 10.4602 3.33337 9.99996 3.33337C9.53972 3.33337 9.16663 3.70647 9.16663 4.16671C9.16663 4.62694 9.53972 5.00004 9.99996 5.00004Z"
            fill="#6C737F"
          />
          <path
            d="M9.99996 16.6667C10.4602 16.6667 10.8333 16.2936 10.8333 15.8334C10.8333 15.3731 10.4602 15 9.99996 15C9.53972 15 9.16663 15.3731 9.16663 15.8334C9.16663 16.2936 9.53972 16.6667 9.99996 16.6667Z"
            fill="#6C737F"
          />
          <path
            d="M9.99996 10.8334C10.4602 10.8334 10.8333 10.4603 10.8333 10C10.8333 9.5398 10.4602 9.16671 9.99996 9.16671C9.53972 9.16671 9.16663 9.5398 9.16663 10C9.16663 10.4603 9.53972 10.8334 9.99996 10.8334Z"
            stroke="#6C737F"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.99996 5.00004C10.4602 5.00004 10.8333 4.62694 10.8333 4.16671C10.8333 3.70647 10.4602 3.33337 9.99996 3.33337C9.53972 3.33337 9.16663 3.70647 9.16663 4.16671C9.16663 4.62694 9.53972 5.00004 9.99996 5.00004Z"
            stroke="#6C737F"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.99996 16.6667C10.4602 16.6667 10.8333 16.2936 10.8333 15.8334C10.8333 15.3731 10.4602 15 9.99996 15C9.53972 15 9.16663 15.3731 9.16663 15.8334C9.16663 16.2936 9.53972 16.6667 9.99996 16.6667Z"
            stroke="#6C737F"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {/* <MenuItem onClick={handleClose}>Profili Sil</MenuItem> */}
        {status === "ACTIVE" ? (
          <MenuItem onClick={handleStatusChange}>Pasife çek</MenuItem>
        ) : (
          <MenuItem onClick={handleStatusChange}>Aktife çek</MenuItem>
        )}
      </Menu>
    </Grid>
  );
}
