import { gql } from "@apollo/client";
import { ATHLETE_FRAGMENT } from "./fragments";

export const LOGGED_IN_QUERY = gql`
  query loggedInUser {
    loggedInUser {
      id
      role
      firstName
      lastName
      email
      phone
      dateOfBirth
      receiveEmails
      receiveGameResults
      receiveNewSporcu
      receivePaymentMade
      unreadNotificationCount
    }
  }
`;

export const ATHLETE_LIST_QUERY = gql`
  query allAthletes(
    $skip: Int
    $take: Int
    $search: String
    $paymentMonth: Int
    $paymentYear: Int
    $playerPositionId: ID
    $groupLeagueId: ID
    $orderBy: AthleteOrderBy
    $status: String
    $hasUnpaidPayments: Boolean
  ) {
    allAthletes(
      skip: $skip
      take: $take
      search: $search
      paymentMonth: $paymentMonth
      paymentYear: $paymentYear
      playerPositionId: $playerPositionId
      groupLeagueId: $groupLeagueId
      orderBy: $orderBy
      status: $status
      hasUnpaidPayments: $hasUnpaidPayments
    ) {
      athletes {
        ...AthleteFragment
      }
      pages
      total
    }
  }
  ${ATHLETE_FRAGMENT}
`;

export const DUE_ATHLETES_LIST_QUERY = gql`
  query allAthletes(
    $skip: Int
    $take: Int
    $search: String
    $hasUnpaidPayments: Boolean
    $paymentMonth: Int
    $paymentYear: Int
    $status: String
    $orderBy: AthleteOrderBy
  ) {
    allAthletes(
      skip: $skip
      take: $take
      search: $search
      hasUnpaidPayments: $hasUnpaidPayments
      paymentMonth: $paymentMonth
      paymentYear: $paymentYear
      status: $status
      orderBy: $orderBy
    ) {
      athletes {
        ...AthleteFragment
      }
      pages
      total
    }
  }
  ${ATHLETE_FRAGMENT}
`;
export const ALL_PAYMENTS_QUERY = gql`
  query allPayments($athleteId: ID) {
    allPayments(athleteId: $athleteId) {
      payments {
        id
        amount
        installmentDate
        debtAmount
        createdAt
        status
      }
    }
  }
`;

export const ALL_PAYMENTS_QUERY_PAGINATED = gql`
  query allPayments($athleteId: ID, $skip: Int, $take: Int) {
    allPayments(athleteId: $athleteId, skip: $skip, take: $take) {
      pages
      payments {
        id
        amount
        debtAmount
        installmentDate
        status
      }
      total
    }
  }
`;
export const GET_ATHLETE_MEASUREMENTS_QUERY = gql`
  query getAthleteMeasurements($measurementType: String!, $athleteId: String!) {
    getAthleteMeasurements(
      measurementType: $measurementType
      athleteId: $athleteId
    ) {
      athleteId
      createdAt
      id
      type
      value
      updatedAt
    }
  }
`;

export const GET_ATHLETE_DETAIL_QUERY = gql`
  query getAthlete($getAthleteId: String!) {
    getAthlete(id: $getAthleteId) {
      ...AthleteFragment
    }
  }
  ${ATHLETE_FRAGMENT}
`;

export const ALL_PLAYER_POSITIONS_QUERY = gql`
  query allPlayerPositions {
    allPlayerPositions {
      id
      name
    }
  }
`;

export const ALL_GROUPS_QUERY = gql`
  query allGroupLeagueJunctions($attachedToACoach: Boolean) {
    allGroupLeagueJunctions(attachedToACoach: $attachedToACoach) {
      id
      groupLeagueName
      paymentAmount
    }
  }
`;

export const GROUP_LEAGUE_COUNT = gql`
  query myGroupLeagueCount {
    myGroupLeagueCount
  }
`;

export const MY_ATHLETE_STATISTICS = gql`
  query myAthleteStatistics {
    myAthleteStatistics {
      totalAthletes
      myTotalAthletes
      activeAthletes
      inactiveAthletes
    }
  }
`;
export const NOTIFICATIONS_QUERY = gql`
  query notifications {
    getMyNotifications {
      notifications {
        id
        message
        read
        createdAt
        title
        type
        userId
      }
      pages
      total
    }
  }
`;

export const ALL_LEAGUES_QUERY = gql`
  query allLeagues {
    allLeagues {
      id
      leagueName
      totalAthletes
      linkedGroups {
        id
        groupName
      }
      linkedGroupLeagueJunctions {
        id
        groupName
      }
    }
  }
`;

export const MY_ALL_GROUPS_QUERY = gql`
  query allGroupSegments {
    allGroupSegments {
      groupMaxAge
      groupMinAge
      groupName
      id
      isSportSchool
      totalAthletes
      leagues {
        id
        leagueName
      }
    }
  }
`;

export const ALL_USERS_QUERY = gql`
  query allUsers($take: Int, $skip: Int, $role: String, $search: String) {
    allUsers(take: $take, skip: $skip, role: $role, search: $search) {
      users {
        id
        email
        firstName
        lastName
        role
        createdAt
        teams {
          id
          groupLeagueName
        }
      }
      pages
      total
    }
  }
`;

export const PAYMENT_REPORTS = gql`
  query paymentReports {
    paymentReports {
      currentMonth {
        percentage
        potentialIncome
        realIncome
      }
      nextSixMonthsForecast {
        potentialIncome
        percentage
        realIncome
      }
      lastSixMonthsSummary {
        percentage
        potentialIncome
        realIncome
      }
    }
  }
`;
