import { useQuery } from '@apollo/client';
import { Avatar, Grid,  Typography } from '@mui/material'
import React from 'react'
import { LOGGED_IN_QUERY } from '../../../queries';
import ProfilImage from "../../../assets/header/avatarGoztepe.png";

export default function ProfilAvatar() {
    const { data: loggedInUser } = useQuery(LOGGED_IN_QUERY);

  return (
    <Grid
        container
        sx={{ px: { sm: "24px", xs: "10px" } }}
        alignItems={"center"}
      >
        <Grid
          item
         
          sx={{
            position: "relative",
            "& .MuiIconButton-root:hover": { backgroundColor: "white" },
          }}
        >
          <Avatar
            sx={{
              height: { sm: "91px", xs: "50px" },
              width: { sm: "96px", xs: "50px" },
            }}
            src={ProfilImage}
          />
          {/* <IconButton
            item
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
              zIndex: 1,
              background: "white",
              borderRadius: "50%",
              padding: "2px",
              display: "flex",
            }}
          >
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="21"
              viewBox="0 0 24 21"
              fill="none"
            >
              <g filter="url(#filter0_d_776_6996)">
                <path
                  d="M4.31128 14.1984C4.34932 13.8561 4.36833 13.685 4.42012 13.525C4.46606 13.3831 4.53097 13.248 4.6131 13.1235C4.70566 12.9831 4.82742 12.8614 5.07094 12.6179L16.0031 1.68577C16.9174 0.77141 18.3999 0.771411 19.3142 1.68577C20.2286 2.60013 20.2286 4.08259 19.3142 4.99695L8.38212 15.929C8.1386 16.1726 8.01684 16.2943 7.87647 16.3869C7.75194 16.469 7.61688 16.5339 7.47496 16.5799C7.31499 16.6316 7.14385 16.6507 6.80156 16.6887L4 17L4.31128 14.1984Z"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  shape-rendering="crispEdges"
                ></path>
              </g>
              <defs>
                <filter
                  id="filter0_d_776_6996"
                  x="-1"
                  y="0"
                  width="26"
                  height="26"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood
                    flood-opacity="0"
                    result="BackgroundImageFix"
                  ></feFlood>
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  ></feColorMatrix>
                  <feOffset dy="4"></feOffset>
                  <feGaussianBlur stdDeviation="2"></feGaussianBlur>
                  <feComposite in2="hardAlpha" operator="out"></feComposite>
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  ></feColorMatrix>
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_776_6996"
                  ></feBlend>
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_776_6996"
                    result="shape"
                  ></feBlend>
                </filter>
              </defs>
            </svg>
          </IconButton> */}
        </Grid>
        <Grid item xs sx={{ pl: "24px" }}>
          <Grid container sx={{ pb: { sm: "8px", xs: "2px" } }}>
            <Typography
              variant="h1"
              sx={{ color: "#111927", fontSize: { xs: "20px", sm: "32px" } }}
            >
              {loggedInUser?.loggedInUser?.firstName} {loggedInUser?.loggedInUser?.lastName}
            </Typography>
          </Grid>
          <Grid container>
            <Typography sx={{ fontWeight: 500 }} variant="body2">
              {loggedInUser?.loggedInUser?.email}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
  )
}
