import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { Grid, Paper } from "@mui/material";
import { NavLink } from "react-router-dom";

export default function GoztepeBottomNavigation() {
  return (
    <>
      <Grid container sx={{ height: 72 }}></Grid>
      <Paper
        sx={{
          display: { sm: "none", xs: "block" },
          backdropFilter: "blur(12px)",
          bgcolor: "#ffffff40",
          bottom: 0,
          left: 0,
          right: 0,
          position: { xs: "fixed" },
        }}
        elevation={3}
      >
        <BottomNavigation
          sx={{
            "& .MuiBottomNavigationAction-root.Mui-selected": {
              color: "#6366F1",
            },
            "& .MuiBottomNavigationAction-label": {
              mt: 0.5,
              fontSize: 11,
            },
            "& .active": {
              color: "#6366F1",
            },
            "& svg": {
              stroke: "#6C737F",
            },
            "& .active svg": {
              stroke: "#6366F1",
            },
            bgcolor: "transparent",
          }}
          showLabels
        >
          <BottomNavigationAction
            to="/"
            component={NavLink}
            label="Ana Sayfa"
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M6.77168 11.6667C7.14172 13.1043 8.4468 14.1667 10 14.1667C11.5532 14.1667 12.8583 13.1043 13.2283 11.6667M9.18141 2.30333L3.52949 6.69927C3.15168 6.99312 2.96278 7.14005 2.82669 7.32405C2.70614 7.48704 2.61633 7.67065 2.56169 7.86588C2.5 8.08627 2.5 8.32558 2.5 8.80421V14.8333C2.5 15.7667 2.5 16.2335 2.68166 16.59C2.84144 16.9036 3.09641 17.1585 3.41002 17.3183C3.76654 17.5 4.23325 17.5 5.16667 17.5H14.8333C15.7668 17.5 16.2335 17.5 16.59 17.3183C16.9036 17.1585 17.1586 16.9036 17.3183 16.59C17.5 16.2335 17.5 15.7667 17.5 14.8333V8.80421C17.5 8.32558 17.5 8.08627 17.4383 7.86588C17.3837 7.67065 17.2939 7.48704 17.1733 7.32405C17.0372 7.14005 16.8483 6.99312 16.4705 6.69927L10.8186 2.30333C10.5258 2.07562 10.3794 1.96177 10.2178 1.918C10.0752 1.87938 9.92484 1.87938 9.78221 1.918C9.62057 1.96177 9.47418 2.07562 9.18141 2.30333Z"
                  stroke={""}
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
          />
          <BottomNavigationAction
            to="/sporcular"
            component={NavLink}
            label="Sporcularımız"
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M15 13.1974C16.2133 13.8069 17.2534 14.785 18.0127 16.008C18.1631 16.2502 18.2382 16.3713 18.2642 16.539C18.3171 16.8798 18.084 17.2988 17.7666 17.4336C17.6104 17.5 17.4347 17.5 17.0833 17.5M13.3333 9.6102C14.5681 8.99657 15.4167 7.72238 15.4167 6.25C15.4167 4.77762 14.5681 3.50343 13.3333 2.8898M11.6667 6.25C11.6667 8.32107 9.98774 10 7.91667 10C5.8456 10 4.16667 8.32107 4.16667 6.25C4.16667 4.17893 5.8456 2.5 7.91667 2.5C9.98774 2.5 11.6667 4.17893 11.6667 6.25ZM2.13269 15.782C3.46128 13.7871 5.55781 12.5 7.91667 12.5C10.2755 12.5 12.3721 13.7871 13.7006 15.782C13.9917 16.219 14.1372 16.4375 14.1205 16.7166C14.1074 16.9339 13.965 17.2 13.7913 17.3313C13.5683 17.5 13.2615 17.5 12.648 17.5H3.1853C2.57181 17.5 2.26507 17.5 2.04203 17.3313C1.86837 17.2 1.72591 16.9339 1.71286 16.7166C1.69611 16.4375 1.84164 16.219 2.13269 15.782Z"
                  stroke={""}
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
          />
          <BottomNavigationAction
            to="/mac-sonuclari"
            component={NavLink}
            label="Sonuçlar"
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  opacity="0.12"
                  d="M2.5 6.5C2.5 5.09987 2.5 4.3998 2.77248 3.86502C3.01217 3.39462 3.39462 3.01217 3.86502 2.77248C4.3998 2.5 5.09987 2.5 6.5 2.5H13.5C14.9001 2.5 15.6002 2.5 16.135 2.77248C16.6054 3.01217 16.9878 3.39462 17.2275 3.86502C17.5 4.3998 17.5 5.09987 17.5 6.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V6.5Z"
                  fill=""
                />
                <path
                  d="M6.66667 12.5V14.1667M10 9.16667V14.1667M13.3333 5.83333V14.1667M6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5V6.5C17.5 5.09987 17.5 4.3998 17.2275 3.86502C16.9878 3.39462 16.6054 3.01217 16.135 2.77248C15.6002 2.5 14.9001 2.5 13.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5Z"
                  stroke={""}
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
          />
          <BottomNavigationAction
            to="/aidat-raporlari"
            component={NavLink}
            label="Aidat Rap."
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M11.6667 1.89136V5.33348C11.6667 5.80018 11.6667 6.03354 11.7575 6.2118C11.8374 6.3686 11.9649 6.49609 12.1217 6.57598C12.2999 6.66681 12.5333 6.66681 13 6.66681H16.4421M6.66667 12.5001V15.0001M13.3333 10.8334V15.0001M10 8.75008V15.0001M16.6667 8.3236V14.3334C16.6667 15.7335 16.6667 16.4336 16.3942 16.9684C16.1545 17.4388 15.7721 17.8212 15.3016 18.0609C14.7669 18.3334 14.0668 18.3334 12.6667 18.3334H7.33334C5.9332 18.3334 5.23314 18.3334 4.69836 18.0609C4.22795 17.8212 3.8455 17.4388 3.60582 16.9684C3.33334 16.4336 3.33334 15.7335 3.33334 14.3334V5.66675C3.33334 4.26662 3.33334 3.56655 3.60582 3.03177C3.8455 2.56137 4.22795 2.17892 4.69836 1.93923C5.23314 1.66675 5.9332 1.66675 7.33334 1.66675H10.0098C10.6213 1.66675 10.927 1.66675 11.2147 1.73582C11.4698 1.79707 11.7137 1.89808 11.9374 2.03515C12.1897 2.18975 12.4059 2.40594 12.8382 2.83832L15.4951 5.49517C15.9275 5.92755 16.1437 6.14374 16.2983 6.39604C16.4353 6.61972 16.5364 6.86358 16.5976 7.11867C16.6667 7.40639 16.6667 7.71213 16.6667 8.3236Z"
                  stroke={""}
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
          />
        </BottomNavigation>
      </Paper>
    </>
  );
}
