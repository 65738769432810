import { Grid } from "@mui/material";
import React from "react";
import SectionTitle from "../title/SectionTitle";
import { Tab } from "@mui/material";
import { Tabs } from "@mui/material";
import { TrainingPlansOptions } from "../../utils";
import CalendarCard from "./CalendarCard";

export default function TrainingPlans() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Grid container>
      <SectionTitle
        title="Antrenman Planları"
        description="Takımlarınızın antrenman planlarını bu ekrandan takip edebilirsiniz."
      />
      <Grid container sx={{ p: 3 }}>
        <Grid container sx={{ bgcolor: "white", boxShadow: '0px 0px 0px 0.5px #00000008,0px 5px 22px 0px #0000000a', p: '8px 24px', borderRadius: "20px" }}>
          <Grid container>
            <Tabs
              sx={{
                "& .MuiTabs-scroller": { height: "40px" },
                "& .MuiTabs-scrollButtons": {
                  height: 40,
                  width: {
                    xs: "20px",
                    sm: "20px",
                    md: "40px",
                  },
                },
              }}
              variant="scrollable"
              scrollButtons="auto"
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {TrainingPlansOptions.map((opt, index) => (
                <Tab
                  sx={{
                    textTransform: "unset",
                    mr: 3,
                    p: 0,
                    minHeight: 40,
                    minWidth: "inherit",
                  }}
                  key={index}
                  label={opt.title}
                />
              ))}
            </Tabs>
          </Grid>
          <Grid container>
            <CalendarCard />
          </Grid>

        </Grid>
      </Grid>
    </Grid>
  );
}
