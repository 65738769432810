import * as React from "react";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import { Menu } from "@mui/material";
import { HiChevronDown } from "react-icons/hi2";
import { Avatar } from "@mui/material";
import DemoAvatar from "../../assets/header/avatarGoztepe.png";
import { Grid } from "@mui/material";
import { NavLink } from "react-router-dom";
import { ApolloConsumer } from "@apollo/client";

export default function AvatarMenu() {
  // const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = (cache) => {
    localStorage.removeItem("login");
    cache.resetStore();
    // navigate('/login')
  };
  return (
    <>
      <Grid onClick={handleClick} sx={{ p: 0.5, cursor: "pointer" }} item>
        <Grid alignItems={"center"} container>
          <Avatar src={DemoAvatar} sx={{ mr: 1 }} />
          <HiChevronDown fill={"#6C737F"} />
        </Grid>
      </Grid>
      <Menu
        MenuListProps={{
          dense: true,
        }}
        sx={{
          "& .active": {
            bgcolor: "primary.100",
          },
          "& .active .MuiListItemText-primary": {
            color: "primary.main",
          },
        }}
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => setAnchorEl(null)}
          component={NavLink}
          to={"/profil"}
        >
          <ListItemText>Profilimi Görüntüle</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => setAnchorEl(null)}
          component={NavLink}
          to={"/sporcular"}
        >
          <ListItemText>Sporcularım</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => setAnchorEl(null)}
          component={NavLink}
          to={"/mac-sonuclari"}
        >
          <ListItemText>Maç Sonuçları</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => setAnchorEl(null)}
          component={NavLink}
          to={"/antrenman-planlari"}
        >
          <ListItemText>Planlar</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => setAnchorEl(null)}
          component={NavLink}
          to={"/ligler"}
        >
          <ListItemText>Ligler</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => setAnchorEl(null)}
          component={NavLink}
          to={"/gruplar"}
        >
          <ListItemText>Grup Segmentler</ListItemText>
        </MenuItem>
        <Divider />
        <ApolloConsumer>
          {(cache) => (
            <MenuItem onClick={() => handleLogout(cache)}>
              <ListItemText>Çıkış Yap</ListItemText>
            </MenuItem>
          )}
        </ApolloConsumer>
      </Menu>
    </>
  );
}
