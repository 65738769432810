import { Tab } from "@mui/material";
import { TextField } from "@mui/material";
import { Tabs } from "@mui/material";
import { InputAdornment } from "@mui/material";
// import { Button } from "@mui/material";
// import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import React from "react";
import { athletesTeamOptions } from "../../utils";
import TableContainer from "../container/TableContainer.js";
import AthletesFilter from "../filter/AthletesFilter";
import AthleteTable from "../table/AthleteTable";

export default function AthletesCard() {
  const [filter, setFilter] = React.useState({});

  const [value, setValue] = React.useState(0);
  const [search, setSearch] = React.useState("");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container sx={{ p: { sm: 3, xs: 1 } }}>
      <Grid
        container
        sx={{
          borderRadius: "20px",
          boxShadow: "0px 0px 0px 0.5px #00000008, 0px 5px 22px 0px #0000000a",
          p: "8px 24px",
          bgcolor: "white",
        }}
      >
        <Grid container>
          <Tabs
            sx={{
              "& .MuiTabs-scroller": { height: "40px" },
              "& .MuiTabs-scrollButtons": {
                height: 40,
                width: {
                  xs: "20px",
                  sm: "20px",
                  md: "40px",
                },
              },
            }}
            variant="scrollable"
            scrollButtons="auto"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {athletesTeamOptions.map((opt, index) => (
              <Tab
                sx={{
                  textTransform: "unset",
                  mr: 3,
                  p: 0,
                  minHeight: 40,
                  minWidth: "inherit",
                }}
                key={index}
                label={opt.title}
              />
            ))}
          </Tabs>
          <Grid container sx={{ mt: 1 }}>
            <Grid container alignItems={"center"} spacing={2}>
              <Grid item xs>
                <TextField
                  fullWidth
                  value={search}
                  onChange={(event) => setSearch(event.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="23"
                          height="24"
                          viewBox="0 0 23 24"
                          fill="none"
                        >
                          <path
                            d="M20.125 21L15.9562 16.65M18.2083 11C18.2083 15.4183 14.7758 19 10.5417 19C6.30748 19 2.875 15.4183 2.875 11C2.875 6.58172 6.30748 3 10.5417 3C14.7758 3 18.2083 6.58172 18.2083 11Z"
                            stroke="#111927"
                            stroke-opacity="0.38"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Sporcu ara"
                  size="small"
                />
              </Grid>
              <Grid item>
                <AthletesFilter filter={filter} setFilter={setFilter} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <TableContainer
          table={<AthleteTable filter={filter} search={search} />}
        />
      </Grid>
    </Grid>
  );
}
