import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Grid, Skeleton, TablePagination, Typography } from "@mui/material";
import AvatarCard from "../card/athleteTable/AvatarCard";
import DuesAction from "../card/duesTable/DuesAction";
import PaymentStatusChip from "../card/duesTable/PaymentStatusChip";
import { ATHLETE_LIST_QUERY } from "../../queries";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { isEmpty } from "lodash";
import PlayerGroupsSelect from "../card/athleteTable/PlayerGroupsSelect";
import { FaSort } from "react-icons/fa";
import { FaSortUp } from "react-icons/fa";
import { FaSortDown } from "react-icons/fa6";
import { useTheme } from "@mui/material/styles";

export default function AthleteDuesTable({
  search = "",
  hasUnpaidPayments,
  filter,
}) {
  const theme = useTheme();
  const monthFilter = filter?.data?.find((d) => d.type === "month");
  const playerPositionFilter = filter?.data?.find((d) => d.type === "position");
  const groupFilter = filter?.data?.find((d) => d.type === "group");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sort, setSort] = React.useState({});

  const { data: allAthletesRaw, loading } = useQuery(ATHLETE_LIST_QUERY, {
    variables: {
      skip: page * 10,
      take: rowsPerPage,
      search: search,
      status: "ACTIVE",
      hasUnpaidPayments: hasUnpaidPayments ? true : undefined,
      ...(!isEmpty(monthFilter)
        ? {
            paymentMonth: monthFilter?.value?.id,
            paymentYear: moment().year(),
          }
        : {}),
      ...(!isEmpty(playerPositionFilter)
        ? {
            playerPositionId: playerPositionFilter?.value?.id,
          }
        : {}),
      ...(!isEmpty(groupFilter)
        ? {
            groupLeagueId: groupFilter?.value?.id,
          }
        : {}),
      ...(!isEmpty(sort)
        ? {
            orderBy: sort,
          }
        : {}),
    },
    fetchPolicy: "cache-and-network",
  });
  const handleSort = (val) => {
    if (!sort[val]) {
      setSort({ [val]: "desc" });
    } else if (sort[val] === "desc") {
      setSort({ [val]: "asc" });
    } else {
      setSort({});
    }
  };
  React.useEffect(() => {
    setPage(0);
  }, [search, filter]);
  const allAthletes = allAthletesRaw?.allAthletes?.athletes || [];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <TableContainer
        sx={{ boxShadow: "unset", maxWidth: "100%" }}
        component={Paper}
      >
        <Table
          sx={{
            "& .MuiTableCell-root": {
              border: "unset",
              textAlign: "left",
            },
            "& thead .MuiTableCell-root": {
              fontWeight: 600,
              fontSize: 12,
              color: "#111927",
              letterSpacing: "0.5px",
              textTransform: "uppercase",
            },
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                onClick={() => handleSort("firstName")}
                align="right"
                sx={{ cursor: "pointer", userSelect: "none" }}
              >
                <Grid
                  sx={{
                    color: sort["firstName"]
                      ? theme.palette.primary.main
                      : "inherit",
                    minWidth: "160px",
                  }}
                  container
                  alignItems={"center"}
                >
                  AD SOYAD{" "}
                  {sort["firstName"] !== "asc" &&
                    sort["firstName"] !== "desc" && (
                      <FaSort style={{ marginLeft: 4 }} />
                    )}
                  {sort["firstName"] === "asc" && (
                    <FaSortUp style={{ marginLeft: 4 }} />
                  )}
                  {sort["firstName"] === "desc" && (
                    <FaSortDown style={{ marginLeft: 4 }} />
                  )}
                </Grid>
              </TableCell>
              <TableCell align="right">Gruplar</TableCell>

              <TableCell align="right" sx={{ minWidth: "182px" }}>
                SON ÖDEME YAPILAN TARİH
              </TableCell>
              <TableCell
                onClick={() => handleSort("totalDebtAmount")}
                align="right"
                sx={{ minWidth: "174px", cursor: "pointer" }}
              >
                <Grid
                  sx={{
                    color: sort["totalDebtAmount"]
                      ? theme.palette.primary.main
                      : "inherit",
                  }}
                  container
                  alignItems={"center"}
                >
                  ÖDENECEK TUTAR{" "}
                  {sort["totalDebtAmount"] !== "asc" &&
                    sort["totalDebtAmount"] !== "desc" && (
                      <FaSort style={{ marginLeft: 4 }} />
                    )}
                  {sort["totalDebtAmount"] === "asc" && (
                    <FaSortUp style={{ marginLeft: 4 }} />
                  )}
                  {sort["totalDebtAmount"] === "desc" && (
                    <FaSortDown style={{ marginLeft: 4 }} />
                  )}
                </Grid>
              </TableCell>
              <TableCell align="right">ÖDEME DURUMU</TableCell>
              <TableCell
                sx={{
                  textAlign: "center !important",
                  position: "sticky",
                  right: -1,
                  backdropFilter: "blur(8px)",
                  bgcolor: "#ffffff40",
                }}
              >
                Aksiyonlar
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading &&
              !allAthletesRaw?.allAthletes?.athletes &&
              [1, 2, 3, 4, 6, 7, 8, 9, 10].map((s) => (
                <Skeleton
                  sx={{ my: 1.5, bgcolor: "#dcdee29e", borderRadius: "12px" }}
                  variant="rectangular"
                  width={"1351px"}
                  height={"64.25px"}
                />
              ))}
            {allAthletes.map((athlete, index) => (
              <TableRow
                key={athlete.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {
                    <AvatarCard
                      firstName={athlete.firstName}
                      lastName={athlete.lastName}
                      email={athlete.email}
                      playerId={athlete?.id}
                    />
                  }
                </TableCell>
                <TableCell align="right">
                  <PlayerGroupsSelect
                    value={athlete?.groupLeagueId}
                    playerId={athlete?.id}
                  />
                </TableCell>{" "}
                <TableCell align="right">
                  {athlete.lastPaymentDate
                    ? moment(athlete.lastPaymentDate).format("DD/MM/YYYY")
                    : "-"}
                </TableCell>
                <TableCell align="right">
                  {athlete.totalDebtAmount} TL
                </TableCell>
                <TableCell sx={{ minWidth: "182px" }} align="right">
                  {
                    <PaymentStatusChip
                      ödemeDurumu="HİÇ ÖDEME YAPILMADI"
                      value={athlete.totalPaymentStatus}
                    />
                  }
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    position: "sticky",
                    right: -1,
                    backdropFilter: "blur(8px)",
                    bgcolor: "#ffffff40",
                  }}
                >
                  <DuesAction
                    playerId={athlete?.id}
                    paymentStatus={athlete.totalPaymentStatus}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && allAthletesRaw?.allAthletes?.athletes?.length === 0 && (
        <Grid container justifyContent={"center"}>
          <Typography>Sporcu bulunamadı...</Typography>
        </Grid>
      )}
      <Grid
        container
        sx={{
          "& .MuiSelect-select-MuiInputBase-input:focus": {
            backgroundColor: "#FFF",
          },
        }}
        justifyContent={"flex-end"}
      >
        <TablePagination
          labelRowsPerPage="Satır sayısı:"
          sx={{ mt: 1 }}
          component="div"
          count={allAthletesRaw?.allAthletes?.total || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </>
  );
}
